import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData } from "../../FetchService";
import DataTable from "react-data-table-component";
// import { isHtmlElement } from "react-router-dom/dist/dom";

const ManageEarning = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [paidEvents, setPaidEvents] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [filterdData, setFilterdData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSearchPaidEvents, setIsSearchPaidEvents] = useState("");
  const [filterdDataPaidEvents, setFilterdDataPaidEvents] = useState([]);
  const [startDatePaidEvents, setStartDatePaidEvents] = useState();
  const [endDatePaidEvents, setEndDatePaidEvents] = useState();
  const [status1, setStatus1] = useState("");

  const handleSubscriptions = async () => {
    const res = await getData("get-subscription");
    setSubscriptions(res.data);
  };
  const handlepaidEvents = async () => {
    const res = await getData("get-paid-events");
    setPaidEvents(res.data);
  };

  useEffect(() => {
    handleSubscriptions();
    handlepaidEvents();
  }, []);

  // All Filter Event For Subsscriptions

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearch(e.target.value);
    let fill = subscriptions?.filter((el) => {
      if (
        el.user
          ? `${el.user.firstName} ${el.user.lastName}`
              .toLowerCase()
              .includes(e.target.value)
          : ""
      ) {
        return el;
      }
    });

    setFilterdData(fill);
  };

  //Filter by Date and Payment Status

  const filterByStatus = () => {
    if (status1) {
      setIsSearch(1);
      let filterStatus = subscriptions?.filter((item) => {
        if (
          item.isSuccess.toString() == status1 && item.createdAt
            ? item.createdAt > startDate && item.createdAt < endDate
            : ""
        ) {
          return item;
        }
      });
      setFilterdData(filterStatus);
    } else {
      setIsSearch(1);
      const fill2 = subscriptions?.filter((item) => {
        if (
          item.createdAt
            ? item.createdAt > startDate && item.createdAt < endDate
            : ""
        )
          return item;
      });
      setFilterdData(fill2);
    }
  };

  // All Filter Events For Paid Events

  const handleSearchPaidEvents = (e) => {
    e.preventDefault();
    setIsSearchPaidEvents(e.target.value);
    let filterEvents = paidEvents?.filter((el) => {
      if (
        el.user ? el.user.firstName?.toLowerCase().includes(e.target.value) : ""
      ) {
        return el;
      }
    });

    setFilterdDataPaidEvents(filterEvents);
  };

  const filterByDatePaidEvents = () => {
    setIsSearchPaidEvents(1);
    let filterEvents2 = paidEvents?.filter((item) => {
      if (
        item.createdAt
          ? item.createdAt > startDatePaidEvents &&
            item.createdAt < endDatePaidEvents
          : ""
      ) {
        return item;
      }
    });
    setFilterdDataPaidEvents(filterEvents2);
  };

  const clearFilter = () => {
    setIsSearch("");
    setStatus1("");
    setStartDate("");
    setEndDate("");
  };

  const subscriptionsColumns = [
    {
      name: "SR.NO",
      selector: (row, i) => i + 1,
      center: true,
    },
    {
      name: "Transaction ID",
      selector: (row) => (row.orderId ? row.orderId : " Yet to be declare"),
      sortable: true,
      center: true,
    },
    {
      name: "Transaction Date and Time",
      selector: (row) =>
        row.createdAt ? row.createdAt.slice(0, 10) : "01/01/2001",
      sortable: true,
      center: true,
    },
    {
      name: "Username",
      selector: (row) =>
        row.user ? `${row.user.firstName} ${row.user.lastName}` : "Unknown",
      sortable: true,
      center: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => (row.user ? row.user.mobile : "8953286101"),
      sortable: true,
      center: true,
    },
    {
      name: "Subscription Plan",
      selector: (row) =>
        row.subscription[0].name ? row.subscription[0].name : "XXXXX",
      sortable: true,
      center: true,
    },
    {
      name: "Amount Earned (₹)",
      selector: (row) => (row.amount ? row.amount : "XXXXX"),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => (row.isSuccess == true ? "Paid" : "Not Paid"),
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="/manageusers/viewuserdeials"
            state={{ item: row.userId }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
        </div>
      ),
    },
  ];

  let status = {
    status: 1,
  };
  const paidEventsColumns = [
    {
      name: "SR.NO",
      selector: (row, i) => i + 1,
      center: true,
    },
    {
      name: "Transaction ID",
      selector: (row) => (row.orderId ? row.orderId : "XXXX"),
      center: true,
    },
    {
      name: "Date and Time",
      selector: (row) =>
        row.createdAt ? row.createdAt.slice(0, 10) : "1/1/2001",
      center: true,
    },
    {
      name: "UserName",
      selector: (row) =>
        row.user ? `${row.user.firstName} ${row.user.lastName}` : "Unknown",
      center: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => (row.user ? row.user.mobile : "8953286101"),
      center: true,
    },
    {
      name: "Email Address",
      selector: (row, i) => "shibudubey5@gmail.com",
      center: true,
    },
    {
      name: "Event Name",
      selector: (row, i) =>
        row.events
          ? row.events.length !== 0
            ? row.events[0].name
            : "N/A"
          : "",
      center: true,
    },
    {
      name: "Activities",

      selector: (row, i) =>
        row.events
          ? row.events.length !== 0
            ? row.events[0].activities.map((el) => {
                return el == 1
                  ? "Running "
                  : "" || el == 2
                  ? "Cycling "
                  : "" || el == 3
                  ? "Jumping "
                  : "";
              })
            : "N/A"
          : "",
      center: true,
    },
    {
      name: "Amount Paid(₹)",
      selector: (row, i) => (row.amount ? row.amount : "XXXX"),
      center: true,
    },
    {
      name: "Status",
      selector: (row, i) => (row.amount ? "Paid" : "Not Paid"),
      center: true,
    },

    {
      name: "Action",
      cell: (row) => {
        return row?.events?.length > 0 ? (
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="/managepublicevent/vieweventdetailspaid"
            state={{ items: row?.events[0]?._id, user: row?.user }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
        ) : (
          "No Events"
        );
      },
    },
  ];

  return (
    <div className="main mt-8">
      <div className="content container-fluid subscriptionsManageEarnings">
        <div className="pageheader">
          <div className="row g-3 align-items-center mb-5">
            <div className="col-12">
              <h1 className="page-header-title pageTitle">Manage Earnings</h1>
            </div>
            <div className="col-12 tableTabs">
              <ul
                className="nav nav-segment"
                id="leaderboardTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="all-time-tab"
                    data-bs-toggle="tab"
                    href="#all-time"
                    role="tab"
                  >
                    Subscriptions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="weekly-tab"
                    data-bs-toggle="tab"
                    href="#weekly"
                    role="tab"
                  >
                    Paid Events
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <div className="tab-content" id="leaderboardTabContent">
                <div
                  className="tab-pane fade show active"
                  id="all-time"
                  role="tabpanel"
                  aria-labelledby="all-time-tab"
                >
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-8 col-md-5">
                          <form>
                            <div className="input-group input-group-merge input-group-flush border rounded">
                              <div className="input-group-prepend input-group-text ms-2">
                                <i className="bi-search"></i>
                              </div>
                              <input
                                id="datatableSearch"
                                type="search"
                                className="form-control formSearch pe-2"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={(e) => handleSearch(e)}
                                onKeyPress={(e) => {
                                  if (e.charCode == 13) {
                                    handleSearch(e);
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-lg-auto">
                          <div className="row g-3 align-items-center">
                            <div className="col-12 col-sm-auto">
                              <div className="fw-bold">Filter By Date</div>
                            </div>
                            <div className="col-auto">
                              <form>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </form>
                            </div>
                            <div className="col-auto">
                              <form>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </form>
                            </div>
                            <div className="col-auto">
                              <div className="tom-select-custom">
                                <select
                                  id="datatableEntries"
                                  className="js-select form-select w-auto selectForm  "
                                  autoComplete="off"
                                  data-hs-tom-select-options=""
                                  onChange={(e) => setStatus1(e.target.value)}
                                  defaultValue={"DEFAULT"}
                                >
                                  <option value="DEFAULT" defaultValue={false}>
                                    Select Payment Status
                                  </option>
                                  <option
                                    value={true}
                                    selected={status1 == "true" ? true : false}
                                  >
                                    Paid
                                  </option>
                                  <option
                                    value={false}
                                    selected={status1 == "false" ? true : false}
                                  >
                                    Not Paid
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="dgrid d-sm-flex gap-2 justify-content-md-start justify-content-sm-end justify-content-center ms-sm-2">
                                <button
                                  className="btn btn-dark pageBtn"
                                  type="button"
                                  onClick={filterByStatus}
                                >
                                  {" "}
                                  Apply
                                </button>
                                <button
                                  className="btn btn-dark pageBtn"
                                  type="button"
                                  onClick={clearFilter}
                                >
                                  {" "}
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card">
                        <div className="table-responsive datatable-custom">
                          <DataTable
                            pagination
                            columns={subscriptionsColumns}
                            data={isSearch ? filterdData : subscriptions}
                            fixedHeaderScrollHeight="408px"
                            highlightOnHover
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="weekly"
                  role="tabpanel"
                  aria-labelledby="weekly-tab"
                >
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-8 col-md-5">
                          <form>
                            <div className="input-group input-group-merge input-group-flush border rounded">
                              <div className="input-group-prepend input-group-text ms-2">
                                <i className="bi-search"></i>
                              </div>
                              <input
                                id="datatableSearch"
                                type="search"
                                className="form-control formSearch pe-2"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={(e) => handleSearchPaidEvents(e)}
                                onKeyPress={(e) => {
                                  if (e.charCode == 13) {
                                    handleSearchPaidEvents(e);
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-lg-auto">
                          <div className="row g-3 align-items-center">
                            <div className="col-12 col-sm-auto">
                              <div className="fw-bold">Filter By Date</div>
                            </div>
                            <div className="col-auto">
                              <form>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={startDatePaidEvents}
                                  onChange={(e) =>
                                    setStartDatePaidEvents(e.target.value)
                                  }
                                />
                              </form>
                            </div>
                            <div className="col-auto">
                              <form>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={endDatePaidEvents}
                                  onChange={(e) =>
                                    setEndDatePaidEvents(e.target.value)
                                  }
                                />
                              </form>
                            </div>
                            <div className="col-auto">
                              <div className="dgrid d-sm-flex gap-2 justify-content-md-start justify-content-sm-end justify-content-center ms-sm-2">
                                <button
                                  className="btn btn-dark pageBtn"
                                  type="button"
                                  onClick={filterByDatePaidEvents}
                                >
                                  {" "}
                                  Apply
                                </button>
                                <button
                                  className="btn btn-dark pageBtn"
                                  type="button"
                                  onClick={() => {
                                    setEndDatePaidEvents("");
                                    setStartDatePaidEvents("");
                                    setIsSearchPaidEvents("");
                                  }}
                                >
                                  {" "}
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card">
                        <div className="table-responsive datatable-custom">
                          <DataTable
                            pagination
                            columns={paidEventsColumns}
                            data={
                              isSearchPaidEvents
                                ? filterdDataPaidEvents
                                : paidEvents
                            }
                            fixedHeaderScrollHeight="408px"
                            highlightOnHover
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEarning;
