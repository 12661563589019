import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { postData } from "../../FetchService";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextError from "../../CommonPages/TextError";
const Reset = () => {
  const [pass, setpassword] = useState(false);
  const [confirmpass, setconfirmpassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let Email = location?.state?.id?.email;

  const handleReset = async (value) => {
    const data = {
      email: Email,
      otp: value?.otp,
      password: value?.password,
    };

    const res = await postData("reset-password", data);

    if (res.status == true) {
      // localStorage.setItem("token", res.data.auth);
      // if (res.status) {
      //     await userprofile();
      // }
      setTimeout(() => {
        navigate("/");
      }, 2000);
      toast.success("SuccessFully Password Changed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const myValidations = yup.object({
    otp: yup.string().required("OTP is Required !"),
    password: yup
      .string()
      // .matches(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W]).{8,}$/, 'Minimum eight characters, at least one letter, one number and one special character !')
      .required("New Password is Required "),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <div className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: "url(assets/svg/components/card-6.svg)",
        }}
      >
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
      </div>
      <div className="container py-5 py-sm-7 vh-100">
        <div
          className="mx-auto h-100 row align-items-center justify-content-center"
          style={{ maxWidth: "30rem" }}
        >
          <div className="card card-lg mb-5">
            <ToastContainer autoClose={1000} />
            <div className="card-body px-2 py-3 p-sm-5">
              <Formik
                initialValues={{ otp: "", password: "", confirmpassword: "" }}
                validationSchema={myValidations}
                onSubmit={(value) => handleReset(value)}
              >
                <Form className="js-validate needs-validation" noValidate>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Reset Password</h1>
                      <p>
                        Enter OTP which you received on entered email address
                        and reset your password.
                      </p>
                    </div>
                  </div>
                  <div className="mb-4 d-flex flex-column gap-3">
                    <Field
                      type="text "
                      className="form-control form-control-lg"
                      name="otp"
                      id="resetPasswordSrEmail"
                      tabIndex="1"
                      placeholder="OTP"
                      aria-label="OTP"
                      required
                    />
                    <p className="text-danger text-start">
                      <ErrorMessage name="otp" component={<TextError />} />
                    </p>
                    <label
                      className="form-label w-100 m-0 mt-n2"
                      htmlFor="signupSrPassword"
                      tabIndex="0"
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <a
                          className="form-label-link mb-0 ms-auto text-black"
                          href="authentication-reset-password-basic.html"
                        >
                          Resend OTP
                        </a>
                      </span>
                    </label>
                    <div
                      className="input-group input-group-merge"
                      data-hs-validation-validate-classname
                    >
                      <Field
                        type={pass ? "text" : "password"}
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                        id=""
                        placeholder="New Password"
                        aria-label="8+ characters required"
                        required
                        minLength="8"
                        data-hs-toggle-password-options='{
                                                  "target": "#changePassTarget",
                                              "defaultclassName": "bi-eye-slash",
                                                 "showclassName": "bi-eye",
                                                "classNameChangeTarget": "#changePassIcon"
                                                 }'
                      />
                      <div
                        id="changePassTarget"
                        className="input-group-append input-group-text"
                      >
                        <i
                          id="changePassIcon"
                          title={pass ? "Hide password" : "Show password"}
                          className="bi-eye"
                          onClick={() => setpassword((prevState) => !prevState)}
                        ></i>
                      </div>
                    </div>
                    <p className="text-danger text-start">
                      <ErrorMessage name="password" />
                    </p>
                    <div
                      className="input-group input-group-merge"
                      data-hs-validation-validate-classname
                    >
                      <Field
                        type={confirmpass ? "text" : "password"}
                        className="js-toggle-password form-control form-control-lg"
                        name="confirmpassword"
                        id=""
                        placeholder="Confirm New Password"
                        aria-label="8+ characters required"
                        required
                        minLength="8"
                        data-hs-toggle-password-options='{
                                                 "target": "#changePassTarget",
                                                 "defaultclassName": "bi-eye-slash",
                                                   "showclassName": "bi-eye",
                                                "classNameChangeTarget": "#changePassIcon"
                                                       }'
                      />
                      <a
                        id="changePassTarget"
                        className="input-group-append input-group-text"
                      >
                        <i
                          title={
                            confirmpass ? "Hide password" : "Show password"
                          }
                          id="changePassIcon"
                          onClick={() =>
                            setconfirmpassword((prevState) => !prevState)
                          }
                          className="bi-eye"
                        ></i>
                      </a>
                    </div>
                    <p className="text-danger text-start">
                      <ErrorMessage name="confirmpassword" />
                    </p>
                  </div>
                  <div className="d-flex gap-2">
                    <NavLink
                      className="btn btn-primary w-100"
                      to="/forgetpassword"
                    >
                      <i className="bi-chevron-left"></i> Back{" "}
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      to="/"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
