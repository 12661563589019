import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData, putData } from "../../FetchService";
const ViewEventDetailsPaid = () => {
  const location = useLocation();

  let userDetails = location?.state?.user;
  let id = location.state.items;
  const navigate = useNavigate();

  const [singleDel, setSingleDel] = useState();
  const [publiceventDetails, setPublicEventDetails] = useState();
  let adminDetails1 = userDetails?.firstName && userDetails?.lastName;
  let adminDetails =
    publiceventDetails?.admin?.firstName && publiceventDetails?.admin?.lastName;
  let name = `${publiceventDetails?.admin?.firstName} ${publiceventDetails?.admin?.lastName}`;
  let name1 = `${userDetails?.firstName} ${userDetails?.lastName}`;

  const publiceventDetail = async () => {
    const res = await getData(`event-details?id=${id}`);
    setPublicEventDetails(res.data);
  };

  useEffect(() => {
    publiceventDetail();
  }, [id]);

  const deleteSingleEvent = async (id) => {
    let data = {
      id: [id],
    };

    const res = await putData("delete-events", data);

    if (res.status) {
      setTimeout(() => {
        navigate("/managepublicevent");
      }, 1000);
      toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <Header />
      <SideBar />
      <div id="content" role="main" className="main">
        <div className="content container-fluid manage_user manage_event_list manageUpcomingFree">
          <div className="pageheader">
            <div className="row align-items-center mb-3 gap-3 gap-sm-0">
              <div className="col-sm d-flex align-items-center gap-2">
                <h1 className="page-header-title m-0">View Event Details</h1>
              </div>

              <div className="col-12 col-sm-auto">
                <div className="gap-3 d-flex">
                  {publiceventDetails?.status == 1 &&
                  publiceventDetails?.participants == 0 ? (
                    <>
                      <NavLink
                        className="btn btn-primary addEventBtn"
                        to="managepublicaddevent"
                        state={{ isEdit: true, data: publiceventDetails }}
                      >
                        Edit
                      </NavLink>
                      <button
                        type="button"
                        className="btn btn-primary addEventBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#askdeletecategory"
                        onClick={() => setSingleDel(publiceventDetails)}
                      >
                        Delete
                      </button>
                      <NavLink to="/managepublicevent">
                        <button
                          type="button"
                          className="btn btn-danger addEventBtn"
                        >
                          Cancel Event
                        </button>
                      </NavLink>
                    </>
                  ) : (
                    <div className="d-flex col-12 align-items-end justify-content-end">
                      <button
                        onClick={() => window.history.go(-1)}
                        type="button"
                        className="btn btn-danger addEventBtn"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pageBody mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card p-3 pb-4">
                  <div className="row gap-3 gap-md-0">
                    <div className="col-md-7 col-lg-6 col-xl-7 col-xxl-6">
                      <div className="row gap-3 gap-sm-0">
                        <div className=" col-sm-4 col-md-5 col-lg-6">
                          <div className="imgOuter">
                            <img
                              className="w-100 h-100"
                              src={
                                publiceventDetails?.image
                                  ? publiceventDetails?.image
                                  : "assets/img/event-img-1.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-sm col-md-7 col-lg-6">
                          <div className="row">
                            <div className="col col-md-12">
                              <div className="eventDetail">
                                {publiceventDetails?.name}
                              </div>
                              <div className="smallTxt my-1 my-sm-2 eventDec">
                                {publiceventDetails?.template}
                              </div>
                              <div className="smallTxt my-1 my-sm-2 startDate">
                                Start Date:
                                {new Date(
                                  publiceventDetails?.startDate
                                ).getUTCDate()}{" "}
                                {new Date(
                                  publiceventDetails?.startDate
                                ).toLocaleString("default", {
                                  month: "short",
                                })}{" "}
                                {new Date(
                                  publiceventDetails?.startDate
                                ).getFullYear()}
                              </div>
                              <div className="smallTxt my-1 my-sm-2 endDate">
                                End Date:{" "}
                                {new Date(
                                  publiceventDetails?.endDate
                                ).getUTCDate()}{" "}
                                {new Date(
                                  publiceventDetails?.endDate
                                ).toLocaleString("default", {
                                  month: "short",
                                })}{" "}
                                {new Date(
                                  publiceventDetails?.endDate
                                ).getFullYear()}
                              </div>
                            </div>
                            <div className="col-auto col-md-12 customCol">
                              <button className="typeEvent rounded d-inline-block text-white w-50 btn btn-secondary">
                                Public :
                                {publiceventDetails?.amount > 0
                                  ? "PAID"
                                  : "FREE"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-6 col-xl-5 col-xxl-6">
                      <div className="diveder border-top d-md-none"></div>
                      <div className="row pt-3 pt-md-0 ">
                        <div className="col">
                          <ul className="p-0 m-0 list-unstyled">
                            <li className="mb-1 mb-sm-2">
                              <b>Qualifying Activities:</b>{" "}
                              <span>
                                {publiceventDetails?.activities?.map((el) => {
                                  return el == 1
                                    ? "Running "
                                    : "" || el == 2
                                    ? " Cycling "
                                    : "" || el == 3
                                    ? " Jumping "
                                    : "" || el == 4
                                    ? " Walking "
                                    : "" || el == 5
                                    ? "Skipping "
                                    : "";
                                })}
                              </span>
                            </li>

                            <li className="my-1 my-sm-2">
                              <b>Event Participants:</b>{" "}
                              <span>{publiceventDetails?.participants}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-auto customCol">
                          <div className="typeEvent rounded text-white d-inline-block  p-1">
                            {publiceventDetails?.status == 1 ? (
                              <button className=" btn btn-primary h-50  d-flex inline align-items-center block text-white">
                                Upcomming
                              </button>
                            ) : publiceventDetails?.status == 2 ? (
                              <button className=" btn btn-warning h-50  d-flex inline align-items-center block text-white">
                                Ongoing
                              </button>
                            ) : publiceventDetails?.status === 3 ? (
                              <button className=" btn btn-success h-50  d-flex inline align-items-center block text-white">
                                Completed
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="">
                  <div className="sectionTitle text-black mb-3">
                    Description
                  </div>
                  <span className="smallTxt">
                    {publiceventDetails?.description}
                  </span>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className=" mb-3">
                  <div className="sectionTitle text-black">
                    Event Achievements
                  </div>
                </div>
                <div className="card p-3 p-sm-4  gap-2">
                  <div className="text-black">
                    <b>Reward Points:</b>{" "}
                    <span> {publiceventDetails?.points}</span>
                  </div>
                  <div className="text-black">
                    <b>Provide Medal:</b>{" "}
                    <span>
                      {publiceventDetails?.isMedal == false ? "NO" : "YES"}
                    </span>
                  </div>
                  <span className="smallTxt">
                    {" "}
                    {publiceventDetails?.rewardDesc}
                  </span>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="row g-3 align-items-end justify-content-between">
                  <div className="col-12 col-sm-6 col-lg-5 col-xl-4">
                    <div className=" mb-3">
                      <div className="sectionTitle text-black">Event Admin</div>
                    </div>
                    <div className="card p-3 p-sm-4  gap-2">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="adminImg rounded-pill overflow-hidden">
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50px",
                              }}
                              src={
                                publiceventDetails?.admin?.image ||
                                userDetails?.image
                                  ? publiceventDetails?.admin?.image ||
                                    userDetails?.image
                                  : "assets/img/160x160/img6.jpg"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="text-black">
                            <b>
                              {adminDetails
                                ? name
                                : adminDetails1
                                ? name1
                                : "No Admin"}
                            </b>
                          </div>
                          <span className="smallTxt">Admin</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-auto">
                    <div className="card p-3 gap-2 bg-dark">
                      <div className="sectionTitle text-white">
                        Total Amount Earned
                      </div>
                      <div className="sectionTitle text-white">
                        ₹
                        {publiceventDetails?.amount
                          ? publiceventDetails?.amount
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {publiceventDetails?.status == 1 ? (
                ""
              ) : (
                <div className="col-12 mt-4">
                  <div className="  mb-3 d-flex justify-content-between align-items-center">
                    <div className="sectionTitle text-black">
                      Event Leaderboard
                    </div>
                    <div className=" text-black fs-sm-12">
                      <i>Members: {publiceventDetails?.leaderboard.length}</i>
                    </div>
                  </div>

                  <div className="card overflow-hidden border-0">
                    <div className="table-responsive datatable-custom rounded-top  subscriptionInner">
                      <table
                        id="datatable"
                        className="table table-bordered table-thead-bordered table-nowrap table-align-middle card-table tabelCustom"
                        data-hs-datatables-options=""
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="table-column">Rank</th>
                            <th>Athlete</th>
                            <th>Distance (KM)</th>
                          </tr>
                        </thead>

                        <tbody>
                          {publiceventDetails?.leaderboard?.map((el, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td className="table-column">
                                  <div className="d-flex align-items-center">
                                    <h5 className="text-inherit mb-0">
                                      {el.firstName && el.lastName
                                        ? `${el.firstName} ${el.lastName}`
                                        : "No Leader"}
                                    </h5>
                                  </div>
                                </td>
                                <td>
                                  {(el.activities.distance / 1000).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Modal for delete --> */}
        <div
          className="modal fade"
          id="askdeletecategory"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete the category -{" "}
                <strong>{publiceventDetails?.name}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => deleteSingleEvent(publiceventDetails?._id)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEventDetailsPaid;
