import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import Moment from "react-moment";
import { useEffect } from "react";
import { getData } from "../../FetchService";

const ViewUserDetails = () => {
  const location = useLocation();
  let id = location.state.item;
  const [uAge, setuAge] = useState();
  const [usersdetails, setuserdetails] = useState();

  const userDetailsApi = async () => {
    const res = await getData(`user-details?id=${id}`);
    setuserdetails(res.data);
  };

  useEffect(() => {
    userDetailsApi();
  }, [id]);

  const calculate_Age = () => {
    var today = new Date();
    var birthDate = new Date(usersdetails?.dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    // var m = today.getMonth() - birthDate.getMonth();
    // if(m < 0 || (m === 0 && today.getDate() < birthDate.getDate())){
    //     age_now--;
    // }
    setuAge(age_now - 1);
  };

  function getTimeRemaining(startDate, expireDate) {
    let total;
    if (Date.parse(startDate) > Date.parse(expireDate)) {
      total = Date.parse(startDate) - Date.parse(expireDate);
    } else {
      total = Date.parse(expireDate) - Date.parse(startDate);
    }
    // const total = Date.parse(new Date()) - Date.parse(endtime);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);

    let minute, hour, day, month;
    if (months > 0) {
      month = months;
    } else if (months == 0 && days > 0) {
      day = days;
    } else if (months == 0 && days == 0 && hours > 0) {
      hour = hours;
    } else if (months == 0 && days == 0 && hours == 0 && minutes > 0) {
      minute = months;
    }
    return {
      minute,
      hour,
      day,
      month,
    };
  }

  let calculate_Duration = getTimeRemaining(
    usersdetails?.subscription?.startDate,
    usersdetails?.subscription?.expiresAt
  );
  useEffect(() => {
    calculate_Age();
  }, [usersdetails?.dob]);

  return (
    <div className="main mt-8">
      <div className="content container-fluid manage_user manage_event_list manageUpcomingFree">
        <div className="pageheader">
          <div className="row align-items-center mb-3 gap-3 gap-sm-0">
            <div className="col-sm d-flex align-items-center gap-2">
              <h1 className="page-header-title m-0">View User Details</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="card mb-3 mb-lg-5">
              <div className="card-body">
                <h3 className="mb-0 mb-4">
                  <span className="">Basic Details</span>
                </h3>
                <div className="row align-items-center mb-5">
                  <div className="col mx-0 row align-items-center">
                    <div className="flex-shrink-0 col-auto px-0">
                      <div className="avatar avatar-lg avatar-circle">
                        <img
                          className="avatar-img"
                          src={
                            usersdetails?.image
                              ? usersdetails?.image
                              : "https://t4.ftcdn.net/jpg/01/06/92/47/240_F_106924759_7qPPu6bZNN2O4al1ExdEWBdHUcpKMwuJ.jpg"
                          }
                          alt="Image Description"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 mx-3 col-auto ps-0">
                      <div className="d-flex mb-1">
                        <h5 className="m-0">
                          {usersdetails?.firstName && usersdetails?.lastName ? (
                            `${usersdetails?.firstName} ${usersdetails?.lastName}`
                          ) : (
                            <span className="text-danger">Not Given</span>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-5">
                    <div className="card p-3 ">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="rank_img">
                            <img
                              src="../admin/assets/img/rank.png"
                              alt=""
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-auto">
                          <h3 className="text-start">{usersdetails?.rank}</h3>
                          <div className="">Rank</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-7 mt-sm-0 mt-3">
                    <div className="card px-3">
                      <div className="row align-items-center">
                        <div className="col-auto py-3">
                          <div className="rank_img">
                            <img
                              src="../admin/assets/img/point.png"
                              alt=""
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-auto py-3">
                          <h3 className="text-start">{usersdetails?.points}</h3>
                          <div className="">Overall Points Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-auto mt-3">
                    <div className="card p-3">
                      <h3 className="text-center">{usersdetails?.following}</h3>
                      <div className="text-center">Followings</div>
                    </div>
                  </div>
                  <div className="col-sm-auto mt-3">
                    <div className="card p-3">
                      <h3 className="text-center">{usersdetails?.followers}</h3>
                      <div className="text-center">Followers</div>
                    </div>
                  </div>
                  <div className="col-sm-auto mt-3">
                    <div className="card p-3">
                      <h3 className="text-center">
                        {usersdetails?.totalActivities
                          ? usersdetails?.totalActivities
                          : "0"}
                      </h3>
                      <div className="text-center">Activities</div>
                    </div>
                  </div>
                  <div className="col-sm-auto mt-3">
                    <NavLink
                      to="manageeventlist"
                      state={{ id: usersdetails?.events }}
                    >
                      <div className="card p-3">
                        <h3 className="text-center">
                          {usersdetails?.events?.length}
                        </h3>
                        <div className="text-center">Participated Events</div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-auto mt-3">
                    <NavLink
                      to="manageclubslist"
                      state={{ id: usersdetails?.clubs }}
                    >
                      <div className="card p-3">
                        <h3 className="text-center">
                          {usersdetails?.clubs?.length}
                        </h3>
                        <div className="text-center">Participated Clubs</div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3 mb-lg-5">
              <div className="card-body">
                <h3 className="mb-0 mb-3">
                  <span className="border-bottom">Subscription Details</span>
                </h3>
                {!!usersdetails?.subscription ? (
                  <>
                    <div className="mb-2">
                      <strong>Plan Name:</strong>
                      <span> {usersdetails?.subscription?.name}</span>
                    </div>
                    <div className="mb-2">
                      <strong>Plan Amount:</strong>
                      <span> {usersdetails?.subscription?.price}</span>
                    </div>
                    <div className="mb-2">
                      <strong>Plan Start Date:</strong>
                      <span>
                        {" "}
                        <Moment format="DD/MM/YY">
                          {usersdetails?.subscription?.startDate
                            ? usersdetails?.subscription?.startDate
                            : ""}
                        </Moment>
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>Plan Duration:</strong>
                      <span>
                        {" "}
                        {calculate_Duration.month > 0
                          ? `${calculate_Duration.month} Months `
                          : ""}
                        {calculate_Duration.day > 0
                          ? `${calculate_Duration.day} Days`
                          : ""}
                        {calculate_Duration.hour > 0
                          ? `${calculate_Duration.hour} Hours `
                          : ""}
                        {calculate_Duration.minute > 0
                          ? `${calculate_Duration.minute} Minutes `
                          : ""}
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>Plan Expiry Date:</strong>
                      <span>
                        {" "}
                        <Moment format="DD/MM/YY">
                          {usersdetails?.subscription?.expiresAt
                            ? usersdetails?.subscription?.expiresAt
                            : ""}
                        </Moment>
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>No. of Events (FREE):</strong>
                      <span> {usersdetails?.subscription?.eventsLeft}</span>
                    </div>
                  </>
                ) : (
                  <div className="mb-2 d-flex align-items-center justify-content-center text-danger">
                    <strong>No subscription available</strong>
                    {/* <span> {usersdetails?.subscription?.eventsLeft}</span> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-3 mb-lg-5">
              <div className="card-body">
                <ul className="list-unstyled list-py-2 mb-0">
                  <li className="pb-0">
                    <span className="card-subtitle">
                      <strong>About</strong>
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                    <strong>Gender</strong> :{" "}
                    {usersdetails?.gender ? (
                      usersdetails?.gender
                    ) : (
                      <span className="text-danger">Not given</span>
                    )}
                  </li>
                  <li>
                    <i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>
                    <strong>Age</strong> :{" "}
                    {uAge ? (
                      uAge
                    ) : (
                      <span className="text-danger">Not given</span>
                    )}
                  </li>
                  <li className=" pb-0">
                    <span className="card-subtitle">
                      <strong>Contact info</strong>
                    </span>
                  </li>
                  <li>
                    <i className="bi-at dropdown-item-icon"></i>
                    {usersdetails?.email ? (
                      usersdetails?.email
                    ) : (
                      <span className="text-danger">Not given</span>
                    )}
                  </li>
                  <li>
                    <i className="bi-phone dropdown-item-icon"></i>
                    {usersdetails?.mobile ? (
                      usersdetails?.mobile
                    ) : (
                      <span className="text-danger">Not given</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserDetails;
