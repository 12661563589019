import React, { useState, useEffect } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { NavLink, useLocation } from "react-router-dom";
import Moment from "react-moment";

const ReportedUserDetails = () => {
  let location = useLocation();
  let data = location.state.data;
  let cDate = new Date();
  let bDate = new Date(data.user?.dob);
  let rDate = new Date(data.details?.dob);

  let age_now = cDate.getFullYear() - bDate.getFullYear();
  let age_now1 = cDate.getFullYear() - rDate.getFullYear();
  return (
    <div className="main mt-8">
      <div className="content container-fluid">
        <div className="row justify-content-lg-center">
          <div className="pageheader">
            <div className="row align-items-center mb-4">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">
                  View Reported User Details
                </h1>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="profile-cover">
              <div className="profile-cover-img-wrapper">
                <img
                  className="profile-cover-img"
                  src="/assets/img/1920x400/img1.jpg"
                  alt="Image Description"
                />
              </div>
            </div>
            <div className="text-center mb-5">
              <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
                <img
                  className="avatar-img"
                  src={data.details?.image}
                  alt="Image Description"
                />
                <span className="avatar-status avatar-status-success"></span>
              </div>
              <h1 className="page-header-title">
                {data.details?.firstName} {data.details?.lastName}
              </h1>
            </div>
            <div className="js-nav-scroller hs-nav-scroller-horizontal mb-5">
              <span
                className="hs-nav-scroller-arrow-prev"
                style={{ display: "none" }}
              >
                <NavLink className="hs-nav-scroller-arrow-link">
                  <i className="bi-chevron-left"></i>
                </NavLink>
              </span>
              <span
                className="hs-nav-scroller-arrow-next"
                style={{ display: "none" }}
              >
                <NavLink className="hs-nav-scroller-arrow-link">
                  <i className="bi-chevron-right"></i>
                </NavLink>
              </span>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="js-sticky-block card  mb-md-5">
                  <div className="card-header px-3 py-4">
                    <h4 className="card-header-title">Reporter User Details</h4>
                  </div>
                  <div className="card-body row">
                    <div className=" col-md-6 avatar avatar-xl avatar-circle ">
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50px",
                        }}
                        // className="avatar-img"
                        src={data?.user?.image}
                        alt="Image Description"
                      />
                    </div>
                    <ul className="list-unstyled list-py-2 text-dark mb-0 col-md-6">
                      <li className="pb-0">
                        <span className="card-subtitle">About</span>
                      </li>
                      <li>
                        <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                        Name : {data.user?.firstName} {data.user?.lastName}
                      </li>
                      <li>
                        <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                        Gender : {data.user?.gender}
                      </li>
                      <li>
                        <i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>
                        Age : {age_now ? age_now - 1 : "0"}
                      </li>
                      <li className=" pb-0">
                        <span className="card-subtitle">Contacts</span>
                      </li>
                      <li>
                        <i className="bi-at dropdown-item-icon"></i>
                        {data.user?.email}
                      </li>
                      <li>
                        <i className="bi-phone dropdown-item-icon"></i>
                        {data.user?.mobile}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="js-sticky-block card  mb-md-5">
                  <div className="card-header px-3 py-4">
                    <h4 className="card-header-title">Reported User Details</h4>
                  </div>
                  <div className="card-body row">
                    <div className=" col-md-6 avatar avatar-xl avatar-circle ">
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50px",
                        }}
                        // className="avatar-img"
                        src={data.details?.image}
                        alt="Image Description"
                      />
                    </div>
                    <ul className="list-unstyled list-py-2 text-dark mb-0 col-md-6">
                      <li className="pb-0">
                        <span className="card-subtitle">About</span>
                      </li>
                      <li>
                        <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                        Name : {data.details?.firstName}{" "}
                        {data.details?.lastName}
                      </li>
                      <li>
                        <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                        Gender : {data.details?.gender}
                      </li>
                      <li>
                        <i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>
                        Age : {age_now1 ? age_now1 - 1 : "0"}
                      </li>
                      <li className=" pb-0">
                        <span className="card-subtitle">Contacts</span>
                      </li>
                      <li>
                        <i className="bi-at dropdown-item-icon"></i>
                        {data.details?.email}
                      </li>
                      <li>
                        <i className="bi-phone dropdown-item-icon"></i>
                        {data.details?.mobile}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-grid gap-3 gap-lg-5">
                  <div className="card">
                    <div className="card-header card-header-content-between px-3 py-4">
                      <h4 className="card-header-title">Reported Reason</h4>
                    </div>
                    <div
                      className="card-body card-body-height"
                      style={{ height: "10rem" }}
                    >
                      <p>{data?.message ? data?.message : ""}</p>
                    </div>
                    <div className="card-footer">
                      <div className="link link-collapse pb-1">
                        <span className="link-collapse-default">
                          Reported Date and Time:{" "}
                          <span>
                            <Moment format="DD-MMM-YY , hh:mm A">
                              {data.createdAt}
                            </Moment>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportedUserDetails;
