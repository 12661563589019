import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer ,toast} from 'react-toastify';
import Header from '../../CommonPages/Header'
import SideBar from '../../CommonPages/SideBar'
import { getData, putData } from '../../FetchService';


const ViewMedalWallOrderDetails = () => {
  let location = useLocation();
  let locationD = location?.state

  let navigate = useNavigate();
  const [data, setData] = useState()
  const [userAge, setUserAge] = useState()
  const [changeStatus, setChangeStatus] = useState(locationD?.status)
  const [trackid, setTrackId] = useState(locationD?.trackingNumber)


  const updateTrackingId = async () => {
    let Data = {
      id: data?._id,
      status: changeStatus ? changeStatus : locationD?.status,
      trackingNumber: trackid ? trackid : locationD?.trackingNumber
    }
    let res = await putData('change-status', Data)
    if (res.status == true) {
      setTimeout(() => {
        navigate("/manageorder");
      }, 2000);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handlemedalsDetails = async () => {
    const res = await getData(`medal/${location?.state?._id}`)
    setData(res?.data)
  }
  useEffect(() => {
    handlemedalsDetails()
    calculate_age()
  }, [location?.state?._id, data?.user?.dob])

  const calculate_age = () => {
    var today = new Date();
    var birthDate = new Date(data?.user?.dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setUserAge(age_now)
  }

  let height = data?.user?.height / 100
  let heightSqure = height * height
  let weight = data?.user?.weight
  return (
    <>
    <ToastContainer autoClose={1000} />
    
        <div  className="main mt-8">
          <div className="content container-fluid viewMedalOrderDetails">
            <div className="page-header border-0 pb-0 mb-3">
              <div className="row g-2 align-items-center mb-3">
                <div className="col">
                  <h1 className="page-header-title m-0">View Order Details</h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-lg-center">
              <div className="col-lg-12">
                <div className="profile-cover">
                  <div className="profile-cover-img-wrapper">
                    <img className="profile-cover-img" src="/assets/img/1920x400/img1.jpg" alt="Image Description" />
                  </div>
                </div>
                <div className="text-center mb-5">
                  <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
                    <img className="avatar-img" src={data?.user ? data?.user.image : "/assets/img/profile-img.png"} alt="Image Description" />
                    <span className="avatar-status avatar-status-success"></span>
                  </div>
                  <h1 className="page-header-title">{`${data?.user?.firstName} ${data?.user?.lastName}`}</h1>
                </div>
                <div className="row g-3">
                  <div className="col-12">
                    <h2 className="cardTitle">Basic Details:</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card h-100 basicDetailCard">
                      <div className="card-header">
                        <div className="text-dark fw-bold fs-4">Contact info:{" "}{`${data?.user?.firstName} ${data?.user?.lastName}`}</div>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled list-py-2 text-dark mb-0">
                          <li className="pb-0"><span className="card-subtitle">About:</span></li>
                          <li><i className="bi bi-gender-ambiguous dropdown-item-icon"></i>Gender : {data?.user?.gender}</li>
                          <li><i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>Age : {userAge}</li>
                          <li className=" pb-0 mt-2"><span className="card-subtitle">Contacts:</span></li>
                          <li><i className="bi-at dropdown-item-icon"></i>{data?.user?.email}</li>
                          <li><i className="bi-phone dropdown-item-icon"></i>{data?.user?.mobile}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card p-sm-3 p-2 h-100">
                      <div className="row g-3 align-items-center justify-content-center justify-content-sm-start">
                        <div className="col-12">
                          <div className="row g-sm-3 g-2 justify-content-sm-around justify-content-between">
                            <div className="col-auto">
                              <div className="py-3 px-2 text-center">
                                <h3 className="h3Font">{data?.user?.following}</h3>
                                <div>Followings</div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="py-3 px-2 text-center">
                                <h3 className="h3Font">{data?.user?.followers}</h3>
                                <div>Followers</div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="py-3 px-2 text-center">
                                <h3 className="h3Font">{data?.user?.totalActivities}</h3>
                                <div>Activities</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row g-sm-3 g-2 justify-content-sm-around justify-content-between">
                            <div className="col-auto">
                              <div className="py-3 px-3 px-sm-5 text-center">
                                <h3 className="h3Font">{(weight / heightSqure).toFixed(2)}</h3>
                                <div>BMI</div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="py-3 px-2 text-center">
                                <h3 className="h3Font">{data?.user?.height}</h3>
                                <div>Height(cm)</div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="py-3 px-2 text-center">
                                <h3 className="h3Font">{data?.user?.weight}</h3>
                                <div>Weight (kg)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body p-lg-4">
                        <div className="row g-3">
                          <div className="col-12">
                            <h2 className="mb-3 cardTitle">Product Details:</h2>
                            <p>This user has achieved 10 Medals. To celebrate his achievements deliver a MEDAL WALL</p>
                            <ul className="list-unstyled m-0">
                              <li className="mb-2"><span className="fw-bold">Product Type:</span>{" "}{data?.event?.isWall == true ? "Medal Wall" : ""}</li>
                              <li className="mb-2"><span className="fw-bold">Current Product Status:</span>
                              {" "}
                                {
                                  locationD ?.status == 0 ? "Earned" :
                                    locationD ?.status == 1 ? "READY_TO_DISPATCH" :
                                      locationD ?.status == 2 ? "CONFIRMED" :
                                        locationD ?.status == 3 ? "SHIPPED" :
                                          locationD ?.status == 4 ? "DELIVERED" :
                                          locationD ?.status == 5 ? "CANCELED" : ""
                                }
                              </li>
                              <li className="my-4">
                                <div className="row g-2">
                                  <div className="col-12">
                                    <span className="fw-bold">Change Product Status:</span>
                                  </div>
                                  <div className="col-12">
                                    <form action="">
                                      <div className="row g-2">
                                        <div className="col-auto tom-select-custom">
                                          <select onChange={(e) => setChangeStatus(e.target.value)} id="datatableEntries" className="js-select form-select w-auto selectForm" autoComplete="off">
                                            <option value="Update Product Status" defaultValue={false}>Update Product Status</option>
                                            <option value="0" defaultValue={locationD?.status == 0 ? true : false}>Earned</option>
                                            <option value="1" defaultValue={locationD?.status == 1 ? true : false}>Ready to Dispatch</option>
                                            <option value="2" defaultValue={locationD?.status == 2 ? true : false}>CONFIRMED</option>
                                            <option value="3" defaultValue={locationD?.status == 3 ? true : false}>SHIPPED</option>
                                            <option value="4" defaultValue={locationD?.status == 4 ? true : false}>DELIVERED</option>
                                            <option value="5" defaultValue={locationD?.status == 5 ? true : false}>CANCELED</option>
                                          </select>
                                        </div>
                                        <div className="col-auto">
                                          <button onClick={() => setChangeStatus(changeStatus)} className="btn btn-dark pageBtn" type="button">Update</button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-12">

                                    <form action="">
                                      <div className="row g-2">
                                        <div className="col-auto tom-select-custom">
                                          <input type="text" name='trackid' className="form-control" placeholder="Tracking ID" aria-label="" value={trackid} onChange={(e) => setTrackId(e.target.value)} />
                                        </div>
                                        <div className="col-auto">
                                          <button className="btn btn-dark pageBtn" type="button" onClick={updateTrackingId}>Update</button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </li>
                              <li><span className="fw-bold">Delivery Address:</span> {data?.address ? `${data?.address?.building}, ${data?.address?.city}, ${data?.address?.house} ,${data?.address?.landmark} ,${data?.address?.state},${data?.address?.pinCode}` : 'Panchamrut Bunglows Rd, Panchamrut Bunglows II, Thaltej, Ahmedabad, Gujarat 380059'}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body p-lg-4">
                        <div className="row g-3">
                          <div className="col-12 col-lg-6">
                            <h2 className="mb-3 cardTitle">Order Details:</h2>
                            <ul className="list-unstyled m-0">
                              <li className="mb-2"><span className="fw-bold">Order ID:</span> #{data?._id}</li>
                              <li className="mb-2"><span className="fw-bold">Order Date:</span> 
                              {" "}
                                <Moment format='YY/MM/DD'>
                                  {data?.event?.updatedAt}
                                </Moment>
                              </li>
                              <li className="mb-2"><span className="fw-bold">Order Status:</span> 
                              {" "}
                                {
                                  locationD?.status == 0 ? "Earned" :
                                    locationD?.status == 1 ? "READY_TO_DISPATCH" :
                                      locationD?.status == 2 ? "CONFIRMED" :
                                        locationD?.status == 3 ? "SHIPPED" :
                                          locationD?.status == 4 ? "DELIVERED" : 
                                          locationD?.status == 5? "CANCELED" :  ""
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>

  )
}

export default ViewMedalWallOrderDetails