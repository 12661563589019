import React, { useState, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./Pages/Login";
import Reset from "./Pages/Reset Password";
import Dashboard from "./Pages/Dashboard";
import ForgetPassword from "./Pages/ForgetPassword";
import Header from "./CommonPages/Header";
import ManageUsers from "./Pages/Manage Users";
import ViewUserDetails from "./Pages/Manage Users/ViewUserDetails";
import ManageEventList from "./Pages/Manage Users/ManageEventList";
import ManageClubsList from "./Pages/Manage Users/ManageClubsList";
import ManageHealthCategories from "./Pages/Manage Health Categories";
import ManageSubscriptionPlans from "./Pages/Manage Subscription Plans";
import ManageHealthArticles from "./Pages/Manage Health Articles";
import ViewArticleDetails from "./Pages/Manage Health Articles/ViewArticleDetails";
import AddEditHealthArticle from "./Pages/Manage Health Articles/AddEditHealthArticle";
import ManageClubs from "./Pages/Manage Clubs";
import LeaderboardViewClubDetails from "./Pages/Manage Clubs/LeaderboardViewClubDetails";
import ManageEventTemplate from "./Pages/Manage Event Template";
import AddEditClubs from "./Pages/Manage Clubs/AddEditClubs";
import ManagePublicEvent from "./Pages/Manage Public Event";
import ManagePublicAddEvent from "./Pages/Manage Public Event/ManagePublicAddEvent";
import ManageCMS from "./Pages/Manage CMS";
import ManageCorporateEvents from "./Pages/Manage Corporate Event";
import AddEditCorporateEventDetails from "./Pages/Manage Corporate Event/AddEditCorporateEventDetails";
import ViewEventDetailsFree from "./Pages/Manage Public Event/ViewEventDetailsFree";
import ViewEventDetailsPaid from "./Pages/Manage Public Event/ViewEventDetailsPaid";
import AllActivities from "./Pages/Manage Event Template/AllActivities";
import Team from "./Pages/Manage Team";
import ManageTeams from "./Pages/Manage Team/ManageTeams";
import CreateUpdateTeam from "./Pages/Manage Team/CreateUpdateTeam";
import EventDetailsCompleted from "./Pages/Manage Corporate Event/EventDetailsCompleted";
import ViewCorporateEventDetailsFree from "./Pages/Manage Corporate Event/ViewCorpoteEventDetailsFree";
import ViewCorporateEventDetailsPaid from "./Pages/Manage Corporate Event/ViewCorporateEventDetailsPaid";
import CreateEditCorporateTeams from "./Pages/Manage Corporate Event/CreateEditCorporateTeams";
import ManageCorporateTeams from "./Pages/Manage Corporate Event/ManageCorporateTeams";
import Query from "./Pages/Manage Raised Query";
import ViewQuery from "./Pages/Manage Raised Query/ViewQuery";
import TextEditorCms from "./Pages/Manage CMS/TextEditorCms";
import AddNewPlan from "./Pages/Manage Subscription Plans/AddNewPlan";
import ManageEarning from "./Pages/Manage Earning";
import PostDetails from "./Pages/Manage Clubs/PostDetails";
import EditPost from "./Pages/Manage Clubs/EditPost";
import ManageReports from "./Pages/Manage Reports/Index";
import ReportedUserDetails from "./Pages/Manage Reports/ReportedUserDetails";
import Changepassword from "./Pages/ChangePassword/Index";
import ManageOrder from "./Pages/Manage Orders/Index";
import ViewMedalOrderDetails from "./Pages/Manage Orders/ViewMedalOrderDetails";
import ViewMedalWallOrderDetails from "./Pages/Manage Orders/ViewMedalWallOrderDetails";
import EventImages from "./Pages/Manage Event Images/Index";
import Authentication from "./CommonPages/Authentication";
import Config from "./CommonPages/DigitalOcean/Config";
import s3 from "./CommonPages/DigitalOcean/Index";
import { ReportedActivitiesDetails } from "./Pages/Manage Reports/ReportedActivitiesDetails";
import { ReportedPostDetails } from "./Pages/Manage Reports/ReportedPostDetails";
import SideBar from "./CommonPages/SideBar";
import InviteMember from "./Pages/Manage Clubs/InviteMember";
import ManageMerchandise from "./Pages/Manage Merchandise/Index";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Inner />
      </BrowserRouter>
    </div>
  );
}

function Inner() {
  const location = useLocation();
  const [image, setImage] = useState("");

  const convertImageToUrl = (e) => {
    if (e.target.files && e.target.files[0]) {
      const blob = e.target.files[0];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };
      s3.putObject(params)
        .on("build", (request) => {
          request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`;
          request.httpRequest.headers["Content-Length"] = blob.size;
          request.httpRequest.headers["Content-Type"] = blob.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .send((err) => {
          if (err) {
          } else {
            const imageUrl = `${Config.digitalOceanSpaces}` + blob.name;
            setImage(imageUrl);
          }
        });
    }
  };

  const imageSeter = (img) => {
    setImage(img);
  };
  return (
    <Suspense fallback={<h1>...Loading</h1>}>
      {location.pathname == "/dashboard" ||
      location.pathname == "/manageusers" ||
      location.pathname == "/manageusers/viewuserdeials" ||
      location.pathname == "/manageusers/viewuserdeials/manageeventlist" ||
      location.pathname == "/manageusers/viewuserdeials/manageclubslist" ||
      location.pathname == "/managesubscriptionplans" ||
      location.pathname == "/managesubscriptionplans/addnewplan" ||
      location.pathname == "/managehealthcategories" ||
      location.pathname == "/managehealtharticles" ||
      location.pathname == "/managehealtharticles/addedithealtharticle" ||
      location.pathname == "/managehealtharticles/viewarticledetails" ||
      location.pathname == "/manageeventtemplate" ||
      location.pathname == "/manageeventtemplate/allactivities" ||
      location.pathname == "/managepublicevent" ||
      location.pathname == "//managepublicevent/managepublicaddevent" ||
      location.pathname == "/managepublicevent/vieweventdetailspaid" ||
      location.pathname == "/managepublicevent/vieweventdetailsfree" ||
      location.pathname ==
        "/managepublicevent/vieweventdetailsfree/managepublicaddevent" ||
      location.pathname ==
        "/managepublicevent/vieweventdetailspaid/managepublicaddevent" ||
      location.pathname == "/managecorporateevents" ||
      location.pathname ==
        "/managecorporateevents/add-edit-corporate-event-deetails" ||
      location.pathname ==
        "/managecorporateevents/view-corporat-event-Details-Free" ||
      location.pathname ==
        "/managecorporateevents/view-corporate-event-details-paid" ||
      location.pathname ==
        "/managecorporateevents/view-corporat-event-Details-Free/add-edit-corporate-event-deetails" ||
      location.pathname ==
        "/managecorporateevents/view-corporate-event-details-paid/add-edit-corporate-event-deetails" ||
      location.pathname ==
        "/managecorporateevents/view-corporat-event-Details-Free/manage-corporate-teams" ||
      location.pathname ==
        "/managecorporateevents/view-corporate-event-details-paid/manage-corporate-teams" ||
      location.pathname ==
        "/managecorporateevents/view-corporat-event-Details-Free/manage-corporate-teams/create-edit-corporate-teams" ||
      location.pathname ==
        "/managecorporateevents/view-corporate-event-details-paid/manage-corporate-teams/create-edit-corporate-teams" ||
      location.pathname ==
        "//managecorporateevents/view-corporate-event-details-paid/manage-corporate-teams/create-edit-corporate-teams" ||
      location.pathname == "/ManageMerchandise" ||
      location.pathname == "/manageclubs" ||
      location.pathname == "/manageclubs/addeditclubs" ||
      location.pathname == "/manageclubs/leaderboardviewclubdetails" ||
      location.pathname == "/manageclubs/leaderboardviewclubdetails/editpost" ||
      location.pathname ==
        "/manageclubs/leaderboardviewclubdetails/editpost/postdetails" ||
      location.pathname == "/raisedquery" ||
      location.pathname == "/raisedquery/viewquery" ||
      location.pathname == "/managereports" ||
      location.pathname == "/managereports/reporteduserdetails" ||
      location.pathname == "/managereports/reportedactivitiesdetails" ||
      location.pathname == "/managereports/reportedpostdetails" ||
      location.pathname == "/manageearning" ||
      location.pathname == "/manageorder" ||
      location.pathname == "/manageorder/viewmedalorderdetails" ||
      location.pathname == "/manageorder/viewmedalwallorderdetails" ||
      location.pathname == "/managecms" ||
      location.pathname == "/managecms/texteditorcms" ||
      location.pathname == "/team" ||
      location.pathname == "/team/manageteams" ||
      location.pathname == "/manageteams/createupdateteam" ||
      location.pathname == "/team/manageteams/createupdateteam" ||
      location.pathname == "/Event-Images" ||
      location.pathname == "/invitemember" ||
      location.pathname == "/changepassword" ? (
        <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl   footer-offset mb-0">
          <Header />

            {/* <SideBar /> */}

        </div>
      ) : (
        ""
      )}
      <Routes>
        <Route element={<Authentication />}>
          <Route
            exact
            path="/"
            element={
              localStorage.getItem("token") ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />

          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/changepassword" element={<Changepassword />} />

          {/* Manage User  */}
          <Route>
            <Route exact path="/manageusers" element={<ManageUsers />} />

            <Route>
              <Route
                exact
                path="manageusers/viewuserdeials"
                element={<ViewUserDetails />}
              />

              <Route
                exact
                path="/manageusers/viewuserdeials/manageeventlist"
                element={<ManageEventList />}
              />
              <Route
                exact
                path="/manageusers/viewuserdeials/manageclubslist"
                element={<ManageClubsList />}
              />
            </Route>
          </Route>

          {/* Manage Subscription Plan */}
          <Route>
            <Route
              path="managesubscriptionplans"
              element={<ManageSubscriptionPlans />}
            />
            <Route
              path="managesubscriptionplans/addnewplan"
              element={<AddNewPlan />}
            />
          </Route>

          {/* Manage Health Categories */}
          <Route
            exact
            path="/managehealthcategories"
            element={<ManageHealthCategories />}
          />

          <Route>
            <Route
              exact
              path="/managehealtharticles"
              element={<ManageHealthArticles />}
            />
            <Route
              exact
              path="managehealtharticles/addedithealtharticle"
              element={
                <AddEditHealthArticle
                  imageSeter={imageSeter}
                  image={image}
                  convertImageToUrl={convertImageToUrl}
                />
              }
            />

            <Route
              exact
              path="managehealtharticles/viewarticledetails"
              element={<ViewArticleDetails />}
            />
          </Route>
          {/* ManageMerchandise */}
          <Route>
            <Route path="/ManageMerchandise" element={<ManageMerchandise />} />
          </Route>

          {/* Manage Event Template */}
          <Route>
            <Route
              path="/manageeventtemplate"
              element={<ManageEventTemplate />}
            />
            <Route
              path="manageeventtemplate/allactivities"
              element={<AllActivities />}
            />
            managepublicaddevent
          </Route>

          {/* Manage Public Event */}
          <Route>
            <Route path="/managepublicevent" element={<ManagePublicEvent />} />
            <Route>
              <Route
                path="managepublicevent/managepublicaddevent"
                element={
                  <ManagePublicAddEvent
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
              <Route
                path="managepublicevent/vieweventdetailsfree/managepublicaddevent"
                element={
                  <ManagePublicAddEvent
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
              <Route
                path="managepublicevent/vieweventdetailspaid/managepublicaddevent"
                element={
                  <ManagePublicAddEvent
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
            </Route>

            <Route
              path="managepublicevent/vieweventdetailsfree"
              element={<ViewEventDetailsFree />}
            />

            <Route
              path="managepublicevent/vieweventdetailspaid"
              element={<ViewEventDetailsPaid />}
            />
          </Route>

          {/* Manage Corporate */}
          <Route>
            <Route
              path="/managecorporateevents"
              element={<ManageCorporateEvents />}
            />
            <Route>
              <Route
                path="managecorporateevents/add-edit-corporate-event-deetails"
                element={
                  <AddEditCorporateEventDetails
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
              <Route
                path="managecorporateevents/view-corporat-event-Details-Free/add-edit-corporate-event-deetails"
                element={
                  <AddEditCorporateEventDetails
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
              <Route
                path="managecorporateevents/view-corporate-event-details-paid/add-edit-corporate-event-deetails"
                element={
                  <AddEditCorporateEventDetails
                    imageSeter={imageSeter}
                    image={image}
                    convertImageToUrl={convertImageToUrl}
                  />
                }
              />
              <Route
                path="managecorporateevents/view-corporate-event-details-paid/manage-corporate-teams"
                element={<ManageCorporateTeams />}
              />
              <Route
                path="managecorporateevents/view-corporat-event-Details-Free/manage-corporate-teams"
                element={<ManageCorporateTeams />}
              />
              <Route
                path="managecorporateevents/view-corporat-event-Details-Free/manage-corporate-teams/create-edit-corporate-teams"
                element={<CreateEditCorporateTeams />}
              />
              <Route
                path="managecorporateevents/view-corporate-event-details-paid/manage-corporate-teams/create-edit-corporate-teams"
                element={<CreateEditCorporateTeams />}
              />
            </Route>

            <Route
              path="managecorporateevents/view-corporat-event-Details-Free"
              element={<ViewCorporateEventDetailsFree />}
            />
            <Route
              path="managecorporateevents/view-corporate-event-details-paid"
              element={<ViewCorporateEventDetailsPaid />}
            />

            {/* <Route
              path="create-edit-corporate-teams"
              element={<CreateEditCorporateTeams />}
            />


            <Route
              path="/eventdetailscompleted"
              element={<EventDetailsCompleted />}
            /> */}
          </Route>

          {/* Manage Clubs  */}
          <Route>
            <Route path="/manageclubs" element={<ManageClubs />} />
            <Route path="/invitemember" element={<InviteMember />} />
            <Route
              path="manageclubs/addeditclubs"
              element={
                <AddEditClubs
                  imageSeter={imageSeter}
                  image={image}
                  convertImageToUrl={convertImageToUrl}
                />
              }
            />

            <Route>
              <Route
                path="manageclubs/leaderboardviewclubdetails"
                element={<LeaderboardViewClubDetails />}
              />

              <Route>
                <Route
                  path="manageclubs/leaderboardviewclubdetails/editpost"
                  element={
                    <EditPost
                      imageSeter={imageSeter}
                      image={image}
                      convertImageToUrl={convertImageToUrl}
                    />
                  }
                />
                <Route
                  path="manageclubs/leaderboardviewclubdetails/editpost/postdetails"
                  element={<PostDetails />}
                />
              </Route>
            </Route>
          </Route>

          {/* Query */}
          <Route>
            <Route path="/raisedquery" element={<Query />} />
            <Route path="raisedquery/viewquery" element={<ViewQuery />} />
          </Route>

          {/* Manage Reports */}
          <Route>
            <Route path="/managereports" element={<ManageReports />} />
            <Route
              path="managereports/reporteduserdetails"
              element={<ReportedUserDetails />}
            />
            <Route
              path="managereports/reportedactivitiesdetails"
              element={<ReportedActivitiesDetails />}
            />
            <Route
              path="managereports/reportedpostdetails"
              element={<ReportedPostDetails />}
            />
          </Route>

          {/* manage earning */}
          {/* <Route> */}
          <Route path="/manageearning" element={<ManageEarning />} />
          {/* <Route path="manageearning/userdetails" element={<UserDetails />} />
            <Route path="manageearning/eventsdetails" element={<EventsDetails />} />
            </Route> */}

          {/* Manage CMS */}
          <Route>
            <Route path="/managecms" element={<ManageCMS />} />
            <Route path="managecms/texteditorcms" element={<TextEditorCms />} />
          </Route>

          {/* Manage Team */}
          <Route>
            <Route path="/team" element={<Team />} />
            <Route>
              <Route path="team/manageteams" element={<ManageTeams />} />
              <Route
                path="team/manageteams/createupdateteam"
                element={<CreateUpdateTeam />}
              />
            </Route>
          </Route>

          {/* Manage Order */}
          <Route>
            <Route path="/manageorder" element={<ManageOrder />} />
            <Route
              path="manageorder/viewmedalorderdetails"
              element={<ViewMedalOrderDetails />}
            />
            <Route
              path="manageorder/viewmedalwallorderdetails"
              element={<ViewMedalWallOrderDetails />}
            />
          </Route>
          <Route
            path="/Event-Images"
            element={
              <EventImages
                imageSeter={imageSeter}
                image={image}
                convertImageToUrl={convertImageToUrl}
              />
            }
          />
        </Route>
        <Route index exact path="/login" element={<Login />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/reset" element={<Reset />} />
      </Routes>
    </Suspense>
  );
}

export default App;
