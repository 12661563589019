import React, { useCallback, useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate, useLocation } from "react-router-dom";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { getData, putData } from "../../FetchService";

export default function Posts({}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [id, setId] = useState(location?.state?.id);
  const [slideIndexText, setSlideIndexText] = useState("");
  const [deletePost, setDeletePost] = useState("");
  const sideOptions = { startIndex: 3 };
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const getPost = async () => {
    const res = await getData(`club-posts?id=${id}`);
    setPosts(res?.data);
  };
  useEffect(() => {
    getPost();
  }, []);
  const deletePostHandler = async (idd) => {
    let data = {
      id: idd,
    };
    const res = await putData("delete-post", data);
    setDeletePost("");
    if (res.status) {
      setOpen(false);
      getPost();
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpen(false);
    } else {
      setOpen(false);
      toast.error(`${res.message}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const onStartSlide = useCallback((idx, length) => {
    setSlideIndexText(`${idx} / ${length}`);
  }, []);
  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className="row g-2">
        <div className="col-md-8">
          <div className="d-grid gap-3 gap-md-5">
            {posts?.length ? (
              posts?.map((el, i) => {
                return (
                  <div key={i} className="card">
                    <div className="card-body viewReprtedActivityDetailsOuter px-2 px-sm-3 text-black">
                      <div className="col-12 bg-white card_box_sh">
                        <div className="row mx-0 card_box_profile">
                          <div className="col-12">
                            <div className="row align-items-center justify-content-between">
                              <div className="col-auto px-0">
                                <div className="d-flex align-items-center user_name_image">
                                  <div className="customImageBorder">
                                    <div className="custom_user_image">
                                      <img
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "50%",
                                        }}
                                        src={el?.user?.image}
                                        className="user_image"
                                        alt="Profile_pic"
                                      />
                                    </div>
                                  </div>
                                  <div className="profile_user_text text-black">
                                    <div className="user_name mb-sm-2 ms-2">
                                      {`${el?.user?.firstName} ${el?.user?.lastName}`}
                                    </div>
                                    <div className="time_and_Date ms-2">
                                      {/* 22 Jan 20, 11:00 PM */}
                                      <Moment format="DD-MMM-YY hh:mm A">
                                        {el?.createdAt}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col text-end">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn btn-white btn-sm"
                                    id="actionsDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </button>
                                  <div
                                    className="dropdown-menu mt-1"
                                    aria-labelledby="actionsDropdown"
                                  >
                                    <div
                                      className="dropdown-item cursor-pointer"
                                      // onClick={() =>
                                      //   navigate(
                                      //     "/manageclubs/leaderboardviewclubdetails/editpost",
                                      //     {
                                      //       state: { edit: true, data: el },
                                      //     }
                                      //   )
                                      // }
                                      onClick={() =>
                                        navigate("editpost/postdetails", {
                                          state: {
                                            id: el._id,
                                          },
                                        })
                                      }
                                    >
                                      <i className="bi bi-info-circle-fill me-2 "></i>
                                      Details
                                    </div>
                                    <div
                                      className="dropdown-item cursor-pointer"
                                      onClick={() => {
                                        setOpen(true);
                                        setDeletePost(el?._id);
                                      }}
                                    >
                                      <i className="bi bi-trash3-fill me-2"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-decoration-none text-black"></div>
                              <div className="col-12 text_Message mb-2 px-0">
                                <div className="morning_walk_time text-black">
                                  {el.name}
                                </div>
                                <div className="summery_text text-black btn">
                                  {el.description}
                                  <div
                                    // to={{
                                    //   pathname:
                                    //     "editpost/postdetails",
                                    //   state: { data: el },
                                    // }}
                                    onClick={() =>
                                      navigate("editpost/postdetails", {
                                        state: {
                                          id: el._id,
                                        },
                                      })
                                    }
                                    className="text-decoration-none text-primary"
                                  >
                                    View more
                                  </div>
                                </div>
                              </div>

                              {/* el.images */}
                              <div className="col-12 map mb-2 px-0">
                                <div
                                  className="maps_card"
                                  ref={ref}
                                  style={{
                                    minWidth: "400px",
                                    height: "400px",
                                  }}
                                >
                                  {el?.images?.length < 2 ? (
                                    <img
                                      style={{
                                        height: "400px",
                                        width: "509px",
                                      }}
                                      src={el?.images}
                                      alt="map"
                                    />
                                  ) : (
                                    <SimpleImageSlider
                                      width={width}
                                      height={height}
                                      images={el?.images}
                                      showBullets={true}
                                      showNavs={true}
                                      autoPlay={true}
                                      bgColor="#000"
                                      navStyle={2}
                                      onStartSlide={onStartSlide}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-12 map mb-2 px-0 d-flex align-items-center justify-content-center">
                                {el?.images?.length > 1 ? (
                                  <span>
                                    {slideIndexText ||
                                      `${sideOptions.startIndex} / ${el?.images?.length}`}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-12 mb-md-2 like_com_shr px-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center gap-md-3 gap-1 gap-sm-2 text-black">
                                    <span className="widText">
                                      <span className="wid me-2">
                                        <i className="bi bi-hand-thumbs-up"></i>
                                      </span>
                                      {el.likes.length} likes
                                    </span>
                                    <span className="widText">
                                      <span className="wid me-2">
                                        <i className="bi bi-chat-left-text"></i>
                                      </span>
                                      {el.comments.length} Comments
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-12 d-flex align-items-center justify-content-center card">
                <span className="text-danger  mt-3 mb-3">No Data Found</span>
              </div>
            )}
          </div>
        </div>
        {/*<-- modal ---> */}
        <div
          className={`modal fade ${open ? "show" : ""}`}
          id="multypleEventDelete"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={open ? "true" : "false"}
          style={{
            display: open ? "block" : "none",
          }}
          role={open ? "dialog" : ""}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">Do you want to delete post</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => deletePostHandler(deletePost)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setOpen(false);
                    setDeletePost("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
