import React ,{useState,useEffect} from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { useNavigate } from "react-router-dom";
import { getData } from "../../FetchService";



export default function ManageCMS() {
  const [Allcms,setAllcms]=useState({});
  const navigate = useNavigate()

  const allCmsData = async () => {
    // setLoader(true);
    const res = await getData("cms");
    setAllcms(res.data);
    // setLoader(false);
  };
  useEffect(() => {
    allCmsData();
  }, []);

  return (
   
      <div  className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list managePublicEvent">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">CMS</h1>
              </div>
            </div>
          </div>
          <div className="card border-0">
            <div className="table-responsive datatable-custom ">
              <table
                id="datatable"
                className="table table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th className="table-column">SR.NO</th>
                    <th className="w-100">CMS Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="table-column">About Us</td>
                    <td>
                      <div className="d-flex gap-3">
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i  onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'aboutUs' ,
                                data:Allcms.aboutUs, type:"view"}
                              })
                            } className="bi bi-eye-fill"></i>
                        </button>
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i
                            onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'aboutUs' ,
                                data:Allcms.aboutUs},
                              })
                            }
                            className="bi bi-pen-fill"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="table-column">Privacy Policy</td>
                    <td>
                      <div className="d-flex gap-3">
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'privacyPolicy',
                                data:Allcms.privacyPolicy, type:"view"}
                              })
                            } className="bi bi-eye-fill"></i>
                        </button>
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i
                            onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'privacyPolicy',
                                data:Allcms.privacyPolicy},
                              })
                            }
                            className="bi bi-pen-fill"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="table-column">Terms & Conditions</td>
                    <td>
                      <div className="d-flex gap-3">
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i  onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'termsAndConditions' ,
                                data:Allcms.termsAndConditions, type:"view"}
                              })
                            } className="bi bi-eye-fill"></i>
                        </button>
                        <button
                          type="button"
                          className="btn border-0 shadow-none"
                        >
                          <i
                            onClick={() =>
                              navigate("texteditorcms", {
                                state: { cms: 'termsAndConditions',
                                data:Allcms.termsAndConditions },
                              })
                            }
                            className="bi bi-pen-fill"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
   
  );
}
