import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, putData } from "../../FetchService";
import { NavLink, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Moment from 'react-moment'
import { createGlobalStyle } from "styled-components";
import { toast, ToastContainer } from "react-toastify";
const ManageReports = () => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportPost, setReportPost] = useState([]);
  const [searchusers, setSearchusers] = useState("");
  const [searchactivities, setSearchActivities] = useState("");
  const [searchpost, setSearchPost] = useState("");
  const [filterReport, setFilterReport] = useState([]);
  const [singleId, setSingleId] = useState([]);
  const [multipleRow, setMultipleRow] = useState();
  const [toggleRow, setToggleRow] = useState(false);




  const handleData = async () => {
    let res = await getData("get-reports");
    setData(res.data);
    let d = res.data.filter((item) => {
      if (item.type == 1) {
        return item;
      }
    });
    setUserData(d);
    setFilterReport(d)
  };
  const filterHandler = () => {
    let d = data.filter((item) => {
      if (item.type == 2) return item;
    });
    setReportData(d);
    setFilterReport(d)
  };
  const filterReportedPost = () => {
    let fill = data.filter((item) => {
      if (item.type == 3) return item;
    });
    setReportPost(fill);
    setFilterReport(fill)
  };

  const searchFilter = async (e) => {
    e.preventDefault();
    setSearchusers(e.target.value);
    let a = userData.filter((el) => {
      const name = `${el.user.firstName} ${el.user.lastName}`
      if (name.toString().toLowerCase().includes(e.target.value)) {
        return el
      }
    })
    setFilterReport(a)
  };

  const searchFilterActivites = (e) => {
    e.preventDefault();
    setSearchActivities(e.target.value);
    let b = reportData.filter((el) => {
      const name = `${el.user.firstName} ${el.user.lastName}`
      if (name.toLowerCase().includes(e.target.value)) {
        return el
      }
    })
    setFilterReport(b)
  }
  const searchFilterPost = (e) => {
    e.preventDefault();
    setSearchPost(e.target.value);
    let b = reportPost.filter((el) => {
      const name = `${el.user.firstName} ${el.user.lastName}`
      if (name.toLowerCase().includes(e.target.value)) {
        return el
      }
    })
    setFilterReport(b)
  }

  useEffect(() => {
    handleData();
  }, []);
  useEffect(() => {
    filterHandler()
    filterReportedPost()
  }, [data]);

  
  const handleSingleDelete = async (item) => {
    let body = {
      id: [item]
    }
    const res = await putData("delete-reports", body)
    if (res.status) {
      handleData()
      filterHandler()
      filterReportedPost()
      return toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
     
        handleData()
        filterHandler()
        filterReportedPost()
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handleSelectedRowsChange = useCallback(
    (row) => {
      const selected = row.selectedRows.map((x) => x._id);
      setMultipleRow(selected);
    },
    [multipleRow]
  );

  const handleMultipleDelete = async () => {
    let data = {
      id: multipleRow,
    };

    const res = await putData("delete-reports", data);
    if (res.status && multipleRow.length > 0) {
      handleData()
        filterHandler()
        filterReportedPost()
      return toast.success(
        `${multipleRow.length} reports deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      handleData()
        filterHandler()
        filterReportedPost()
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`No reports select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  
  const columns = [
    {
      name: "SR.NO",

      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Reported Users",
      selector: (row) =>
        row.details
          ? row.details.firstName
            ? `${row.details.firstName} ${row.details.lastName}`
            : "N"
          : "",
      sortable: true,
      center: true,
    },
    {
      name: "Reporter Name",
      selector: (row) =>
        row.user
          ? row.user.firstName
            ? `${row.user.firstName} ${row.user.lastName}`
            : ""
          : "",
      sortable: true,
      center: true,
    },
    {
      name: "Report Reason",
      selector: (row) => (row.message ? row.message : ''),
      center: true,
      style: { width: "570px" },
    },
    {
      name: "Reported Date and Time",
      selector: (row) =>
        row.createdAt ? row.createdAt.toString().slice(0, 16) : "",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="reporteduserdetails"
            state={{ data: row }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
       
          <NavLink
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => setSingleId(row._id)}

          >
            <i className="bi bi-trash-fill"></i>
          </NavLink>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "SR.NO",
      selector: (row, i) =>i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Reported Users",
      selector: (row) => row.details.user?.firstName && row.details.user?.lastName ? `${row.details.user?.firstName} ${row.details.user?.lastName}` : row.details.user.mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Reporter Name",
      selector: (row) => row.user?.firstName && row.user?.lastName ? `${row.user.firstName} ${row.user.lastName}` : row.user.mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Report Reason",
      selector: (row) => (row.message ? row.message : ""),
      center: true,
      style: { width: "570px" },
    },
    {
      name: "Reported Date and Time",
      selector: (row) => <Moment format="DD/MM/YY , hh:mm A">
        {row.createdAt}
      </Moment>,
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="reportedactivitiesdetails"
            state={{ data: row ,  }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
          <NavLink
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => setSingleId(row._id)}
          >
            <i className="bi bi-trash-fill"></i>
          </NavLink>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];
  const columns3 = [
    {
      name: "SR.NO",

      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Reported Users",
      selector: (row) => row.details.user?.firstName && row.details.user?.lastName ? `${row.details.user?.firstName} ${row.details.user?.lastName}` : row.details.user.mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Reporter Name",
      selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
      sortable: true,
      center: true,
    },
    {
      name: "Report Reason",
      selector: (row) => (row.message ? row.message : ""),
      center: true,
      style: { width: "570px" },
    },
    {
      name: "Reported Date and Time",
      selector: (row) => <Moment format="DD/MM/YY , hh:mm A">
        {row.createdAt}
      </Moment>,
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="reportedpostdetails"
            state={{ data: row }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
          <NavLink
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => setSingleId(row._id)}
          >
            <i className="bi bi-trash-fill"></i>
          </NavLink>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];
  return (
    <>
      <ToastContainer autoClose={1000} />
      <>
       
        <div className="main mt-8">
          <div className="content container-fluid manageReportOuter">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-sm mb-2 mb-sm-0">
                  <h1 className="page-header-title">Manage Reports</h1>
                </div>
                <div className="col-sm-auto navTabs">
                  <ul
                    className="nav nav-segment"
                    id="leaderboardTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="all-time-tab"
                        data-bs-toggle="tab"
                        href="#manage-reports"
                        role="tab"
                      >
                        Manage Users
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="weekly-tab"
                        data-bs-toggle="tab"
                        href="#reported-activities"
                        role="tab"
                        onClick={filterHandler}
                      >
                        Reported Activities
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="monthly-tab"
                        data-bs-toggle="tab"
                        href="#reported-posts"
                        role="tab"
                        onClick={filterReportedPost}
                      >
                        Reported Posts
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card overflow-hidden">
              <div className="tab-content" id="leaderboardTabContent">
                <div
                  className="tab-pane fade show active"
                  id="manage-reports"
                  role="tabpanel"
                  aria-labelledby=""
                >
                  <div className="card-header border-bottom-0 card-header-content-md-between row align-items-center">
                    <div className="mb-2 mb-md-0 col-sm-auto">
                      <form>
                        <div className="input-group input-group-merge input-group-flush">
                          <div className="input-group-prepend input-group-text">
                            <i className="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            placeholder="Search users"
                            aria-label="Search users"
                            onChange={(e) => searchFilter(e)}
                            onKeyPress={(event) => {
                              if (event.charCode == 13) {

                                // setsearch(event)
                                searchFilter(event)
                              }
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col pt-sm-0 pt-3 px-0 active_inactive_btn_bar d-sm-flex gap-2 justify-content-center justify-content-sm-end">
                      <div className="row mx-0 justify-content-between">
                        <div className="col-auto ps-0">
                          <button
                            type="button"
                            className="btn btn-white border-0 px-2"
                            data-bs-toggle="modal"
                            data-bs-target="#multypleEventDelete"
                          >
                            <i
                              className="bi bi-trash-fill fs-1"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive datatable-custom manageReportInner">
                    <DataTable
                      pagination
                      columns={columns}
                      data={searchusers ? filterReport : userData}
                      onSelectedRowsChange={handleSelectedRowsChange}
                      selectableRows
                      clearSelectedRows={toggleRow}
                     
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="reported-activities"
                  role="tabpanel"
                  aria-labelledby=""
                >
                  <div className="card-header border-bottom-0 card-header-content-md-between row align-items-center">
                    <div className="mb-2 mb-md-0 col-sm-auto">
                      <form>
                        <div className="input-group input-group-merge input-group-flush">
                          <div className="input-group-prepend input-group-text">
                            <i className="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            placeholder="Search users"
                            aria-label="Search users"
                            onChange={(e) => searchFilterActivites(e)}
                            onKeyPress={(event) => {
                              if (event.charCode == 13) {
                                searchFilterActivites(event)
                              }
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col pt-sm-0 pt-3 px-0 active_inactive_btn_bar d-sm-flex gap-2 justify-content-center justify-content-sm-end">
                      <div className="row mx-0 justify-content-between">
                        <div className="col-auto ps-0">
                          <button
                            type="button"
                            className="btn btn-white border-0 px-2"
                            data-bs-toggle="modal"
                            data-bs-target="#multypleEventDelete"
                          >
                            <i
                              className="bi bi-trash-fill fs-1"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive datatable-custom manageReportInner">
                    <DataTable
                      pagination
                      columns={columns2}
                      data={searchactivities ? filterReport : reportData}
                      onSelectedRowsChange={handleSelectedRowsChange}
                      selectableRows
                      clearSelectedRows={toggleRow}
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="reported-posts"
                  role="tabpanel"
                  aria-labelledby=""
                >
                  <div className="card-header border-bottom-0 card-header-content-md-between row align-items-center">
                    <div className="mb-2 mb-md-0 col-sm-auto">
                      <form>
                        <div className="input-group input-group-merge input-group-flush">
                          <div className="input-group-prepend input-group-text">
                            <i className="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            placeholder="Search users"
                            aria-label="Search users"
                            onChange={(e) => searchFilterPost(e)}
                            onKeyPress={(event) => {
                              if (event.charCode == 13) {
                                searchFilterPost(event)
                              }
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col pt-sm-0 pt-3 px-0 active_inactive_btn_bar d-sm-flex gap-2 justify-content-center justify-content-sm-end">
                      <div className="row mx-0 justify-content-between">
                        <div className="col-auto ps-0">
                          <button
                            type="button"
                            className="btn btn-white border-0 px-2"
                            data-bs-toggle="modal"
                            data-bs-target="#multypleEventDelete"
                          >
                            <i
                              className="bi bi-trash-fill fs-1"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive datatable-custom manageReportInner">
                    <DataTable
                      pagination
                      columns={columns3}
                      data={searchpost ? filterReport : reportPost}
                      onSelectedRowsChange={handleSelectedRowsChange}
                      selectableRows
                      clearSelectedRows={toggleRow}
                    />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="askdeletecategory"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete the User
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleSingleDelete(singleId)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade "
          id="multypleEventDelete"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete {multipleRow?.length} user
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleMultipleDelete()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>{setMultipleRow([]); setToggleRow(!toggleRow)}}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>

  );
};

export default ManageReports;
