import AWS from "aws-sdk";
/**
 * Digital Ocean Spaces Connection
 */
const spaceEndPoint = new AWS.Endpoint("nyc3.digitaloceanspaces.com");

const s3 = new AWS.S3({
  endpoint: spaceEndPoint,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default s3;
