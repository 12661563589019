import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, putData } from "../../FetchService";
import { ToastContainer, toast } from "react-toastify";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";

import Activity from "./Activity";
import Posts from "./Posts";
import ClubMembers from "./ClubMembers";
NoDataToDisplay(Highcharts);
Highcharts.setOptions({
  lang: {
    noData: "No data is available in the chart",
  },
  noData: {
    style: {
      fontWeight: "bold",
      color: "red",
    },
  },
});
const LeaderboardViewClubDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(location?.state?.id);
  const [modalOpen, setModalOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [active, setActive] = useState("leaderboard");
  const [clubDetails, setClubDetails] = useState({});

  const handleClubDetails = async () => {
    const res = await getData(`club-details?id=${id}`);
    setClubDetails(res?.data);
    setActivities(res?.data?.totalActivities);
  };
  useEffect(() => {
    handleClubDetails();
  }, [id]);

  const deleteClub = async (id) => {
    let body = {
      id: [id],
    };
    let res = await putData("delete-clubs", body);
    if (res.status) {
      setTimeout(() => {
        navigate("/manageclubs");
      }, 2000);
      return toast.success(`${res.message} successful !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hr ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  const groupByCategory = activities?.reduce((group, product) => {
    const { userId } = product;
    group[userId] = group[userId] || [];
    group[userId].push(product);
    return group;
  }, {});
  let chartData = [];
  Object.values(groupByCategory)?.map((item) => {
    let d = 0;
    let t = 0;
    let ud = {};
    item.map((el) => {
      return (d += el.distance) && (t += el.time) && (ud = el.user);
    });
    return chartData.push({
      totalDistance: Math.round(d / 1000),
      totalTime: Math.round(t / 3600),
      user: ud,
      ratio: d / t,
    });
  });
  chartData.sort((a, b) => (a.ratio > b.ratio ? -1 : 1));
  let maxDis = 0;
  let maxTime = 0;
  var ser = [],
    len = chartData.length < 10 ? chartData.length : 10,
    i = 0;

  for (i; i < len; i++) {
    let mt = chartData[i]?.totalTime;
    let md = chartData[i]?.totalDistance;
    if (mt < chartData[i + 1]?.totalTime) {
      maxTime = chartData[i + 1]?.totalTime;
    } else {
      maxTime = mt;
    }
    if (md < chartData[i + i]?.totalDistance) {
      maxDis = chartData[i + 1]?.totalDistance;
    } else {
      maxDis = md;
    }
    ser.push({
      name: `${chartData[i].user?.firstName} ${chartData[i].user?.lastName}`,
      dataLabels: {
        useHTML: true,
        enabled: true,
        format: `<img style="border-radius: 50px"  height=30 width=30 src="${chartData[i].user?.image}"/>`,
      },
      data: [{ x: chartData[i].totalTime, y: chartData[i].totalDistance }],

      borderWidth: 4,
    });
  }
  let t = maxTime;
  let tr = Math.round(t / 10) * 10;
  let tres = tr < t ? tr + 10 : tr;
  let d = maxDis;
  let dr = Math.round(d / 10) * 10;
  let dres = dr < d ? dr + 10 : dr;
  const getOptions = (type) => ({
    chart: {
      type,
      width: 600,
      height: 400,
    },
    title: {
      text: _.startCase(`progress chart`),
    },
    accessibility: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },

          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
          },
        },
      ],
    },

    tooltip: {
      positioner: function (labelWidth, labelHeight, point) {
        var tooltipX = point.plotX + 8;
        var tooltipY = point.plotY - 8;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      formatter: function () {
        return (
          '<span style="color:black;font-weight:bold;">' +
          `${this.series.name}<br>` +
          '<b style="color:#D31B22;font-weight:bold;">' +
          `D-${this.y} km <br>` +
          '<b style="color:#D31B22;font-weight:bold;margin: 100px">' +
          `T-${this.x} hr<br>`
        );
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolWidth: 5,
      symbolHeight: 8,
    },
    yAxis: {
      title: {
        text: "Distsnce (km)",
      },
      min: 0,
      max: dres < 100 ? 100 : dres + 30,
      tickInterval: 10,
    },

    xAxis: {
      title: {
        text: "Time (hr)",
      },
      min: 8,
      max: tres < 100 ? 100 : tres + 10,
      tickInterval: 10,
    },
    plotOptions: {
      series: {
        lineWidth: 10,
      },
    },
    series: ser,
    function(chart) {
      if (chart.series.length < 1) {
        chart.renderer
          .text("No Data Available", 140, 120)
          .css({
            color: "#4572A7",
            fontSize: "16px",
          })
          .add();
      }
    },
  });

  useEffect(() => {
    if (location?.state?.delete) {
      setActive("post");
    }
  }, [location.state.delete]);
  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid leaderboard">
          <div className="pageheader">
            <div className="row g-2 align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">View Club Details</h1>
              </div>
              <div className="col-sm-auto">
                <div className="row mt-2 mt-sm-0">
                  <div className="col-auto">
                    <button
                      className="btn btn-dark px-5"
                      onClick={() =>
                        navigate("/manageclubs/addeditclubs", {
                          state: { edit: true, data: clubDetails },
                        })
                      }
                    >
                      Edit
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-dark"
                      onClick={() => setModalOpen(true)}
                    >
                      Delete Club
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className=" d-flex  ">
                <div className="col-md-4  msauto" style={{ width: "30%" }}>
                  <div className="w-200 ">
                    <img
                      height={"200px"}
                      width={"200px"}
                      className="w-200 img-fluid"
                      src={clubDetails?.image}
                      alt="image"
                    />
                  </div>
                </div>
                <div className="col-md-6 " style={{ width: "70%" }}>
                  <div className="row">
                    <div className="col-12 ">
                      <h5>{clubDetails?.name}</h5>
                      <p className="prog_l">{clubDetails?.description} </p>
                    </div>
                    <div className="col-12">
                      <h5>Club Activities</h5>
                      <div className="row align-items-center g-3">
                        <div className="col-auto">
                          <div className="row gap-2 align-items-center">
                            {clubDetails?.activities?.map((el, i) => {
                              return (
                                <div
                                  key={i}
                                  className="col-auto border rounded"
                                >
                                  <div className="py-2 px-4 text-black fw-bold">
                                    {el == 1
                                      ? "Running"
                                      : "" || el == 2
                                      ? "Cycling"
                                      : "" || el == 3
                                      ? "Jumping"
                                      : "" || el == 4
                                      ? "Skipping"
                                      : ""}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className=" text-center " style={{ width: "" }}>
                          <div className="row align-items-center border p-3 rounded me-6">
                            <div className="col">
                              <div className=" mb-3">
                                {clubDetails?.members?.length}
                              </div>
                              <div className="text-black fw-bold tabs_name">
                                RUNNERS
                              </div>
                            </div>
                            <div className="col ">
                              <div className="mb-3">
                                {clubDetails?.totalActivities?.length}
                              </div>
                              <div className="text-black fw-bold tabs_name">
                                ACTIVITIES
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">{clubDetails?.posts}</div>
                              <div className="text-black fw-bold tabs_name">
                                POSTS
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <h5 className="">Club Leaderboard</h5>
                <div className="tbs">
                  <div className="navtbs">
                    <ul className="nav nav-segment" id="myTab" role="tablist">
                      <li className="nav-item ms-2" role="presentation">
                        <NavLink
                          className={`nav-link ${
                            active == "leaderboard"
                              ? "active text-primary"
                              : "bg-light"
                          }`}
                          id="homeleaderboard-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#homeleaderboard"
                          type="button"
                          role="tab"
                          aria-controls="homeleaderboard"
                          aria-selected={
                            active == "leaderboard" ? "true" : "false"
                          }
                          tabIndex={active == "leaderboard" ? "-1" : ""}
                          onClick={() => {
                            setActive("leaderboard");
                          }}
                        >
                          Club Leaderboard
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2" role="presentation">
                        <NavLink
                          className={`nav-link ${
                            active == "activity"
                              ? "active text-primary"
                              : "bg-light"
                          }`}
                          id="Activities-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Activities"
                          type="button"
                          role="tab"
                          aria-controls="Activities"
                          aria-selected={
                            active == "activity" ? "true" : "false"
                          }
                          tabIndex={active == "activity" ? "-1" : "1"}
                          onClick={() => {
                            setActive("activity");
                          }}
                        >
                          Activities
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2" role="presentation">
                        <NavLink
                          className={`nav-link ${
                            active == "member"
                              ? "active text-primary"
                              : "bg-light"
                          }`}
                          id="contactmembers-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contactmembers"
                          type="button"
                          role="tab"
                          aria-controls="contactmembers"
                          aria-selected={active == "member" ? "true" : "false"}
                          tabIndex={active == "member" ? "-1" : "1"}
                          onClick={() => {
                            setActive("member");
                          }}
                        >
                          Club Members{" "}
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2" role="presentation">
                        <NavLink
                          to=""
                          className={`nav-link active ${
                            active == "post"
                              ? "active text-primary"
                              : "bg-light"
                          }`}
                          id="contactPosts-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contactPosts"
                          type="button"
                          role="tab"
                          aria-controls="contactPosts"
                          aria-selected={active == "post" ? "true" : "false"}
                          tabIndex={active == "post" ? "-1" : ""}
                          onClick={() => {
                            setActive("post");
                          }}
                        >
                          Posts
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content my-3" id="myTabContent">
                    {/* leaderboard */}
                    <div
                      className={`tab-pane fade  ${
                        active == "leaderboard" ? "active show" : ""
                      }`}
                      id="homeleaderboard"
                      role="tabpanel"
                      aria-labelledby="homeleaderboard-tab"
                    >
                      <div className="row mb-3">
                        <div className="col-md-8 d-flex align-items-center justify-content-center">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={getOptions("column")}
                          />
                        </div>
                        <div className="col-md-4 custom_Tables border p-3 rounded">
                          <div className="row align-items-center mb-3 border-bottom py-2">
                            {clubDetails?.admin ? (
                              <>
                                <div className="col-auto px-1 px-sm-2">
                                  <img
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      borderRadius: "50px",
                                    }}
                                    src={clubDetails?.admin?.image}
                                    alt=""
                                  />
                                </div>
                                <div className="col px-1 px-sm-2">
                                  <h4 className="text-black mb-0 table_name">
                                    {`${clubDetails?.admin?.firstName} ${clubDetails?.admin?.lastName}`}
                                  </h4>
                                </div>
                              </>
                            ) : (
                              <div className="col px-1 px-sm-2">
                                <h4 className="text-danger mb-0 table_name">
                                  No Admin !
                                </h4>
                              </div>
                            )}
                            <div className="col-auto">
                              {clubDetails?.maxDistance?.count == 0 ? (
                                <div className="name_l">
                                  <span className="fw-bold text-black me-1">
                                    {clubDetails?.maxCalories?.count}
                                  </span>
                                  Total Club CLs
                                </div>
                              ) : (
                                <div className="name_l">
                                  <span className="fw-bold text-black me-1">
                                    {clubDetails?.maxDistance?.count
                                      ? clubDetails?.maxDistance?.count
                                      : 0}
                                  </span>
                                  Total Club KMs
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-auto px-1 px-sm-2">
                              <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                <img
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={clubDetails?.maxDistance?.user?.image}
                                  alt="user image"
                                />
                              </div>
                            </div>
                            <div className="col px-1 px-sm-2">
                              <h4 className="text-black mb-0 table_name">
                                Leading Distanse
                              </h4>
                            </div>
                            <div className="col-auto">
                              <div className="name_l">
                                {clubDetails?.maxDistance?.count
                                  ? clubDetails?.maxDistance?.count / 1000
                                  : 0}{" "}
                                Km
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-auto px-1 px-sm-2">
                              <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                <img
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={clubDetails?.maxTime?.user?.image}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col px-1 px-sm-2">
                              <h4 className="text-black mb-0 table_name">
                                Leading Time
                              </h4>
                            </div>
                            <div className="col-auto">
                              <div className="name_l">
                                {clubDetails?.maxTime?.count
                                  ? secondsToHms(clubDetails?.maxTime?.count)
                                  : 0 + " sec"}
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-auto px-1 px-sm-2">
                              <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                <img
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={clubDetails?.maxCalories?.user?.image}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col px-1 px-sm-2">
                              <h4 className="text-black mb-0 table_name">
                                Leading Calories
                              </h4>
                            </div>
                            <div className="col-auto">
                              <div className="name_l">
                                {clubDetails?.maxCalories?.count
                                  ? clubDetails?.maxCalories?.count
                                  : 0}{" "}
                                Kcal
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-auto px-1 px-sm-2">
                              <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                <img
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={clubDetails?.maxPoints?.user?.image}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col px-1 px-sm-2">
                              <h4 className="text-black mb-0 table_name">
                                Leading Points
                              </h4>
                            </div>
                            <div className="col-auto">
                              <div className="name_l">
                                {clubDetails?.maxPoints?.count
                                  ? clubDetails?.maxPoints?.count
                                  : 0}{" "}
                                pts
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 px-0 mt-5">
                          <div className="table-responsive datatable-custom border rounded">
                            <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" className="">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="datatableCheckAll"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="datatableCheckAll"
                                      ></label>
                                    </div>
                                  </th>
                                  <th className="">Rank</th>
                                  <th className="">Athlete</th>
                                  <th className="">Distanse(KM)</th>
                                </tr>
                              </thead>

                              {clubDetails?.leaderboard?.length ? (
                                clubDetails?.leaderboard?.map((el, i) => {
                                  return (
                                    <tbody key={i} id="addVariantsContainer">
                                      <tr>
                                        <td className="table-column-pe-0">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="productVariationsCheck1"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="productVariationsCheck1"
                                            ></label>
                                          </div>
                                        </td>
                                        <td className="">{i + 1}</td>
                                        <td>
                                          <img
                                            className="avatar"
                                            src={el?.user?.image}
                                            alt="Image Description"
                                          />
                                          <span className="ms-2">
                                            {`${el?.user?.firstName} ${el?.user?.lastName}`}
                                          </span>
                                          {clubDetails?.admin?._id ==
                                          el?.user?._id ? (
                                            <span className="text-black ad-text ms-1 rounded-pill">
                                              Admin
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td className="">
                                          {el?.distance
                                            ? el.distance.toFixed(2)
                                            : 0}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td className="col-12 d-flex align-items-center justify-content-center">
                                      <div className="col-12 d-flex align-items-center justify-content-end m-3">
                                        <span className="text-danger">
                                          No Data Found !
                                        </span>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* activity */}
                    <div
                      className={`tab-pane fade ${
                        active == "activity" ? "active show" : ""
                      }`}
                      id="Activities"
                      role="tabpanel"
                      aria-labelledby="Activities-tab"
                    >
                      {active == "activity" ? (
                        <Activity activities={activities} />
                      ) : null}
                    </div>

                    {/* clubMembers */}
                    <div
                      className={`tab-pane fade ${
                        active == "member" ? "active show" : ""
                      }`}
                      id="contactmembers"
                      role="tabpanel"
                      aria-labelledby="contactmembers-tab"
                    >
                      {active == "member" ? <ClubMembers id={id} /> : null}
                    </div>
                    {/* <!-- Posts --> */}
                    <div
                      className={`tab-pane fade ${
                        active == "post" ? " active show" : ""
                      }`}
                      id="contactPosts"
                      role="tabpanel"
                      aria-labelledby="contactPosts-tab"
                    >
                      {active == "post" ? <Posts /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Start */}

      <div
        className={`modal fade ${modalOpen ? "active show" : ""} `}
        id="multypleEventDelete"
        tabIndex="-1"
        aria-hidden={modalOpen ? "true" : "false"}
        style={{
          display: modalOpen ? "block" : "none",
        }}
        role={modalOpen ? "dialog" : ""}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete club -{" "}
              <strong>{location?.state?.data?.name}</strong>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => deleteClub(location?.state?.id)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderboardViewClubDetails;
