import React from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { postData } from "../../FetchService";
import { useNavigate } from "react-router-dom";
import TextError from "../../CommonPages/TextError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Changepassword = () => {
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const navigate = useNavigate();

  const chnagePassword = async (value) => {
    let data = {
      oldPass: value.oldPass,
      newPass: value.newPass,
    };
    if (value.oldPass && value.newPass && value.confirmPass) {
      const res = await postData("change-password", data);

      if (res.status == true) {
        setTimeout(() => {
          navigate("/");
        }, 2000);

        toast.success(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("All field Required !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const changePassScheeme = yup.object().shape({
    oldPass: yup.string().required("Old Password is Required !"),
    newPass: yup
      .string()
      .min(8, "New password must be 8 charecter !")
      .required("New password is required !"),
    confirmPass: yup
      .string()
      .oneOf([yup.ref("newPass"), null], "Password must match"),
  });
  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid changePasswordPage">
          <div className="page-header border-0 pb-0">
            <div className="row align-items-center">
              <div className="col-12 col-sm d-flex align-items-center justify-content-center justify-content-sm-start">
                <div className="row g-3 flex-column">
                  <div className="col-12">
                    <h1 className="page-header-title mb-0">Change Password</h1>
                  </div>
                  <ToastContainer autoClose={1000} />
                  <Formik
                    initialValues={{
                      oldPass: "",
                      newPass: "",
                      confirmPass: "",
                    }}
                    validationSchema={changePassScheeme}
                    onSubmit={(value, { resetForm }) => {
                      chnagePassword(value);
                      resetForm({
                        oldPass: "",
                        newPass: "",
                        confirmPass: "",
                      });
                    }}
                  >
                    {({ errors, touched, values }) => {
                      return (
                        <Form
                          className="js-validate needs-validation"
                          noValidate
                        >
                          <div className="col-12 mb-5">
                            <div
                              className="position-relative input-group input-group-merge mb-1"
                              data-hs-validation-validate-classname
                            >
                              <Field
                                type={active ? "text" : "password"}
                                className="form-control pe-5"
                                name="oldPass"
                                id="oldPass"
                                placeholder="Old Password"
                                aria-label=""
                              />
                              <span className="input-group-append input-group-text">
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() =>
                                    setActive((prevSate) => !prevSate)
                                  }
                                ></i>
                              </span>
                            </div>
                            {errors.oldPass || touched.oldPass ? (
                              <span className="text-danger text-start">
                                <ErrorMessage
                                  name="oldPass"
                                  component={TextError}
                                />
                              </span>
                            ) : null}
                          </div>

                          <div className="col-12 mb-5">
                            <div
                              className="position-relative input-group input-group-merge mb-1"
                              data-hs-validation-validate-classname
                            >
                              <Field
                                type={active1 ? "text" : "password"}
                                className="form-control pe-5 js-toggle-password form-control form-control-lg"
                                name="newPass"
                                id="newPass"
                                placeholder="New Password"
                                aria-label=""
                              />
                              <span className="input-group-append input-group-text">
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() =>
                                    setActive1((prevSate) => !prevSate)
                                  }
                                ></i>
                              </span>
                            </div>
                            {errors.newPass || touched.newPass ? (
                              <span className="text-danger text-start">
                                <ErrorMessage
                                  name="newPass"
                                  component={TextError}
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 mb-5">
                            <div
                              className="position-relative input-group input-group-merge mb-1"
                              data-hs-validation-validate-classname
                            >
                              <Field
                                type={active2 ? "text" : "password"}
                                className="form-control pe-5 js-toggle-password form-control form-control-lg"
                                name="confirmPass"
                                id="confirmPass"
                                placeholder="Confirm Password"
                                aria-label=""
                              />
                              <span className="input-group-append input-group-text">
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() =>
                                    setActive2((prevSate) => !prevSate)
                                  }
                                ></i>
                              </span>
                            </div>
                            {errors.newPass || touched?.confirmPass ? (
                              <span className="text-danger text-start">
                                <ErrorMessage
                                  name="confirmPass"
                                  component={TextError}
                                />
                              </span>
                            ) : null}
                          </div>

                          <div className="col-12 text-center text-sm-start">
                            <button
                              // disabled
                              className="btn btn-dark px-5"
                              type="submit"
                            >
                              UPDATE
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
