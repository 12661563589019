import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { postData } from "../../FetchService";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const [active, setactive] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleLogin = async (value) => {
    const data = {
      email: value?.email,
      password: value?.password,
    };

    const res = await postData("login", data);
    if (res.status == true) {
      // localStorage.setItem("token", res.data.auth);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationschema = yup.object({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        "Enter valid Email !"
      )
      .required("Email is Required !"),
    password: yup
      .string()
      // .matches(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W]).{8,}$/, 'Minimum eight characters, at least one letter, one number and one special character !')
      .required("Password is Required !"),
  });
  useEffect(() => {
    if (token) {
      return navigate("/dashboard");
    }
  }, []);

  return (
    <div className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: "url(assets/svg/components/card-6.svg)",
        }}
      >
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
      </div>
      <div className="container py-5 py-sm-7 vh-100">
        <div
          className="mx-auto h-100 row align-items-center justify-content-center"
          style={{ maxWidth: "30rem" }}
        >
          <div className="card card-lg mb-5">
            <div className="card-body px-2 p-sm-5 py-3 ">
              <ToastContainer autoClose={1000} />
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationschema}
                onSubmit={(value) => handleLogin(value)}
              >
                <Form className="js-validate needs-validation" noValidate>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Login</h1>
                    </div>
                    <div className="d-grid mb-4">
                      {/* <span className="btn btn-white btn-lg">
                        <span className="d-flex justify-content-center align-items-center">
                          <img
                            className="avatar avatar-xss me-2"
                            style={{ width: "1rem" }}
                            src="assets/svg/brands/google-icon.svg"
                            alt="Image Description"
                          />
                          Sign in with Google
                        </span>
                      </span> */}
                    </div>
                    {/* <span className="divider-center text-muted mb-4">OR</span>{" "} */}
                  </div>
                  <div className="mb-4">
                    <Field
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      id="signinSrEmail"
                      tabIndex="1"
                      placeholder="Username"
                      aria-label=""
                      required
                    />
                  </div>
                  <p className="text-danger text-start">
                    <ErrorMessage name="email" />
                  </p>
                  <div className="mb-2">
                    <div
                      className="input-group input-group-merge"
                      data-hs-validation-validate-classname
                    >
                      <Field
                        type={active ? "text" : "password"}
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                        id="signupSrPassword"
                        placeholder="Password"
                        aria-label="8+ characters required"
                        required
                        minLength="8"
                        data-hs-toggle-password-options=""
                      />

                      <span className="input-group-append input-group-text">
                        <i
                          title={active ? "Hide password" : "Show password"}
                          className="bi-eye"
                          onClick={() => setactive((prevSate) => !prevSate)}
                        ></i>
                      </span>
                    </div>
                    <p className="text-danger text-start">
                      <ErrorMessage name="password" />
                    </p>
                  </div>
                  <div className="text-end mb-4">
                    <NavLink
                      className="form-label-link mb-0 ms-auto text-black"
                      to="/forgetpassword"
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-bg">
                      Login
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
