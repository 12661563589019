import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postData, putData } from "../../FetchService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditPost = ({ image, imageSeter, convertImageToUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const seter = imageSeter;
  const [text, setText] = useState("");
  const [media, setMedia] = useState([]);
  const data = location?.state?.data;
  useEffect(() => {
    setText(data?.text);
    setMedia(data?.images);
    if (image) {
      if (media.length < 5) {
        setMedia([...media, image]);
      }
    }
  }, [location.state, image]);
  const HandleChange = (e) => {
    setText(e.target.value);
  };

  const handleRemoveMedia = (img) => {
    if (media.includes(img)) {
      let fill = media.filter((el) => el != img);
      setMedia(fill);
    }
  };
  const browseImage = (e) => {
    if (media.length < 5) {
      convertImageToUrl(e);
    } else {
      toast.success(`You can post only 5 image !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePublish = async () => {
    const body = {
      id: data._id,
      text: text,
      images: media,
    };
    const res = await putData(`edit-post`, body);
    if (res.status == true) {
      setMedia([]);
      seter("");
      navigate("postdetails", { state: { id: res?.data?._id } });
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid viewArticleDetails">
          <div className="page-header border-0 pb-0">
            <div className="row align-items-center">
              <div className="col-12 ">
                <div className="row g-3 mb-2">
                  <div className="col">
                    <h1 className="page-header-title mb-1">
                      Add / Edit Club Post
                    </h1>
                  </div>
                  <div className="col-sm-auto">
                    <div className="row mt-2 mt-sm-0">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary px-5"
                          onClick={() => {
                            window.history.go(-1);
                            seter("");
                            setMedia([]);
                          }}
                        >
                          Discard
                        </button>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-primary"
                          onClick={handlePublish}
                        >
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between px-2 px-sm-4 gap-0">
                  <h4 className="card-header-title">Media</h4>
                </div>

                <div className="card-body px-2 p-sm-4 d-flex row justify-content-center align-items-center">
                  <div className="col-12 d-flex justify-content-center align-items-center mb-3">
                    <label
                      htmlFor="attachFilesNewProjectLabel"
                      className="w-25 js-dropzone dz-dropzone dz-dropzone-card p-3 p-sm-5"
                    >
                      <div className="dz-message">
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3 d-none"
                          src={
                            image
                              ? image
                              : "https://www.istockphoto.com/photos/no-image-available"
                          }
                          alt="Image Description"
                          data-hs-theme-appearance="dark"
                        />
                        <h5>Drag and drop your file here</h5>
                        <p className="mb-2">or</p>
                        <span className="btn btn-white btn-sm">
                          Browse files
                        </span>
                      </div>
                    </label>
                    <input
                      type="file"
                      className="d-none"
                      name=""
                      id="attachFilesNewProjectLabel"
                      onChange={(e) => browseImage(e)}
                    />
                  </div>
                  <div className="row d-flex  justify-content-start align-items-center ms-4">
                    {media.map((el, i) => {
                      return (
                        <div
                          key={i}
                          className="col-3 d-flex col  justify-content-center align-items-center"
                        >
                          <img
                            style={{
                              height: "20vh",
                              width: "30vh",
                              border: "1px solid",
                            }}
                            className="avatar avatar-xl avatar-4x3 mb-3"
                            src={el}
                            alt="Image Description"
                            data-hs-theme-appearance="default"
                          />
                          <span
                            className="cross ms-3 mb-2"
                            onClick={() => handleRemoveMedia(el)}
                          >
                            ✖
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <form action="">
                <div className="mb-3">
                  <textarea
                    className="form-control rounded-3"
                    id="exampleFormControlTextarea1 shadow"
                    rows="10"
                    placeholder="Description"
                    onChange={(e) => HandleChange(e)}
                    value={text}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPost;
