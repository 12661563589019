import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ManageTeams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [teams, setTeams] = useState([]);
  const [isDisabled, setDisabled] = useState(false);

  const handleTeams = async () => {
    const res = await getData("get-all-global-teams");

    setTeams(res.data);
  };
  useEffect(() => {
    handleTeams();
  }, []);

  const handleEditTeam = async (id) => {
    const res = await getData(`get-one-global-team?id=${id}`);
    if (res.status) {
      setTimeout(() => {
        navigate("createupdateteam", {
          state: { data: res.data[0], isEdit: true },
        });
      }, 1000);
    }
  };

  const submitTeamData = () => {
    navigate("/team");
  };

  return (
    <div>
      <div className="main mt-8">
        <ToastContainer autoClose={1000} />
        <div className="content container-fluid manage_user manage_event_list manageUpcomingFree">
          <div className="pageheader">
            <div className="row align-items-center mb-3 gap-3 gap-sm-0">
              <div className="col-sm d-flex align-items-center gap-2">
                <h1 className="page-header-title m-0">Team List</h1>
              </div>
              <div className="col-12 col-sm-auto">
                <div className="d-flex gap-3">
                  <NavLink to="createupdateteam" state={{ isEdit: false }}>
                    <button type="button" className="btn btn-dark addEventBtn">
                      Create New Team
                    </button>
                  </NavLink>
                  <button
                    onClick={submitTeamData}
                    disabled={isDisabled}
                    type="button"
                    className="btn btn-dark addEventBtn"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="pageBody mt-4">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="card shadow-none border-0 ">
                  <div className="row mx-0 mb-3 align-items-center">
                    <div className="col ps-0 sectionTitle text-black">
                      Total Teams: {teams.length}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {teams.map((item, i) => {
                    return (
                      <div key={i} className="col-sm-6 mb-3">
                        <div className="card card-hover-shadow h-100">
                          <div className="card-body">
                            <div className="row align-items-center gx-2">
                              <div className="col-12 px-0 mb-3 row mx-0">
                                <div className="col ps-0">
                                  <h2 className="card-title text-inherit">
                                    {item.name}
                                  </h2>
                                </div>
                                <div className="col-auto pe-0">
                                  <span className="cursor-pointer me-1">
                                    <i
                                      onClick={() => handleEditTeam(item._id)}
                                      className="bi bi-pencil-fill fs-3"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                              <div className="col-12 px-0 mb-3">
                                <h6 className="card-subtitle">
                                  Added Participants: {item.invitedParticipants}
                                </h6>
                              </div>
                              <div className="col-12 px-0">
                                <h6 className="card-subtitle">
                                  Event Admin:{" "}
                                  {`${item?.admin?.firstName} ${item?.admin?.lastName}`}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTeams;
