import React, { useEffect, useState, useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";
import DataTable from "react-data-table-component";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
const ManageHealthArticles = () => {
  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [multipleRow, setMultipleRow] = useState();
  const [name, setName] = useState("");
  const [editId, seteditid] = useState("");
  const [filterHealth, setFilterHealth] = useState("");
  const [filterName, setFilterName] = useState([]);
  const [search, setsearch] = useState("");
  const [health, sethealth] = useState("");
  const [row, setRow] = useState([]);
  const [toggleRow, setToggleRow] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleHealthdata = async () => {
    let res = await getData("health-categories");
    setData(res.data);
  };

  const handleManageHealthArticle = async () => {
    setLoader(true);
    const res = await getData("health-articles");
    setMainData(res.data);
    setFilterName(res.data);
    setLoader(false);
  };
  useEffect(() => {
    handleManageHealthArticle();
    handleHealthdata();
  }, []);

  const healthFilter = (name) => {
    sethealth(name);
    let arr = [];
    filterName?.filter((item) => {
      item.categories.map((el, i) => {
        if (el.name.includes(name)) {
          arr.push(item);
        }
      });
    });
    setFilterName(arr);
  };

  useEffect(() => {
    if (search) {
      const filterData = mainData?.filter((item) => {
        if (item.name.toLowerCase().includes(search.trim().toLowerCase())) {
          return item;
        }
      });
      setFilterName(filterData);
    }
  }, [search]);

  const searchFilter = (e) => {
    e.preventDefault();
    setsearch(e.target.value);
  };

  const deleteCategoriesHandler = async (id) => {
    setToggleRow(!toggleRow);
    const body = { id: [id] };
    const res = await putData("delete-health-articles", body);
    if (res.status) {
      handleManageHealthArticle();
      return toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      handleManageHealthArticle();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEdit = (data) => {
    seteditid(data?._id);
    setName(data?.name);
  };
  const editAddCategories = async () => {
    const data = {
      name: name,
    };
    const res = await postData(`edit-health-article/${editId}`, data);
    if (res.status) {
      handleManageHealthArticle();
      return toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      handleManageHealthArticle();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "SR.NO",
      cell: (row, index) => index + 1,
      center: true,
    },
    {
      name: "Health Categories",
      cell: (row) =>
        row?.categories?.length == 0
          ? "No data found"
          : row.categories?.map((el) =>
              row.categories.length == 1 ? el.name : el.name + " , "
            ),
      sortable: true,
      // center: true,
    },
    {
      name: "Article Name",
      selector: (row) =>
        row.name ? row.name : <span className="text-danger">No data !</span>,
      sortable: true,
      // center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <div
            className="btn btn-white btn-sm border-0"
            onClick={() => handleEdit(row)}
            data-bs-toggle="modal"
            data-bs-target="#addEditCealthCategories"
          >
            <i className="bi-pencil-fill me-1"></i>
          </div>
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="viewarticledetails"
            state={{ id: row }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>

          <NavLink
            className="btn btn-white btn-sm border-0"
            to=""
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => {
              setRow(row._id);
            }}
          >
            <i
              className="bi bi-trash-fill"
              onClick={() => {
                setRow(row._id);
              }}
            ></i>
          </NavLink>
        </div>
      ),
      center: true,
    },
  ];

  const handleSelectedRowsChange = useCallback(
    (row) => {
      const selected = row.selectedRows.map((x) => x._id);
      setMultipleRow(selected);
    },
    [multipleRow]
  );
  const handleMultipleDelete = async () => {
    let body = {
      id: multipleRow,
    };
    const res = await putData("delete-health-articles", body);
    if (res.status == true && multipleRow.length > 0) {
      handleManageHealthArticle();
      return toast.success(
        `${multipleRow.length} health category deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      handleManageHealthArticle();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`No health category select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid manageHealthArticles">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0 pageTitle">
                  Manage Health Articles
                </h1>
              </div>
              <div className="col-auto">
                <NavLink to="addedithealtharticle">
                  <button className="btn btn-dark pageBtn" type="button">
                    Add Health Articles
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header card-header-content-md-between">
              <div className="col-auto mb-2 mb-md-0 w-25 p-1">
                <form>
                  <div className="input-group input-group-merge input-group-flush">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search articles by name"
                      value={search}
                      onChange={(e) => searchFilter(e)}
                      onKeyPress={(event) => {
                        if (event.charCode == 13) {
                          searchFilter(event);
                        }
                      }}
                    />
                    {search == " " || search == "" ? null : (
                      <i
                        onClick={() => {
                          setsearch(" ");
                        }}
                        className="bi bi-x fs-2"
                      ></i>
                    )}
                  </div>
                </form>
              </div>
              {multipleRow?.length > 0 ? (
                <div className="">
                  <button
                    type="button"
                    className="btn btn-white btn-sm border"
                    data-bs-toggle="modal"
                    data-bs-target="#multypleEventDelete"
                  >
                    <i className="bi bi-trash-fill fs-1"></i>
                  </button>
                </div>
              ) : null}
              <div className="card-footer border-0 px-md-3 px-0 w-100">
                <div className="col-auto px-0">
                  <div className="d-sm-flex justify-content-center justify-content-sm-end align-items-center">
                    <span className="me-sm-2">Filter by Category</span>
                    <div className="tom-select-custom my-3">
                      <select
                        onChange={(e) => setFilterHealth(e.target.value)}
                        className="js-select form-select w-sm-auto selectForm"
                        autoComplete="off"
                        value={filterHealth}
                      >
                        <option value="" disabled>
                          Health Categories Name
                        </option>
                        {data?.map((item) => {
                          return (
                            <option
                              value={item.name}
                              key={item._id}
                              id={item._id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="dgrid d-sm-flex gap-2 justify-content-md-start justify-content-sm-end justify-content-center ms-sm-2">
                      <button
                        onClick={() => healthFilter(filterHealth)}
                        className="btn btn-dark pageBtn"
                        type="button"
                      >
                        {" "}
                        Apply
                      </button>
                      <button
                        className="btn btn-dark pageBtn"
                        onClick={() => {
                          if (search.includes(" ")) {
                            setsearch(search.trim());
                          } else {
                            setsearch(search + " ");
                          }
                          setFilterHealth("");
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader ? (
              <div style={{ marginLeft: "400px" }}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginTop: "100px" }}
                  wrapperclassName="blocks-wrapper"
                  colors={[
                    "#b8c480",
                    "#B2A3B5",
                    "#F4442E",
                    "#51E5FF",
                    "#429EA6",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive datatable-custom">
                <DataTable
                  // title="Total"
                  pagination
                  columns={columns}
                  data={filterName}
                  selectableRows
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggleRow}
                />
              </div>
            )}
          </div>

          <ToastContainer autoClose={1000} />
          <div
            className="modal fade "
            id="addEditCealthCategories"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header p-3 p-sm-5">
                  <h5
                    className="modal-title fs-4 fs-sm-1"
                    id="editUserModalLabel"
                  >
                    Edit Health Article
                  </h5>
                </div>
                <div className="modal-body ps-3 ps-sm-5 pb-3 pb-sm-5 pe-3 pe-sm-5 pt-0">
                  <form>
                    <div className="row mb-4 flex-column">
                      <label className="col-sm-12 col-form-label form-label">
                        Health Name
                      </label>
                      <div className="col-sm-12">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="js-toggle-password form-control"
                            name="currentPassword"
                            placeholder="Enter Health Category Name"
                            aria-label="Enter current password"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="d-flex gap-3">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setName("");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="button"
                          className="btn btn-dark"
                          onClick={() => editAddCategories()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Delete */}
          <div
            className="modal fade"
            id="askdeletecategory"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-danger"
                    id="exampleModalLongTitle"
                  >
                    Alert !
                  </h5>
                </div>
                <div className="modal-body">
                  Do you want to delete the article
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => deleteCategoriesHandler(row)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="multypleEventDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>

            <div className="modal-body">
              {multipleRow?.length < 1
                ? "Please select the row first !"
                : `Do you want to delete ${multipleRow?.length} article`}
            </div>

            <div className="modal-footer">
              {multipleRow?.length > 0 ? (
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleMultipleDelete()}
                >
                  Delete
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setMultipleRow([]);
                  setToggleRow(!toggleRow);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageHealthArticles;
