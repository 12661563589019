import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";

// class MapDirectionsRenderer extends React.Component {
//   state = {
//     directions: null,
//     error: null,
//   };

//   componentDidMount() {
//     const { place, travelMode } = this.props;

//     const waypoints = place?.map((p) => ({
//       location: { lat: p?.latitude, lng: p?.longitude },
//       stopover: true,
//     }));
//     const origin = waypoints?.shift().location;
//     const destination = waypoints?.pop().location;

//     const directionsService = new google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin: origin,
//         destination: destination,
//         travelMode: travelMode,
//         waypoints: waypoints,
//       },
//       (result, status) => {
//         if (status === google.maps.DirectionsStatus.OK) {
//           this.setState({
//             directions: result,
//           });
//         } else {
//           this.setState({ error: result });
//         }
//       }
//     );
//   }

//   render() {
//     if (this.state.error) {
//       return <h1>{this.state.error}</h1>;
//     }
//     return (
//       this.state.directions && (
//         <DirectionsRenderer directions={this.state.directions} />
//       )
//     );
//   }
// }
const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        style={{ height: "100vh", width: "100%" }}
        defaultCenter={props?.center}
        defaultZoom={props?.zoom}
        onGoogleApiLoaded={({ map, maps }) => this.renderPolylines(map, maps)}
      >
        {props?.markers?.map((marker, index) => {
          console.log(marker);
          const position = { lat: marker?.latitude, lng: marker?.longitude };

          return <Marker key={index} position={position} />;
        })}

        {/* <MapDirectionsRenderer
       places={props?.markers}/> */}
      </GoogleMap>
    );
  })
);

export default Map;
