import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CreateUpdateTeam = () => {
  const location = useLocation();

  let edit = location.state.isEdit;
  const navigate = useNavigate();
  const [add, setAdd] = useState({
    name: "",
  });
  const [teams, setTeams] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [inviteM, setInviteM] = useState([]);
  const [adminId, setAdminId] = useState();
  const [inviteid, setinviteID] = useState([]);
  const [search, setSearch] = useState("");
  const [inviteS, setinviteS] = useState("");
  const [checkedInvitedM, setCheckedInvitedM] = useState([]);

  useEffect(() => {
    if (edit == true) {
      setAdd({
        name: location.state.data.name,
      });
      setinviteID(location.state.data.invitedUsers);
      setCheckedInvitedM(location.state.data?.invitedUsers);
      setAdminId(location.state.data?.admin._id);
      setSearch(
        `${location.state.data?.admin?.firstName} ${location.state.data?.admin?.lastName}`
      );
    }
  }, [edit]);

  const handleTeams = async () => {
    const res = await getData("get-all-global-teams");

    setTeams(res.data);
  };
  useEffect(() => {
    handleTeams();
  }, []);
  const handleAddTeam = (e) => {
    const { name, value } = e.target;
    setAdd({ ...add, [name]: value });
  };
  const handleuserEvent = async (e) => {
    setSearch(e.target.value);
    // setinviteS(e.target.value)
  };
  const handleuserInvite = async (e) => {
    // setSearch(e.target.value)
    setinviteS(e.target.value);
  };

  const adminData = async () => {
    const res = await getData(`search?search=${search}&offset=0&limit=10`);

    setAdmin(res.data);
  };
  const adminInvite = async () => {
    const res = await getData(`search?search=${inviteS}&offset=0&limit=10`);

    setInviteM(res.data);
  };

  useEffect(() => {
    adminData();
  }, [search]);

  useEffect(() => {
    adminInvite();
  }, [inviteS]);

  const handleEventId = (e) => {
    const n = `${e?.firstName} ${e?.lastName}`;
    setSearch(n);
    // setinviteS(n)
  };
  const handleEventInviteId = (e) => {
    const n = `${e?.firstName} ${e?.lastName}`;
    // // setSearch(n)
    setinviteS(n);
  };

  const handleSelectId = (e) => {
    setAdminId(e.target.value);
  };
  const handleSelectInviteID = (id) => {
    let a = inviteM.filter((item) => {
      return item._id == id;
    });
    setinviteID([...inviteid, a[0]]);
  };

  const handleSubmit = async () => {
    let Admin = adminId;
    let inviteUsers = checkedInvitedM.map((el) => el._id);
    let name = add.name;
    // if (name.length && inviteUsers.length && Admin.length > 0) {
    //   setTimeout(() => {
    //     if (edit == true) {
    //       navigate("/team/manageteams", {
    //         state: {
    //           name,
    //           Admin,
    //           inviteUsers,
    //           id: location.state.update._id,
    //           isEdit: location.state.isEdit,
    //         },
    //       });
    //     } else {
    //       navigate("/team/manageteams", {
    //         state: { name, Admin, inviteUsers, isEdit: false },
    //       });
    //     }
    //   }, 1000);
    //   // toast.success('Successfully Added', {
    //   //     position: toast.POSITION.TOP_RIGHT,
    //   // });
    // } else {
    //   toast.error("Fields Not To Be Empty", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    let data = {
      name: name,
      admin: Admin,
      invitedUsers: inviteUsers,
    };

    if (edit == false) {
      const res = await postData("create-global-team", data);
      if (res.status == true) {
        setTimeout(() => {
          navigate("/team");
        }, 2000);
        toast.success(" Added successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Create Team First", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await putData(
        `edit-global-team/${location?.state?.data?._id}`,
        data
      );
      if (res.status == true) {
        setTimeout(() => {
          navigate("/team");
        }, 2000);
        toast.success(" Edited successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Create Team First", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleCheckedInite = (id, e) => {
    if (e.target.checked) {
      let a = inviteid.filter((el) => {
        return el._id == id;
      });
      setCheckedInvitedM([...checkedInvitedM, a[0]]);
    } else {
      let b = checkedInvitedM.filter((el) => {
        return el._id !== id;
      });
      setCheckedInvitedM(b);
    }
    // setInviteM(...inviteM,id)
  };

  return (
    <>
      <div className="main mt-8">
        <ToastContainer autoClose={1000} />
        <div className="content container-fluid addEditHealthArticles manage_user manage_event_list manageUpcomingFree ">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">
                  Create / Edit Team Details
                </h1>
              </div>
              <div className="col-sm-auto mt-sm-0 mt-3">
                <button
                  className="btn btn-dark addEventBtn"
                  onClick={handleSubmit}
                >
                  Send Invite
                </button>
              </div>
            </div>
            <div className="row mx-0 mb-sm-4 mb-3">
              <div className="col-md-6 col-sm-8 px-0">
                <label htmlFor="eventitle" className="form-label fw-bold fs-4">
                  Team Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Enter Event Title"
                  aria-label=""
                  value={add.name}
                  onChange={handleAddTeam}
                />
              </div>
            </div>
            <div className="row mx0 mb-3">
              <div className="col-md-6 col-sm-8 px-0">
                <label htmlFor="" className="form-label fw-bold fs-4">
                  Invite Members
                </label>

                <div className="input-group">
                  <span
                    className="input-group-text border-end-0 rounded-0"
                    id="basic-addon1"
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 shadow-none rounded-0"
                    placeholder="Search by registered username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={inviteS}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => handleuserInvite(e)}
                    onKeyPress={(event) => {
                      if (event.charCode == 13) {
                        handleuserInvite(event);
                      }
                    }}
                  />
                </div>
                <div className="searchOption">
                  <select
                    className="form-select rounded-0"
                    multiple
                    aria-label="multiple select example"
                    onChange={(e) => handleSelectInviteID(e.target.value)}
                  >
                    {/* <option selected>List of Selected Participants</option> */}
                    {inviteM?.map((el) => {
                      return (
                        <option
                          value={el._id}
                          key={el._id}
                          // id={el._id}
                          onClick={() => handleEventInviteId(el)}
                        >
                          {`  ${el?.firstName} ${el?.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-6 col-sm-8 px-0">
                <label htmlFor="" className="form-label fw-bold fs-4">
                  Assign Event Admin
                </label>

                <div className="input-group">
                  <span
                    className="input-group-text border-end-0 rounded-0"
                    id="basic-addon1"
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 shadow-none rounded-0"
                    placeholder="Search by registered username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={search}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => handleuserEvent(e)}
                    onKeyPress={(event) => {
                      if (event.charCode == 13) {
                        handleuserEvent(event);
                      }
                    }}
                  />
                </div>
                <div className="searchOption">
                  <select
                    className="form-select rounded-0"
                    multiple
                    aria-label="multiple select example"
                    onChange={(e) => handleSelectId(e)}
                  >
                    {admin?.map((el) => {
                      return (
                        <option
                          value={el._id}
                          key={el._id}
                          onClick={() => handleEventId(el)}
                          selected={
                            edit
                              ? location.state.update?.admin?._id == el._id
                              : ""
                          }
                        >
                          {`  ${el?.firstName} ${el?.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 custom_Tables">
              <div className="row ">
                {inviteid.map((el, i) => {
                  return (
                    <div key={i} className="col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="row align-items-center mb-3 border-bottom py-3 mx-0 ">
                            <div className="col-auto">
                              <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                <img
                                  src={
                                    el.image
                                      ? el.image
                                      : "assets/img/event-img-1.png"
                                  }
                                  alt="no img"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50px",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <h6 className="mb-0 fs-5">
                                {`${el.firstName} ${el.lastName}`}
                              </h6>
                            </div>
                            <div className="col-auto ">
                              <div className="form-check ">
                                <input
                                  className="form-check-input  p-2 rounded-pill  border border-primary"
                                  onChange={(e) =>
                                    handleCheckedInite(el._id, e)
                                  }
                                  type="checkbox"
                                  value={el._id}
                                  id={el._id}
                                  checked={
                                    checkedInvitedM.includes(el) ? true : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUpdateTeam;
