import React from "react";
import { useState, useEffect } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData } from "../../FetchService";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddEditClubs({ image, imageSeter, convertImageToUrl }) {
  const seter = imageSeter;
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [eventType, setEventType] = useState("");
  const [discription, setDiscription] = useState("");
  const [activity, setActivity] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [isEdit, setIsEdit] = useState(location.state.edit);
  const [data, setData] = useState({});
  const [img, setImg] = useState("");
  useEffect(() => {
    if (isEdit == true) {
      setData(location?.state?.data);
      let a = location?.state?.data;
      setName(a?.name);
      setImg(a?.image);
      seter(a?.image);

      setDiscription(a?.description);
      setEventType(a?.isPrivate ? "Private" : "Public");
      setActivity(location?.state?.data?.activities.map((x) => String(x)));
    }
  }, [isEdit]);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEvent = (e) => {
    setEventType(e.target.value);
  };

  const handleActivity = (a) => {
    if (activity.includes(a)) {
      let fill = activity.filter((el) => el !== a);
      setActivity(fill);
    } else {
      setActivity((prev) => [...prev, a]);
    }
  };

  const getActivities = async () => {
    const res = await getData("activity-templates");
    setActivityList(res.data);
  };
  useEffect(() => {
    getActivities();
  }, []);
  // Activities Api

  const createClub = async () => {
    const dataa = {
      name: name,
      description: discription,
      image: image,
      activities: activity.map((el) => Number(el)),
      isPrivate: eventType === "Private" ? true : false,
    };
    if (isEdit) {
      const res = await postData(`edit-club/${data._id}`, dataa);

      if (res.status == true) {
        seter("");
        setTimeout(() => {
          navigate("/invitemember", {
            state: { id: location?.state?.data?._id },
          });
          // navigate("/manageclubs");
        }, 2000);
        return toast.success(`${res.message} Successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.message} Successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await postData("create-club", dataa);
      if (res.status == true) {
        seter("");
        setTimeout(() => {
          navigate("/invitemember", {
            state: { id: res?.data?._id },
          });
        }, 2000);

        return toast.success(`${res.message} successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Not edited !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleClubImage = (e) => {
    convertImageToUrl(e);
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div>
      <div className="main mt-8">
        <div className="content container-fluid addEditHealthArticles manage_user manage_event_list">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">
                  {isEdit ? "Edit" : "Add"} Club
                </h1>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-12">
              <div className="row g-2 mb-sm-4 mb-2">
                <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                  <div className="card">
                    <div className="card-body p-0">
                      <label
                        htmlFor="attachFilesNewProjectLabel"
                        className="js-dropzone dz-dropzone dz-dropzone-card"
                      >
                        <div className="dz-message">
                          <img
                            className="avatar avatar-xl avatar-4x3 mb-3"
                            src={img}
                            alt="Image Description"
                            data-hs-theme-appearance="default"
                          />
                          <img
                            className="avatar avatar-xl avatar-4x3 mb-3 d-none"
                            src={isEdit ? data?.image : img}
                            alt="Image Description"
                            data-hs-theme-appearance="dark"
                          />
                          <h5>Drag and drop your file here</h5>
                          <p className="mb-2">or</p>
                          <span className="btn btn-white btn-sm">
                            Browse files
                          </span>
                        </div>
                      </label>
                      <input
                        type="file"
                        className="d-none"
                        name=""
                        id="attachFilesNewProjectLabel"
                        accept="compress/*"
                        onChange={(e) => handleClubImage(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-dark mt-2">
                    {isEdit ? "Change Clubs Image" : "Add Clubs Image"}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row pb-sm-5 mb-sm-5 mb-4">
                <div className="col-12 mb-3">
                  <div className="col-12 col-sm-8 px-0">
                    <label htmlFor="eventitle1" className="form-label">
                      Club name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Club name"
                      aria-label=""
                      onChange={(e) => handleName(e)}
                      value={name}
                    />
                  </div>
                </div>
                <div className="col-12 row px2 mx-0 mb-3">
                  <div className="col-12 px-0 mb-1">
                    <label htmlFor="" className="form-check-label">
                      Club Type
                    </label>
                  </div>
                  <div className="form-check col-auto">
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      PUBLIC
                    </label>
                    <input
                      className="form-check-input  rounded-1"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="Public"
                      checked={eventType == "Public"}
                      onChange={(e) => handleEvent(e)}
                    />
                  </div>
                  <div className="form-check col-auto">
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      PRIVATE
                    </label>
                    <input
                      className="form-check-input rounded-1"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="Private"
                      checked={eventType == "Private"}
                      onChange={(e) => handleEvent(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <label htmlFor="eventitle4" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    placeholder=""
                    id="eventitle4"
                    rows="10"
                    value={discription}
                    onChange={(e) => setDiscription(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="row mx-0 pb-sm-5 mb-sm-5 mb-4 g-0">
                <div className="col-12 fs-3 fw-bold text-black mb-3">
                  <div>Qualifying Activities</div>
                </div>
                <div className="col-12 addEditOuter">
                  <div className="row g-2">
                    {activityList?.map((item, i) => {
                      return (
                        <div key={i} className="col-sm-auto col-6">
                          <input
                            type="checkbox"
                            className="d-none"
                            id={`${item._id}btn-check-outlined2`}
                            autoComplete="off"
                            value={item.ref}
                            checked={
                              // isEdit
                              //   ? data.activities.includes(item.ref)
                              activity?.includes(item.ref)
                            }
                            onChange={(e) => handleActivity(e.target.value)}
                          />
                          <label
                            // "btn btn-light w-100"
                            className={
                              activity?.includes(item?.ref.toString())
                                ? "btn btn-dark w-100"
                                : "btn btn-light w-100"
                            }
                            htmlFor={`${item._id}btn-check-outlined2`}
                          >
                            {item?.activity}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-2">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-dark btn-lg"
                      onClick={() => createClub()}
                    >
                      {isEdit ? "Edit" : "Add"} Club
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-dark btn-lg"
                      onClick={() => navigate("/manageclubs")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
