import React from "react";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";

const ViewArticleDetails = () => {
  const location = useLocation();
  const item = location.state.id;
  return (
    <div className="main mt-8">
      <div className="content container-fluid viewArticleDetails">
        <div className="page-header border-0 pb-0">
          <div className="row g-2 align-items-center mb-3">
            <div className="col">
              <h1 className="page-header-title mb-1">{item?.name}</h1>
              <p className="page-header-text">
                <Moment format="DD-MMM-YY , HH:MM A">
                  {new Date(item?.createdAt).getTime()}
                </Moment>
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-1 ">
            <img
              style={{ maxHeight: "400px" }}
              className="w-100 bannerImage"
              src={item.image ? item.image : "../assets/img/page-image.png"}
              alt="page-image"
            />
          </div>
          <div className="col-12">
            <p>{item.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewArticleDetails;
