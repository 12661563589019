import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, postData, putData } from "../../FetchService";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import SimpleImageSlider from "react-simple-image-slider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useResizeObserver from "use-resize-observer";
const PostDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sideOptions = { startIndex: 3 };
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const [postDetails, setPostDetails] = useState([]);
  const [slideIndexText, setSlideIndexText] = useState("");
  const [open, setOpen] = useState(false);
  const [clubId, setClubId] = useState("");
  const getPostDetails = async () => {
    const res = await getData(`post?id=${location.state.id}`);
    setPostDetails(res.data);
    setClubId(res?.data[0]?.clubId);
  };
  useEffect(() => {
    getPostDetails();
  }, [location.state.id]);
  const onStartSlide = useCallback((idx, length) => {
    if (idx && length) {
      setSlideIndexText(`${idx} / ${length}`);
    }
  }, []);
  function getTimeRemaining(startDate, expireDate) {
    let total;
    if (Date.parse(startDate) > Date.parse(expireDate)) {
      total = Date.parse(startDate) - Date.parse(expireDate);
    } else {
      total = Date.parse(expireDate) - Date.parse(startDate);
    }
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);

    let minute, hour, day, month;
    if (months > 0) {
      month = months;
    } else if (days > 0) {
      day = days;
    } else if (hours > 0) {
      hour = hours;
    } else if (minutes > 0) {
      minute = months;
    }
    return {
      minute,
      hour,
      day,
      month,
    };
  }

  const handlePostDelete = async (idd) => {
    const data = {
      id: idd,
    };
    const res = await putData("delete-post", data);
    if (true) {
      setTimeout(() => {
        navigate("/manageclubs/leaderboardviewclubdetails", {
          state: { id: clubId, delete: true },
        });
      }, 1000);
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpen(false);
    } else {
      setOpen(false);
      toast.error(`${res.message}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid viewArticleDetails">
          <div className="page-header border-0 pb-0">
            <div className="row g-2 align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">Post Details</h1>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-8">
              <div className="d-grid gap-3 gap-md-5">
                <div className="card">
                  <div className="card-body viewReprtedActivityDetailsOuter px-2 px-sm-3 text-black border-botom-1">
                    <div className="col-12 bg-white card_box_sh">
                      <div className="row mx-0 card_box_profile">
                        <div className="col-12">
                          {postDetails.length ? (
                            postDetails.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="row align-items-center justify-content-between"
                                >
                                  <div className="col-auto px-0">
                                    <div className="d-flex align-items-center user_name_image">
                                      <div className="customImageBorder">
                                        <div className="custom_user_image">
                                          <img
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "50%",
                                            }}
                                            src={item?.user?.image}
                                            className="user_image"
                                            alt="Profile_pic"
                                          />
                                        </div>
                                      </div>
                                      <div className="profile_user_text text-black">
                                        <div className="user_name mb-sm-2 ms-2">
                                          {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                        </div>
                                        <div className="time_and_Date ms-2">
                                          {/* 22 Jan 20 11:00 AM */}
                                          <Moment format="DD-MMM-YY hh:mm A">
                                            {item?.createdAt}
                                          </Moment>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col text-end">
                                    <div className="dropdown">
                                      <button
                                        type="button"
                                        className="btn btn-white btn-sm"
                                        id="actionsDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </button>

                                      <div
                                        className="dropdown-menu mt-1"
                                        aria-labelledby="actionsDropdown"
                                      >
                                        <div
                                          className="dropdown-item btn"
                                          data-bs-target="#askdeletecategory"
                                          onClick={() =>
                                            navigate(
                                              "/manageclubs/leaderboardviewclubdetails/editpost",
                                              {
                                                state: {
                                                  edit: true,
                                                  data: item,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <i className="bi bi-pencil-fill me-2"></i>
                                          Edit
                                        </div>
                                        <div
                                          className="dropdown-item btn"
                                          data-bs-target="#askdeletecategory"
                                          onClick={() => setOpen(true)}
                                        >
                                          <i className="bi bi-trash3-fill me-2"></i>
                                          Delate
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 text_Message mb-2 px-0">
                                    <div className="summery_text text-black">
                                      {item?.description}
                                    </div>
                                  </div>
                                  <div className="col-12 map mb-2 px-0">
                                    <div
                                      className="maps_card"
                                      ref={ref}
                                      style={{
                                        minWidth: "400px",
                                        height: "400px",
                                      }}
                                    >
                                      {item?.images?.length > 1 ? (
                                        <SimpleImageSlider
                                          width={width}
                                          height={height}
                                          images={item?.images}
                                          showBullets={true}
                                          showNavs={true}
                                          autoPlay={true}
                                          bgColor="#000"
                                          navStyle={2}
                                          onStartSlide={onStartSlide}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            margin: "auto",
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: "600px",
                                              height: "400px",
                                            }}
                                            src={item?.images[0]}
                                            alt="Post"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 map mb-2 px-0 d-flex align-items-center justify-content-center">
                                    {item?.images?.length > 1 ? (
                                      <span>
                                        {slideIndexText ||
                                          `${sideOptions?.startIndex} / ${item?.images?.length}`}
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="col-12 mb-md-2 like_com_shr px-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="d-flex col align-items-center gap-md-3 gap-1 gap-sm-2 text-black">
                                        <span className="widText">
                                          <div className="wid col">
                                            <i className="bi bi-hand-thumbs-up"></i>
                                            {item?.likes} likes
                                          </div>
                                          <div> </div>
                                        </span>
                                        <span className="widText">
                                          <div className="wid">
                                            <i className="bi bi-chat-left-text"></i>{" "}
                                            {item?.comments} Comments
                                          </div>
                                        </span>
                                        <span className="widText">
                                          <div className="wid">
                                            <i className="bi bi-share-fill"></i>{" "}
                                            Share
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 comment_all px-0">
                                    <div className="view_all_comments">
                                      <div className="text-decoration-none mt-3">
                                        <button className="view_com px-0 border-0 bg-white">
                                          Comments
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-2 mb-sm-2 mb-3 align-items-center"></div>
                                    {!!item?.allcomments
                                      ? item?.allcomments?.map((el, i) => {
                                          let userName = `${el?.user?.firstName} ${el?.user?.lastName}`;
                                          return (
                                            <div
                                              key={i}
                                              className="d-flex align-items-center justify-content-around col  mb-sm-2 mb-3 show_comment  border-bottom p-1"
                                            >
                                              <div className="d-flex w-25 align-items-center justify-content-center ms-2">
                                                <div>
                                                  <img
                                                    style={{
                                                      height: "30px",
                                                      width: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    className="profile-member"
                                                    src={el?.user?.image}
                                                  />
                                                </div>
                                                <div className="col-3  w-100 comment_user_nam ms-2">
                                                  <span> {userName}</span>
                                                </div>
                                              </div>
                                              <div className=" ms-4 w-50 comment_text text-start">
                                                {el?.comment}
                                              </div>
                                              <div className="col-auto w-25 comment-time ms-5">
                                                {getTimeRemaining(
                                                  new Date().toISOString(),
                                                  el?.createdAt
                                                ).month
                                                  ? getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).month + " month"
                                                  : getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).day
                                                  ? getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).day + " day"
                                                  : getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).hour
                                                  ? getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).hour + " hr"
                                                  : getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).minute
                                                  ? getTimeRemaining(
                                                      new Date().toISOString(),
                                                      el?.createdAt
                                                    ).minute + " min"
                                                  : ""}
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                  {/*<-- modal ---> */}
                                  <div
                                    className={`modal fade ${
                                      open ? "show" : ""
                                    }`}
                                    id="multypleEventDelete"
                                    tabIndex="-1"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden={open ? "true" : "false"}
                                    style={{ display: open ? "block" : "none" }}
                                    role={open ? "dialog" : ""}
                                  >
                                    <div
                                      className="modal-dialog modal-dialog-centered"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title text-danger"
                                            id="exampleModalLongTitle"
                                          >
                                            Alert !
                                          </h5>
                                        </div>
                                        <div className="modal-body">
                                          Do you want to delete post
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() =>
                                              handlePostDelete(item?._id)
                                            }
                                          >
                                            Delete
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setOpen(false)}
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className=" text-center text-danger">
                              NO data found!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
