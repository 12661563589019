import React, { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import SimpleImageSlider from "react-simple-image-slider";
import { putData } from "../../FetchService";
import { toast, ToastContainer } from "react-toastify";
import useResizeObserver from "use-resize-observer";

export const ReportedPostDetails = () => {
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const location = useLocation();
  const sideOptions = { startIndex: 3 };
  const item = location.state.data;

  const navigate = useNavigate();
  const [slideIndexText, setSlideIndexText] = useState("");

  const onStartSlide = useCallback(
    (idx, length) => {
      setSlideIndexText(`${idx} / ${length}`);
    },
    [item]
  );
  let cDate = new Date();
  let bDate = new Date(item.user?.dob);
  let rDate = new Date(item.details.user?.dob);
  let age_now = cDate.getFullYear() - bDate.getFullYear();
  let age_now1 = cDate.getFullYear() - rDate.getFullYear();

  const getRemainingTime = () => {
    let total;
    item.details?.comments?.map((el) => {
      if (Date.parse(new Date()) > Date.parse(el.createdAt)) {
        total = Date.parse(new Date()) - Date.parse(el.createdAt);
      } else {
        total = Date.parse(el.createdAt) - Date.parse(new Date());
      }
    });

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    let minute, hour, day, month;
    if (months > 0) {
      month = months;
    } else if (months == 0 && days > 0) {
      day = days;
    } else if (months == 0 && days == 0 && hours > 0) {
      hour = hours;
    } else if (months == 0 && days == 0 && hours == 0 && minutes > 0) {
      minute = months;
    }
    return {
      minute,
      hour,
      day,
      month,
    };
  };
  let calculate_Duration = getRemainingTime();

  const handleblockuser = async (item) => {
    let body = {
      id: item,
    };
    const res = await putData("block-user", body);
    if (res.status) {
      setTimeout(() => {
        navigate("/managereports");
      }, 1000);
      return toast.success(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      return toast.error(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleunblockuser = async (item) => {
    let body = {
      id: item,
    };
    const res = await putData("unblock-user", body);
    if (res.status) {
      setTimeout(() => {
        navigate("/managereports");
      }, 1000);
      return toast.success(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      return toast.error(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSingleDelete = async (item) => {
    let body = {
      id: [item],
    };
    const res = await putData("delete-reports", body);
    if (res.status) {
      setTimeout(() => {
        navigate("/managereports");
      }, 1000);
      return toast.success(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      return toast.error(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />

      <div className="main mt-8">
        <div className="content container-fluid">
          <div className="row justify-content-lg-center">
            <div className="pageheader">
              <div className="row align-items-center mb-3">
                <div className="col mb-sm-0 mb-3">
                  <h1 className="page-header-title m-0">
                    View Reported Post Details
                  </h1>
                </div>
                <div className="col-sm-auto align-items-center justify-content-between">
                  {item.details.user.isBlocked ? (
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#blockuserdetails"
                      className="btn btn-primary me-md-2 me-0 px-3 fs-6"
                    >
                      {" "}
                      Unblock User
                    </button>
                  ) : (
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#blockuserdetails"
                      className="btn btn-primary me-md-2 me-0 px-3 fs-6"
                    >
                      Block User
                    </button>
                  )}

                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#askdeletecategory"
                    className="btn btn-primary px-3 fs-6"
                  >
                    Delete Post
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="profile-cover">
                <div className="profile-cover-img-wrapper">
                  <img
                    className="profile-cover-img"
                    src="/assets/img/1920x400/img1.jpg"
                    alt="Image Description"
                  />
                </div>
              </div>
              <div className="text-center mb-5">
                <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
                  <img
                    className="avatar-img"
                    src={item.details.user.image}
                    alt="Image Description"
                  />
                  <span className="avatar-status avatar-status-success"></span>
                </div>
                <h1 className="page-header-title">
                  {item.details.user.firstName} {item.details.user.lastName}
                </h1>
              </div>
              <div className="row gap-3 gap-md-0">
                <div className="col-md-5 ms-auto">
                  <div className="js-sticky-block card  mb-md-5">
                    <div className="card-header px-3 py-4">
                      <h4 className="card-header-title">
                        Reporter User Details
                      </h4>
                    </div>
                    <div className="card-body row">
                      <div className=" col-md-6 avatar avatar-xl avatar-circle ">
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50px",
                          }}
                          // className="avatar-img"
                          src={item.user.image}
                          alt="Image Description"
                        />
                      </div>
                      <ul className="list-unstyled list-py-2 text-dark mb-0 col-md-6">
                        <li className="pb-0">
                          <span className="card-subtitle">About</span>
                        </li>
                        <li>
                          <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                          Name : {item.user?.firstName} {item.user?.lastName}
                        </li>
                        <li>
                          <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                          Gender : {item.user?.gender}
                        </li>
                        <li>
                          <i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>
                          Age : {age_now ? age_now - 1 : "0"}
                        </li>
                        <li className=" pb-0">
                          <span className="card-subtitle">Contacts</span>
                        </li>
                        <li>
                          <i className="bi-at dropdown-item-icon"></i>
                          {item.user?.email}
                        </li>
                        <li>
                          <i className="bi-phone dropdown-item-icon"></i>
                          {item.user?.mobile}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 me-auto">
                  <div className="js-sticky-block card  mb-md-5">
                    <div className="card-header px-3 py-4">
                      <h4 className="card-header-title">
                        Reported User Details
                      </h4>
                    </div>
                    <div className="card-body row">
                      <div className=" col-md-6 avatar avatar-xl avatar-circle ">
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50px",
                          }}
                          // className="avatar-img"
                          src={item.details?.user?.image}
                          alt="Image Description"
                        />
                      </div>
                      <ul className="list-unstyled list-py-2 text-dark mb-0 col-md-6">
                        <li className="pb-0">
                          <span className="card-subtitle">About</span>
                        </li>
                        <li>
                          <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                          Name : {item.details.user?.firstName}{" "}
                          {item.details.user?.lastName}
                        </li>
                        <li>
                          <i className="bi bi-gender-ambiguous dropdown-item-icon"></i>
                          Gender : {item.details.user?.gender}
                        </li>
                        <li>
                          <i className="bi bi-calendar2-date-fill dropdown-item-icon"></i>
                          Age : {age_now1 ? age_now1 - 1 : "0"}
                        </li>
                        <li className=" pb-0">
                          <span className="card-subtitle">Contacts</span>
                        </li>
                        <li>
                          <i className="bi-at dropdown-item-icon"></i>
                          {item.details.user?.email}
                        </li>
                        <li>
                          <i className="bi-phone dropdown-item-icon"></i>
                          {item.details.user?.mobile}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gap-3 gap-md-0">
                <div className="col-md-10 m-auto">
                  <div className="card">
                    <div className="card-header card-header-content-between px-3 py-4">
                      <h4 className="card-header-title">Reported Reason</h4>
                    </div>
                    <div
                      className="card-body card-body-height text-black"
                      style={{ height: "10rem" }}
                    >
                      <p>{item.message}</p>
                    </div>
                    <div className="card-footer">
                      <div className="link link-collapse pb-1">
                        <span className="link-collapse-default">
                          Reported Date and Time:{" "}
                          {item.createdAt ? (
                            <Moment format="DD-MMM-YY, hh:mm A">
                              {item.createdAt}
                            </Moment>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gap-3 gap-md-0 mt-4">
                <div className="col-md-8 me-auto">
                  <div className="d-grid gap-3 gap-md-5">
                    <div className="card">
                      <div className="card-header card-header-content-between px-3 py-4">
                        <h4 className="card-header-title">
                          Reported Post Details
                        </h4>
                      </div>
                      <div className="card-body viewReprtedActivityDetailsOuter px-2 px-sm-3 text-black">
                        <div className="col-12 bg-white card_box_sh">
                          <div className="row mx-0 card_box_profile">
                            <div className="col-12">
                              <div className="row align-items-center justify-content-between">
                                <div className="col-auto px-0">
                                  <div
                                    className="col-12 pb-3 d-flex align-items-center"
                                    key={Math.random()}
                                  >
                                    <div className="proImage me-2 d-flex align-items-center justify-content-center rounded-circle border avatar avatar-md avatar-circle">
                                      <img
                                        className="avatar-img"
                                        src={item.details.user.image}
                                        alt="Image Description"
                                      />
                                    </div>
                                    <span className="proText">
                                      {item.details.user.firstName}{" "}
                                      {item.details.user.lastName}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href="clubs-activity-post-details.html"
                                  className="text-decoration-none text-black"
                                ></a>
                                <div className="col-12 text_Message mb-2 px-0">
                                  <div className="summery_text text-black">
                                    {item.details.text}
                                  </div>
                                </div>
                                <div className="col-12 map mb-2 px-0">
                                  <div
                                    ref={ref}
                                    className="maps_card"
                                    style={{
                                      minWidth: "400px",
                                      height: "400px",
                                    }}
                                  >
                                    {item.details.images.length < 2 ? (
                                      <img
                                        style={{
                                          minWidth: "400px",
                                          width: "400px",
                                        }}
                                        className="w-100"
                                        src={item.details?.images}
                                        alt="map"
                                      />
                                    ) : (
                                      <SimpleImageSlider
                                        width={width}
                                        height={height}
                                        images={item.details?.images}
                                        showBullets={true}
                                        showNavs={true}
                                        autoPlay={true}
                                        bgColor="#000"
                                        navStyle={2}
                                        onStartSlide={onStartSlide}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 map mb-2 px-0 d-flex align-items-center justify-content-center">
                                  {item.details?.images?.length > 1 ? (
                                    <span>
                                      {slideIndexText ||
                                        `${sideOptions.startIndex} / ${item.details?.images?.length}`}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-12 mb-md-2 like_com_shr px-0 mt-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center   gap-md-3 gap-1 gap-sm-2 text-black">
                                      <span className="widText">
                                        <a className="wid">
                                          <i className="bi bi-hand-thumbs-up"></i>
                                        </a>{" "}
                                        {item.details.likes.length} Likes
                                      </span>
                                      <span className="widText">
                                        <a className="wid">
                                          <i className="bi bi-chat-left-text"></i>
                                        </a>{" "}
                                        {item.details.comments.length} Comments
                                      </span>
                                      <span className="widText">
                                        <a className="wid">
                                          <i className="bi bi-graph-up-arrow"></i>
                                        </a>{" "}
                                        View analysis
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 comment_all px-0">
                                  <div className="view_all_comments">
                                    <a
                                      href=""
                                      className="text-decoration-none "
                                    >
                                      <button className="view_com px-0 border-0 bg-white">
                                        Comments
                                      </button>
                                    </a>
                                  </div>
                                  {item.details.comments.length > 0 ? (
                                    item.details?.comments.map((el) => {
                                      return (
                                        <div
                                          className="row align-items-top show_comment mb-md-1 mb-3"
                                          key={Math.random()}
                                        >
                                          <div className="col-md-3 comment_user_nam">
                                            {el.user?.firstName}{" "}
                                            {el.user?.lastName}
                                          </div>
                                          <div className="col p-md-0 comment_text">
                                            {el.comment}
                                          </div>
                                          <div className="col-auto comment-time">
                                            {" "}
                                            {calculate_Duration.month > 0
                                              ? `${calculate_Duration.month} Months `
                                              : ""}
                                            {calculate_Duration.day > 0
                                              ? `${calculate_Duration.day} Days`
                                              : ""}
                                            {calculate_Duration.hour > 0
                                              ? `${calculate_Duration.hour} Hours `
                                              : ""}
                                            {calculate_Duration.minute > 0
                                              ? `${calculate_Duration.minute} Minutes `
                                              : ""}
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <p>No Comments</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 order-last order-md-0 ms-auto">
                  <div className="js-sticky-block card mb-3 mb-lg-5">
                    <div className="card-header px-3 py-4">
                      <h4 className="card-header-title">Likes</h4>
                    </div>
                    <div className="card-body likesOuter px-3 py-4 text-black">
                      <div className="row">
                        {item.details.likes.length > 0 ? (
                          item.details.likes?.map((el) => {
                            return (
                              <div
                                className="col-12 pb-3 d-flex align-items-center"
                                key={Math.random()}
                              >
                                <div className="proImage me-2 d-flex align-items-center justify-content-center rounded-circle border avatar avatar-md avatar-circle">
                                  <img
                                    className="avatar-img"
                                    src={el.image}
                                    alt="Image Description"
                                  />
                                </div>
                                <span className="proText">
                                  {el.firstName} {el.lastName}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          <p>No Likes</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">Do you want to delete the User</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleSingleDelete(item._id)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="blockuserdetails"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {item.details.user.isBlocked ? (
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">Do you want to unblock the User</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleunblockuser(item.details.userId)}
                >
                  unblock
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">Do you want to block the User</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleblockuser(item.details.userId)}
                >
                  Block
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
