import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function CreateEditCorporateTeams() {
  const location = useLocation();

  const navigate = useNavigate();
  let edit = location.state.isEdit;
  const GlobalTeam = location.state.globalTeam;
  const [teams, setTeams] = useState([]);
  const [singleTeam, setSingleTeam] = useState([]);
  const [show, setShow] = useState("");
  const [allAdmin, setAllAdmin] = useState([]);
  const [assignAdminId, setAssignAdminId] = useState("");
  const [userData, setUserData] = useState([]);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [inviteUserId, setInviteUsersId] = useState([]);
  //const [edit, setEdit] = useState(location.state?.edit);
  const [teamDetails, setTeamDetails] = useState([]);
  const [autodata, setAutoData] = useState(false);
  const [preData, setPreData] = useState([]);
  const [inputs, setInputs] = useState({
    assignAdmin: "",
    inviteUser: "",
    teamName: "",
  });
  const [adminData,setAdminData] = useState({
    name:"",
    image:""
  })
  const handleInputFields = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const getAllUsers = async () => {
    if (inputs.assignAdmin) {
      const res = await getData(
        `search?search=${inputs.assignAdmin}&offset=0&limit=10`
      );
      setAllAdmin(res.data);
    }
  };
  const getAllInviteUsers = async () => {
    if (inputs.inviteUser) {
      const res = await getData(
        `search?search=${inputs.inviteUser}&offset=0&limit=10`
      );
      setUserData(res.data);
    }
  };
  useEffect(() => {
    getAllInviteUsers();
  }, [inputs.inviteUser]);
  useEffect(() => {
    getAllUsers();
  }, [inputs.assignAdmin]);

  //  Global Team

  const textChange = (id) => {
    if (show == id) {
      setShow("");
    } else {
      setShow(id);
    }
  };
  const handleTeams = async () => {
    const res = await getData("get-all-global-teams");
    setTeams(res.data);
  };
  useEffect(() => {
    handleTeams();
  }, []);

  const handleOneTeams = async (item) => {
    const res = await getData(`get-one-global-team?id=${item._id}`);
    let a = res.data.map((el) => {
      return el.invitedUsers;
    });
    setSingleTeam(a[0]);
    setAutoData(true);
    setPreData(res.data);
    setInputs({
      assignAdmin: `${res.data[0].admin.firstName} ${res.data[0].admin.lastName}`,
      inviteUser: "",
      teamName: res.data[0].name,
    });
    setAssignAdminId(res.data[0].admin._id);
    setInviteUsers(res.data[0].invitedUsers);
    setInviteUsersId(res.data[0].invitedUsers);
    //setInviteUsers(res.data[0].invitedUsers);
    let id = [];
    //setInviteUsersId({id});
    res.data[0].invitedUsers.map((item) => {
      id.push(item._id);
    });
  };

  const handleEventData = (data) => {
    let n = `${data.firstName} ${data.lastName}`;
    setInputs({ ...inputs, assignAdmin: n });
    setAdminData({
      name:n,
      image:data.image
    })
  };

  const handleUserData = (data) => {
    //setInviteUsers([...inviteUsers, data]);
    let n = `${data.firstName} ${data.lastName}`;

    setInputs({ ...inputs, inviteUser: n });
  };
  const handleInviteUserSelect = (id) => {
    let a = userData.filter((item) => {
      return item._id == id;
    });
    setInviteUsers([...inviteUsers, a[0]]);
  };
  const handleCheckedInite = (id, e) => {
    if (e.target.checked) {
      let a = inviteUsers.filter((el) => {
        return el._id == id;
      });
      setInviteUsersId([...inviteUserId, a[0]]);
    } else {
      let b = inviteUserId.filter((el) => {
        return el._id !== id;
      });
      setInviteUsersId(b);
    }
  };

  const handleAdminSelect = (e) => {
    setAssignAdminId(e.target.value);
  };

  const teamDetailsFunc = async () => {
    const res = await getData(
      `team-details?id=${location.state.edit ? location.state.id : location.state.data?._id
      }`
    );
    console.log("res", { res })
    setTeamDetails(res?.data);
    setInviteUsers(res?.data[0].invitedUsers)
    setInputs({ ...inputs, teamName: res?.data[0].name })
    setAssignAdminId(res?.data[0].admin._id)
    setAdminData({
      name: `${res?.data[0]?.admin?.firstName} ${res?.data[0]?.admin?.lastName}`,
      image:res?.data[0]?.admin?.image
    })



    let data = res.data;
    // setTeamDetails(data);

    // data.map((el) => {
    //   setInviteUsers(el.invitedUsers);

    //   setInputs({
    //     teamName: el.name,
    //     assignAdmin: `${el.admin.firstName} ${el.admin.lastName}`,
    //     eventId: location.state.data._id,
    //   });
    // });
  };
  useEffect(() => {
    teamDetailsFunc();
  }, [location.state.id]);

  const createTeam = async () => {
    const data = {
      name: inputs.teamName,
      eventId: location?.state?.id,
      admin: assignAdminId,
      invitedUsers: inviteUserId.map((el) => el._id),
    };

    const res = await postData("create-team", data);
    if (res.status == true) {
      setTimeout(() => {
        window.history.go(-1);
      }, 2000);

      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const editTeam = async () => {
    const data = {
      name: inputs.teamName,
      id: location?.state?.id,
      admin: assignAdminId,
      invitedUsers: inviteUserId.map((el) => el._id),
    };
    const res = await putData("edit-team", data);
    if (res.status == true) {
      setTimeout(() => {
        window.history.go(-1);
      }, 2000);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }



  console.log("teamDetails", { adminData })




  return (
    <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl   footer-offset">
      <ToastContainer autoClose={1000} />
      <Header />
      <div className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white  ">
        <SideBar />
      </div>
      <div id="content" role="main" className="main">
        <div className="content container-fluid addEditHealthArticles manage_user manage_event_list manageUpcomingFree ">
          {!GlobalTeam || autodata ? (
            <div className="pageheader">
              <div className="row align-items-center mb-3">
                <div className="col">
                  <h1 className="page-header-title m-0">
                    Create / Edit Team Details
                  </h1>
                </div>
                <div className="col-sm-auto mt-sm-0 mt-3">

                  {location?.state?.edit ? <button
                    className="btn btn-dark addEventBtn"
                    onClick={editTeam}
                  >
                    EDIT TEAM
                  </button> : <button
                    className="btn btn-dark addEventBtn"
                    onClick={createTeam}
                  >
                    ADD TEAM
                  </button>}


                </div>
              </div>
              <div className="row mx-0 mb-sm-4 mb-3">
                <div className="col-md-6 col-sm-8 px-0">
                  <label
                    htmlFor="eventitle"
                    className="form-label fw-bold fs-4"
                  >
                    Team Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="teamName"
                    value={inputs.teamName}
                    onChange={(e) => handleInputFields(e)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleInputFields(e);
                      }
                    }}
                    id="eventitle"
                    placeholder="Enter Event Title"
                  />
                </div>
              </div>
              <div className="row mx0 mb-3">
                <div className="col-md-6 col-sm-8 mb-3">
                  <label htmlFor="" className="form-label fw-bold fs-4">
                    Invite Members
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text border-end-0"
                      id="basic-addon1"
                    >
                      <i className="bi bi-search"></i>
                    </span>
                    <input
                      type="text"
                      name="inviteUser"
                      value={inputs.inviteUser}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => handleInputFields(e)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleInputFields(e);
                        }
                      }}
                      className="form-control border-start-0 ps-0 shadow-none"
                      placeholder="Search by registered username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="searchOption">
                    <select
                      className="form-select rounded-0"
                      multiple
                      aria-label="multiple select example"
                      onChange={(e) => handleInviteUserSelect(e.target.value)}
                    >
                      {userData?.map((el, i) => {
                        return (
                          <option
                            value={el._id}
                            key={i}
                            onClick={() => handleUserData(el)}
                            selected={
                              edit
                                ? location.state.data.admin?._id == el._id
                                : ""
                            }
                          >
                            {`${el.firstName} ${el.lastName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-8">
                  <div className="col-12 px-0 fs-3 fw-bold text-black ">
                    Assign Event Admin
                  </div>
                  <div className="col-12 px-0 mt-1">
                    <div className="col">
                      <div className="input-group">
                        <span
                          className="input-group-text border-end-0 rounded-0"
                          id="basic-addon1"
                        >
                          <i className="bi bi-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0 ps-0 shadow-none"
                          placeholder="Search by registered username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onClick={(e) => e.target.select()}
                          name="assignAdmin"
                          value={inputs.assignAdmin}
                          onChange={(e) => handleInputFields(e)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleInputFields(e);
                            }
                          }}
                        />
                      </div>
                      <div className="searchOption">
                        <select
                          className="form-select rounded-0"
                          multiple
                          aria-label="multiple select example"
                          onChange={(e) => handleAdminSelect(e)}
                        >
                          {allAdmin?.map((el, i) => {
                            return (
                              <option
                                value={el._id}
                                key={i}
                                onClick={() => handleEventData(el)}
                                selected={
                                  edit
                                    ? location.state.data.admin?._id == el._id
                                    : ""
                                }
                              >
                                {`${el.firstName} ${el.lastName}`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 custom_Tables">
                <div className="row ">
                  <div className="col-md-6 ">
                    {inviteUsers
                      ? inviteUsers?.map((el, i) => {
                        return (
                          <div
                            key={i}
                            className="row card mt-4 col-mx-4 mb-3 me-3 ms-1"
                          >
                            <div className="col-12">
                              <div className="row align-items-center pb-3 pt-3 mx-0">
                                <div className="col-auto">
                                  <div className=" border d-flex align-items-center justify-content-center rounded-pill">
                                    {el.image ? (
                                      <div className="adminImg rounded-pill overflow-hidden">
                                        <img
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                            borderRadius: "50px",
                                          }}
                                          src={el.image}
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <i
                                        style={{
                                          maxWidth: "40px",
                                          maxHeight: "40px",
                                        }}
                                        className="bi bi-person-fill"
                                      ></i>
                                    )}
                                  </div>
                                </div>
                                <div className="col">
                                  <h6 className="mb-0 fs-5">{`${el.firstName} ${el.lastName}`}</h6>
                                </div>
                                <div className="col-auto">
                                  <div className="form-check ">
                                    <input
                                      className="form-check-input  p-2 rounded-pill  border border-primary"
                                      onChange={(e) =>
                                        handleCheckedInite(el._id, e)
                                      }
                                      type="checkbox"
                                      value={el._id}
                                      id={el._id}
                                      checked={
                                        inviteUserId.includes(el)
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                  {/*  */}


                  <div className="col-md-6 ">



                    <div

                      className="row card mt-4 col-mx-4 mb-3 me-3 ms-1"
                    >
                      <div className="col-12">
                        <div className="row align-items-center pb-3 pt-3 mx-0">
                          <div className="col-auto">
                            <div className=" border d-flex align-items-center justify-content-center rounded-pill">
                              { 
                              adminData.image?
                              <div className="adminImg rounded-pill overflow-hidden">
                                <img
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50px",
                                  }}
                                  src={adminData.image}
                                  alt=""
                                />
                              </div> :

                              <i
                                style={{
                                  maxWidth: "40px",
                                  maxHeight: "40px",
                                }}
                                className="bi bi-person-fill"
                              ></i> }

                            </div>
                          </div>
                          <div className="col">
                            <h6 className="mb-0 fs-5">{adminData.name}</h6>
                          </div>
                          <div className="col-auto">
                            {/* <div className="form-check ">
                                    <input
                                      className="form-check-input  p-2 rounded-pill  border border-primary"
                                      onChange={(e) =>
                                        handleCheckedInite(el._id, e)
                                      }
                                      type="checkbox"
                                      value={el._id}
                                      id={el._id}
                                      checked={
                                        inviteUserId.includes(el)
                                          ? true
                                          : false
                                      }
                                    />
                                  </div> */}
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>

                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {GlobalTeam
            ? teams?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="row card mt-4 col-mx-4 mb-3 me-3 ms-1"
                >
                  <div className="col-12">
                    <div className="row align-items-center pb-3 pt-3 mx-0">
                      <div className="col-auto"></div>
                      <div className="col ">
                        <h3 className="mb-3 ">{item.name}</h3>
                        <h6 className="card-subtitle">
                          Added Participants: {item.invitedParticipants}
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="form-check ">
                          <input
                            className=" form-check-label"
                            type="radio"
                            name="team"
                            style={{ width: "1.2rem", height: "1.2rem" }}
                            onClick={() => {
                              handleOneTeams(item);
                              textChange(item._id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      </div>
    </div>
  );
}
