import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, putData } from "../../FetchService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
// import Config from "../../CommonPages/DigitalOcean/Config";
// import s3 from "../../CommonPages/DigitalOcean/Index";

function EventImages({ image, convertImageToUrl, imageSeter }) {
  const [images, setImages] = useState([]);
  const [img, setImg] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [singleImage, setSingleImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);

  const seter = imageSeter;

  const getImages = async () => {
    setLoader(true);
    const res = await getData("get-images");
    setImages(res.data);
    setLoader(false);
  };
  useEffect(() => {
    getImages();
  }, []);

  const handleMultiSelect = (id) => {
    if (selectedImage.includes(id)) {
      let fill = selectedImage.filter((el) => el != id);
      setSelectedImage(fill);
    } else {
      setSelectedImage([...selectedImage, id]);
    }
  };
  const handleMore = () => {
    setOpenModal((prev) => !prev);
  };
  const handleName = (e) => {
    setTitle(e.target.value);
  };

  const handlePostImage = async () => {
    const data = {
      image: image,
      title: title,
    };
    const res = await putData("add-image", data);
    if (res.status == true) {
      getImages();
      seter("");
      setImg("");
      setTitle("");
      handleMore();
      return toast.success(`${res.message} successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteImage = async () => {
    const data = {
      id: selectedImage.length ? selectedImage : singleImage,
    };
    const res = await putData("delete-images", data);
    if (res.status == true) {
      getImages();
      setDeleteModal(false);
      setSelectedImage([]);
      setSingleImage([]);
      return toast.success(`${res.message} successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setDeleteModal(false);
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="main mt-8">
        <div className="content container">
          <div
            style={{ position: "fixed", zIndex: "2" }}
            className="d-flex bg-white justify-content-between align-items-center me-2  w-75"
          >
            <div className="mt-1">
              <h3>Manage Images</h3>
            </div>
            <div>
              {selectedImage.length ? (
                <button
                  zindex={1}
                  type="button"
                  onClick={() => setDeleteModal(true)}
                  className="btn me-2 "
                >
                  <img height={25} src="../../../assets/img/delete-image.png" />
                </button>
              ) : (
                ""
              )}
              <button
                onClick={handleMore}
                type="button"
                className="btn btn-primary"
              >
                Add more
              </button>
            </div>
          </div>
          {loader ? (
            <div style={{ marginLeft: "400px" }}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ marginTop: "100px" }}
                wrapperclassName="blocks-wrapper"
                colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
              />
            </div>
          ) : (
            <div
              style={{ position: "fixed", height: "70%", overflow: "auto" }}
              className="d-flex mt-6 ms-lg-6  row justify-content-start align-items-center"
            >
              {images.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`card   card-hover-shadow  m-3  ${
                      selectedImage.includes(item._id)
                        ? " border border-3 border-primary bg-light bg-gradient"
                        : ""
                    }`}
                    style={{ width: "15rem" }}
                  >
                    <div className="d-flex justify-content-between ms-2 mt-2">
                      {selectedImage.includes(item._id) ? (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedImage.includes(item._id)}
                          onChange={() => handleMultiSelect(item._id)}
                        ></input>
                      ) : (
                        <span></span>
                      )}
                      <span
                        onClick={() => {
                          setDeleteModal(true);
                          setSingleImage([item._id]);
                        }}
                        className="cross"
                      >
                        ✖
                      </span>
                    </div>
                    <div onClick={() => handleMultiSelect(item._id)}>
                      <div className="d-flex justify-content-conter cursor-pointer ">
                        <img
                          id="foo"
                          height={200}
                          src={item.image}
                          className="card-img-top"
                          alt="No image"
                        />
                      </div>
                      <div className="card-body btn">
                        <h5 className="card-title">{item.title}</h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Add image start */}

      <div
        className={`modal fade ${openModal ? "show" : ""}`}
        id="addEditCealthCategories"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: openModal ? "block" : "none" }}
        aria-hidden={openModal ? "true" : "false"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg w-50 ">
          <div className="modal-content  bg-light border border-3">
            <div className="modal-header p-3 p-sm-5">
              <h5 className="modal-title fs-4 fs-sm-1" id="editUserModalLabel">
                Add image
              </h5>
              <img
                onClick={handleMore}
                style={{ cursor: "pointer" }}
                height={13}
                src="../../../assets/img/image-72.png"
              />
            </div>

            <div className="modal-body ps-3 ps-sm-5 pb-3 pb-sm-5 pe-3 pe-sm-5 pt-0">
              <form>
                <div className="row mb-4 flex-column">
                  <div className="col-sm-12">
                    <label className="col-sm-12 col-form-label form-label b-2">
                      Choose image
                    </label>
                    <div>
                      <label
                        htmlFor="img"
                        className="d-flex row align-items-center justify-content-center"
                      >
                        <div>
                          {image ? (
                            <img height={100} width={100} src={img} alt="" />
                          ) : (
                            <img
                              height={100}
                              width={100}
                              src="../../../assets/img/upload-icon1.png"
                              alt=""
                            />
                          )}
                        </div>
                        <span className="ms-5">Upload File</span>
                      </label>

                      <input
                        id="img"
                        style={{ display: "none" }}
                        type="file"
                          accept="image/*"
                        onChange={(e) => {
                          convertImageToUrl(e);
                          setImg(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4 flex-column">
                  <label className="col-sm-12 col-form-label form-label">
                    Enter image title
                  </label>
                  <div className="col-sm-12">
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="js-toggle-password form-control "
                        placeholder="Enter image title"
                        value={title}
                        onChange={(e) => handleName(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleMore()}
                    >
                      Cancel
                    </button>
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handlePostImage()}
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          handlePostImage();
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal for delete --> */}
      <div>
        <div
          className={`modal fade ${deleteModal ? "show" : ""}`}
          id="askdeletecategory"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          style={{ display: deleteModal ? "block" : "none" }}
          aria-hidden={deleteModal ? "true" : "false"}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content bg-light border border-3 card-body">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete the image ?
                {/* <strong>{row.categories}</strong> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleDeleteImage()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setDeleteModal(false);
                    setSelectedImage([]);
                    setSingleImage([]);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventImages;
