import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getData, postData } from "../../FetchService";
function InviteMember() {
  const location = useLocation();
  const navigate = useNavigate();
  const [allMembers, setAllMembers] = useState([]);
  const [search, setSearch] = useState("");
  const [selcted_Member, set_SelectedMember] = useState([]);
  const [membersId, setMembersId] = useState([]);
  const getInviteMember = async () => {
    const res = await getData(
      `search?search=${search}&offset=${0}&limit=${10}`
    );
    setAllMembers(res.data);
  };

  const getClubDetails = async () => {
    const res = await getData(`club-details?id=${location?.state?.id}`);
    set_SelectedMember(res.data.members);
    let arr = [];
    res.data.members.map((el) => arr.push(el._id));
    setMembersId(arr);
  };
  useEffect(() => {
    getClubDetails();
  }, []);
  useEffect(() => {
    if (search) {
      getInviteMember();
    }
  }, [search]);
  const handleSelectChange = (para) => {
    let fill = selcted_Member.filter((el) => el._id == para._id);
    if (fill.length) {
      return 0;
    } else {
      set_SelectedMember([...selcted_Member, para]);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleClick = (id) => {
    if (membersId.includes(id)) {
      let fill = membersId.filter((el) => el !== id);
      setMembersId(fill);
    } else {
      setMembersId([...membersId, id]);
    }
  };

  const postInviteUsers = async () => {
    const body = {
      clubId: location?.state?.id,
      users: membersId,
    };
    const res = await postData("invite-users-club", body);
    if (res.status) {
      setTimeout(() => {
        navigate("/manageclubs/leaderboardviewclubdetails", {
          state: { id: location?.state?.id },
        });
      }, 2000);
    }
  };

  return (
    <div>
      <div className="main mt-8">
        <div className="content container-fluid addEditHealthArticles manage_user manage_event_list manageUpcomingFree ">
          <div className="pageheader">
            <div className="row g-2 align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0 border-botom-0">
                  Invite Members
                </h1>
              </div>
              <div className="col-sm-auto mt-sm-0 mt-3">
                <div
                  className="btn btn-dark addEventBtn"
                  onClick={() => postInviteUsers()}
                >
                  Send Invite
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-12">
              <div class="col-12">
                <div class="row g-2 mb-4">
                  <div class="col-12 col-sm-6 col-md-5 col-lg-4">
                    <div className="">
                      <div className="input-group">
                        <span
                          className="input-group-text border-end-0 rounded-0"
                          id="basic-addon1"
                        >
                          <i className="bi bi-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0 shadow-none rounded-0"
                          placeholder="Search by registered username"
                          onClick={(e) => e.target.select()}
                          value={search}
                          onChange={(e) => handleSearch(e)}
                          onKeyPress={(e) => {
                            if (e.charCode == 13) {
                              handleSearch(e);
                            }
                          }}
                        />
                      </div>
                      <div className="searchOption">
                        <select
                          className="form-select rounded-0"
                          multiple={true}
                          //   onChange={(e) => handleSelectChange(e)}
                          // defaultValue={[defaultV]}
                        >
                          {allMembers?.map((el, i) => {
                            return (
                              <option
                                value={el._id}
                                key={i}
                                onClick={() => handleSelectChange(el)}
                                //   selected={
                                //     edit
                                //       ? location.state.data.admin?._id == el._id
                                //       : false
                                //   }
                              >
                                {`${el.firstName} ${el.lastName}`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 custom_Tables">
                <div className="col ">
                  <div className="col-md-6">
                    {selcted_Member.map((el, i) => {
                      return (
                        <div
                          key={i}
                          className="row"
                          onClick={() => handleClick(el._id)}
                        >
                          <div className="col-12">
                            <div className="row align-items-center mb-3 border-bottom py-3 mx-0">
                              <div className="col-auto">
                                <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                  <img
                                    src={el.image}
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      borderRadius: "50px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <h6 className="mb-0 fs-5">{`${el.firstName} ${el.lastName}`}</h6>
                              </div>
                              <div className="col-auto">
                                <div className="form-check">
                                  <input
                                    className="form-check-input border-1  p-2 rounded-pill"
                                    type="checkbox"
                                    value={el._id}
                                    onChange={(e) =>
                                      handleClick(e.target.value)
                                    }
                                    checked={membersId.includes(el._id)}
                                    id="defaultCheck1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteMember;
