import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { postData } from "../../FetchService";
import SideBar from "../SideBar";

const Authentication = () => {
  let token = localStorage.getItem("token");
  return token ? (
    <>
      <SideBar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Authentication;
