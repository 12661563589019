import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getData, postData } from "../../FetchService";

export default function ClubMembers({ id }) {
  const navigate = useNavigate();
  const [clubDetails, setClubDetails] = useState({});
  const [remove, setRemove] = useState(false);
  const [make, setMake] = useState(false);
  const [data, setData] = useState({});

  const handleClubDetails = async () => {
    const res = await getData(`club-details?id=${id}`);
    setClubDetails(res?.data);
  };

  useEffect(() => {
    handleClubDetails();
  }, []);
  const handleMakeAdmin = async (uId, cId) => {
    const data = {
      clubId: cId,
      userId: uId,
    };
    const res = await postData("make-admin", data);
    if (res.status) {
      setMake(false);
      handleClubDetails();
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRemoveADmin = async (uId, cId) => {
    const data = {
      clubId: cId,
      users: [uId],
    };

    const res = await postData("remove-club-member", data);
    if (res.status) {
      setRemove(false);
      handleClubDetails();
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className="row">
        <div className="col-12 col-md-8">
          <div
            style={{ padding: "30px" }}
            className="table-responsive datatable-custom border rounded "
          >
            <div className="mt-0  mb-5">
              <button
                className="btn btn-dark"
                type="button"
                onClick={() =>
                  navigate("/invitemember", {
                    state: { id: id },
                  })
                }
              >
                {" "}
                + Invite More FItstreet Member
              </button>
            </div>
            <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table custom_Tables">
              <thead className="col-12">
                <tr className="border-bottom">
                  {!!clubDetails.admin ? (
                    <th className="row d-flex align-items-center">
                      <div className="col-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <img
                            className=" profile-admin"
                            src={clubDetails?.admin?.image}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-4">{`${clubDetails?.admin?.firstName} ${clubDetails?.admin?.lastName}`}</div>{" "}
                      <span className="col-1 text-danger ms-3 fs-6 fw-lighter rounded-pill d-flex align-items-center">
                        Admin
                      </span>
                    </th>
                  ) : !!clubDetails?.members ? (
                    ""
                  ) : (
                    <th className="col-6 d-flex align-items-center justify-content-center">
                      <span className="text-danger fs-6 fw-lighter ">
                        No admin !
                      </span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {clubDetails?.members?.length ? (
                  clubDetails?.members?.map((el, i) => {
                    return (
                      <tr key={i}>
                        <td className="ms-2">
                          <div className="d-flex align-items-center justify-content-start ms-xl-4 ">
                            <div className="d-flex  align-items-center justify-content-start ">
                              <img
                                className=" profile-member"
                                src={el?.image}
                                alt=""
                              />
                            </div>
                            <span className="ms-3 d-flex  align-items-center justify-content-start">
                              {`${el?.firstName} ${el?.lastName}`}
                            </span>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="row align-items-center justify-content-center">
                            <div className="col-6">
                              <button
                                className="icon_w border d-flex align-items-center justify-content-center rounded-pill "
                                onClick={() => {
                                  setRemove(true);
                                  setData(el);
                                }}
                              >
                                <i className="bi bi-dash text-black"></i>
                              </button>
                            </div>
                            {!el.isAdmin ? (
                              <div className="col-6">
                                <button
                                  className="btn btn-dark rounded"
                                  onClick={() => {
                                    setMake(true);
                                    setData(el);
                                  }}
                                >
                                  Make Admin
                                </button>
                              </div>
                            ) : (
                              <div className="col-6"></div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="col-12 d-flex align-items-center justify-content-center card">
                      <span className="text-danger  mt-3 mb-3">
                        No Data Found !
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <-- modal ---> */}
      <div
        className={`modal fade ${make || remove ? "show" : ""}`}
        id="multypleEventDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={make || remove ? "true" : "false"}
        style={{
          display: make || remove ? "block" : "none",
        }}
        role={make || remove ? "dialog" : ""}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border border-2">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            {make ? (
              <div className="modal-body">
                Do you want to make admin -
                {<strong>{`${data?.firstName} ${data?.lastName}`}</strong>}
              </div>
            ) : (
              <div className="modal-body">
                Do you want to remove -
                {<strong>{`${data?.firstName} ${data?.lastName}`}</strong>}
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  remove
                    ? handleRemoveADmin(data?._id, clubDetails?._id)
                    : handleMakeAdmin(data?._id, clubDetails?._id);
                }}
              >
                {make ? "Make Admin" : "Remove"}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setRemove(false);
                  setMake(false);
                  setData({});
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
