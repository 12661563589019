import { Loader } from "@googlemaps/js-api-loader";
import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Moment from "react-moment";
import Map from "../../CommonPages/Map";

function Activity({ activities, active }) {
  return (
    <div>
      <div className="row g-2">
        <div className="col-md-8">
          <div className="d-grid gap-3 gap-md-5">
            <div className="card-header card-header-content-between px-3 py-4">
              <h4 className="card-header-title">Reported Activity Details</h4>
            </div>

            {activities?.length ? (
              activities.map((el, i) => {
                const loader = new Loader({
                  apiKey: process.env.REACT_APP_API_KEY,
                  version: "weekly",
                });
                let map;
                loader.load().then((res) => {
                  map = new window.google.maps.Map(
                    document.getElementById(`map${i}`),
                    {
                      center: {
                        lat: 27.5775488,
                        lng: 76.611584,
                      },
                      zoom: 8,
                      gestureHandling: "cooperative",
                    }
                  );
                });
                let url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&callback=map&libraries=geometry,drawing,places`;
                return (
                  <div key={i} className="card">
                    <div className="card-body viewReprtedActivityDetailsOuter px-2 px-sm-3 text-black">
                      <div className="col-12 bg-white card_box_sh">
                        <div className="row mx-0 card_box_profile">
                          <div className="col-12">
                            <div className="row align-items-center justify-content-between border-bottom pb-1">
                              <div className="col-auto px-0">
                                <div className="d-flex align-items-center user_name_image">
                                  <div className="customImageBorder">
                                    <div className="custom_user_image">
                                      <img
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "50%",
                                        }}
                                        src={el.user.image}
                                        className="user_image"
                                        alt="Profile_pic"
                                      />
                                    </div>
                                  </div>
                                  <div className="profile_user_text text-black">
                                    <div className="user_name mb-sm-1 ms-3">
                                      {`${el.user.firstName} ${el.user.lastName} `}
                                    </div>
                                    <div className="time_and_Date ms-3">
                                      {/* 10 Mar 2021, 10:30 PM{" "} */}
                                      <Moment format="DD-MMM-YY hh:mm A">
                                        {el.createdAt}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 text_Message mb-2 px-0 mt-2">
                              <div className="morning_walk_time text-black fs-4">
                                {el.title}
                              </div>
                              <div className="morning_walk_time text-black fs-6 mt-2 mb-2 ">
                                {el.description}
                              </div>
                              <div className="col-12 clc_time_km mb-3 px-0 d-flex align-items-center gap-sm-2">
                                <div className="d-flex row gap-md-2 gap-1 align-items-center">
                                  <span className="fs-6">Time</span>
                                  <span className="fs-6">
                                    {Math.floor(el.time / 3600)} hr
                                  </span>
                                </div>
                                <div className="d-flex row gap-md-2 gap-1 align-items-center">
                                  <span className="fs-6">Distance</span>
                                  <span className="fs-6">
                                    {el.distance / 1000} km
                                  </span>
                                </div>
                                <div className="d-flex row gap-md-2 gap-1 align-items-center">
                                  <span className="fs-6">Calories</span>
                                  <span className="fs-6">
                                    {el.calories} cal
                                  </span>
                                </div>
                                <div className="d-flex row gap-md-2 gap-1 align-items-center">
                                  <span className="fs-6">Earned Points</span>
                                  <span className="fs-6">{el.points} pts</span>
                                </div>
                              </div>
                              <div className="col-12 map mb-2 px-0">
                                {
                                  <div
                                    id={`map${i}`}
                                    style={{
                                      height: "50vh",
                                      width: "50vw",
                                    }}
                                    className="maps_card w-100"
                                  >
                                    <Map
                                      googleMapURL={url}
                                      loadingElement={
                                        <div
                                          style={{
                                            height: `100%`,
                                          }}
                                        />
                                      }
                                      containerElement={
                                        <div
                                          style={{
                                            height: "80vh",
                                          }}
                                        />
                                      }
                                      mapElement={
                                        <div
                                          style={{
                                            height: `100%`,
                                          }}
                                        />
                                      }
                                      center={{
                                        lat: 27.5775488,
                                        lng: 76.611584,
                                      }}
                                      gestureHandling="cooperative"
                                      zoom
                                    />
                                  </div>
                                }
                              </div>
                              <div className="col-12 mb-md-2 like_com_shr px-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center gap-md-3 gap-1 gap-sm-2 text-black">
                                    <span className="widText">
                                      <span className="wid">
                                        {/* <i className="bi bi-hand-thumbs-up"></i> */}
                                        {el.isLiked ? (
                                          <AiFillHeart
                                            style={{
                                              color: "red",
                                              fontSize: "20px",
                                            }}
                                          />
                                        ) : (
                                          <AiOutlineHeart
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        )}
                                      </span>{" "}
                                      {el.likes} likes
                                    </span>
                                    <span className="widText">
                                      <span className="wid">
                                        <i className="bi bi-chat-left-text"></i>
                                      </span>{" "}
                                      {el.comments} Comments
                                    </span>
                                    <span className="widText">
                                      <span className="wid">
                                        <i className="bi bi-graph-up-arrow"></i>
                                      </span>{" "}
                                      View analysis
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-12 d-flex align-items-center justify-content-center card">
                <span className="text-danger  mt-3 mb-3">No Data Found</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;
