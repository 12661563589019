import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useState } from "react";
import { getData } from "../../FetchService";
let categories = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
const GrowthChart = () => {
  const [userGrowth, setUserGrowth] = useState([]);
  const [isactive, setIsActive] = React.useState(false);
  const handleuserGrowth = async () => {
    const res = await getData("user-growth");
    let ser = [];
    res.data
      .sort((a, b) => (parseInt(a._id) > parseInt(b._id) ? 1 : -1))
      .map((el, i) => {
        ser.push({
          name: categories[i],
          y: res.data[i]?.users,
          borderWidth: 6,
          relativeXValue: false,
          edgeColor: "red",
          edgeWidth: 5,
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        });
      });
    setUserGrowth(ser);
    setIsActive(true);
  };
  useEffect(() => {
    handleuserGrowth();
  }, []);
  console.log(userGrowth, "userGroth");
  const getOptions = (type) => ({
    chart: {
      type,
    },
    title: {
      text: _.startCase(``),
    },
    accessibility: {
      enabled: false,
    },

    tooltip: {
      positioner: function (labelWidth, labelHeight, point) {
        var tooltipX = point.plotX + 20;
        var tooltipY = point.plotY - 30;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      formatter: function () {
        return (
          '<span style="color:black;font-weight:bold;">' +
          `${this.series.name}<br>` +
          '<b style="color:#D31B22;font-weight:bold;">' +
          `Users-${this.y} <br>`
        );
      },
    },
    legend: false,
    yAxis: {
      title: {
        text: "Users",
      },
      lineColor: "black",
      lineWidth: 1,
      min: 0,
      max: userGrowth.length > 6 ? userGrowth.length * 50 : 250,
      tickInterval: 50,
      startPoint: 0,
    },

    xAxis: {
      title: {
        text: "Months",
      },

      min: 0,
      max: 11,
      categories: categories,
    },
    series: [
      {
        data: userGrowth,
      },
    ],
  });
  return (
    <>
      {isactive && (
        <div className="w-100">
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions("column")}
          />
        </div>
      )}
    </>
  );
};

export default GrowthChart;
