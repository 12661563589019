import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { postData } from "../../FetchService/index";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgetPassword() {
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      // .email("Field should contain a valid e-mail")
      // .max(255)
      .required("E-mail is required"),
  });

  const sendotp = async (value) => {
    var data = {
      email: value?.email,
    };

    const res = await postData("generate-otp", data);
    if (res.status == true) {
      setTimeout(() => {
        navigate("/reset", { state: { id: res.data } });
      }, 2000);
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Check Email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: "url(assets/svg/components/card-6.svg)",
        }}
      >
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
      </div>
      <div className="container py-5 py-sm-7 vh-100">
        <div
          className="mx-auto h-100 row align-items-center justify-content-center"
          style={{ maxWidth: "30rem" }}
        >
          <div className="card card-lg mb-5">
            <ToastContainer autoClose={1000} />
            <div className="card-body px-2 py-3 p-sm-5">
              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={(value) => sendotp(value)}
              >
                <Form className="js-validate needs-validation" noValidate="">
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Forgot password?</h1>
                      <p>
                        Enter your registered email address to get the reset
                        password link.
                      </p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label"
                      htmlFor="resetPasswordSrEmail"
                      tabIndex="0"
                    >
                      Your email
                    </label>
                    <Field
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      id="resetPasswordSrEmail"
                      tabIndex="1"
                      placeholder="Email Address"
                      aria-label="Enter your email address"
                      required=""
                    />
                  </div>
                  <p className="text-danger text-start">
                    <ErrorMessage name="email" />
                  </p>
                  <div className="d-grid gap-2">
                    <button
                      // onClick={() => {
                      //   navigate("/reset", { state: { data: "allu" } });
                      // }}
                      type="submit"
                      className="btn btn-primary btn-lg"
                      // to="/reset"
                    >
                      Send Link
                    </button>
                    <div className="text-center">
                      <NavLink className="btn btn-link" to="/">
                        <i className="bi-chevron-left"></i> Back to Login
                      </NavLink>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgetPassword;
