import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBar from "../SideBar";
import './style.css'
function Header() {
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const logOutHandler = () => {
    var token = localStorage.removeItem("token");
    var user = localStorage.removeItem("user");
    if (token == null && user == null) {
      toast.success("Logout Successfull !",{
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  // if (sidebar) {
  //   console.log('hi')
    // let element = document.getElementById("logo");
    // element.classList.toggle("mystyle");
  // } 
 
 


  const getdrawer = () => {
    let element = document.getElementById("logo");
    if (sidebar) {
      element.classList.add("mystyle");
    } else {
      element.classList.remove("mystyle");
    }
  };



  useEffect(() =>{
    getdrawer()
  },[sidebar])
  return (
    <>
     <div
      style={{backgroundColor:'white'}}
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered mainNavbar "
    >
      <ToastContainer autoClose={1000} />
      <div className="navbar-nav-wrap">
        <NavLink className="navbar-brand" aria-label="Front">
          <img
            className="navbar-brand-logo"
            src="/assets/img/image-69.png"
            style={{ minWidth: "1.5rem" }}
            onClick={() => {setSideBar(true)}}
            alt="Logo"
          
            data-hs-theme-appearance="default"
          />
        </NavLink>
      
        <div className="navbar-nav-wrap-content-start">
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler d-block d-xl-none"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Collapse"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Expand"
            ></i>
          </button>
        </div>
        <div className="navbar-nav-wrap-content-end">
          <ul className="navbar-nav">
          
            <li className="nav-item">
              <div className="dropdown">
                <NavLink
                  className="navbar-dropdown-account-wrapper"
                  id="accountNavbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  data-bs-dropdown-animation
                >
                  <div className="avatar avatar-sm avatar-circle">
                    <img
                      className="avatar-img"
                      src="/assets/img/160x160/img6.jpg"
                      alt="Image Description"
                    />
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                </NavLink>
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  style={{ width: "16rem" }}
                >
                  <NavLink className="dropdown-item" to="/changepassword">
                    <i className="bi bi-unlock me-2"></i> Change Password
                  </NavLink>

                  <button
                    className="dropdown-item"
                    style={{
                      backgroundColor: "none",
                      outline: "none",
                      border: "none",
                    }}
                    onClick={logOutHandler}
                  >
                    <i className="bi bi-box-arrow-right me-2"></i> Logout{" "}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
     <SideBar sidebar={sidebar} setSideBar={setSideBar}/>
    </>
   
   
  );
}

export default Header;
