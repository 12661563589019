import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
const EarningChart = ({ earningDetails, isactive }) => {
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);
  const [january, setJanuary] = useState([]);
  const [feb, setfeb] = useState([]);
  const [march, setmarch] = useState([]);
  const [april, setApril] = useState([]);
  const [may, setmay] = useState([]);
  const [june, setJune] = useState([]);
  const [july, setJuly] = useState([]);
  const [august, setAugust] = useState([]);
  const [september, setSeptember] = useState([]);
  const [october, setOctober] = useState([]);
  const [november, setNovember] = useState([]);
  const [december, setDecember] = useState([]);
  const [year21, setYear21] = useState([]);
  const [year22, setYear22] = useState([]);
  const [year23, setYear23] = useState([]);
  const [year24, setYear24] = useState([]);
  const [year25, setYear25] = useState([]);
  const [year26, setYear26] = useState([]);
  const [year27, setYear27] = useState([]);
  const [year28, setYear28] = useState([]);
  const [year29, setYear29] = useState([]);
  const [year30, setYear30] = useState([]);
  const [daily, setDaily] = useState([]);
  const [xformat, setxformat] = useState([]);

  let format = {};
  let dailyAmount = () => {
    let filterdata = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).getDay()} ${new Date(
          el.createdAt
        ).getDate()} ${new Date(el.createdAt).getMonth()} ${new Date(
          el.createdAt
        ).getFullYear()}` ==
        `${new Date().getDay()} ${new Date().getDate()} ${new Date().getMonth()} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    filterdata?.map((el) => {
      if (
        0 <= `${new Date(el.createdAt).getHours()}` &&
        4 > new Date(el.createdAt).getHours()
      ) {
        if (Object.keys(format).includes("1-4 AM")) {
          let a = format["1-4"];
          a.push(el);
          format["1-4 AM"] = a;
        } else {
          format["1-4 AM"] = [el];
        }
      } else if (
        4 <= new Date(el.createdAt).getHours() &&
        8 > new Date(el.createdAt).getHours()
      ) {
        if (Object.keys(format).includes("4-8 AM")) {
          let a = format["4-8 AM"];
          a.push(el);
          format["4-8 AM"] = a;
        } else {
          format["4-8 AM"] = [el];
        }
      } else if (
        8 <= new Date(el.createdAt).getHours() &&
        12 > new Date(el.createdAt).getHours()
      ) {
        if (Object.keys(format).includes("8-12 AM")) {
          let a = format["8-12 AM"];
          a.push(el);
          format["8-12 AM"] = a;
        } else {
          format["8-12 AM"] = [el];
        }
      } else if (
        12 <= new Date(el.createdAt).getHours() &&
        16 > new Date(el.createdAt).getHours()
      ) {
        if (Object.keys(format).includes("12-04 PM")) {
          let a = format["12-04 PM"];
          a.push(el);
          format["12-04 PM"] = a;
        } else {
          format["12-04 PM"] = [el];
        }
      } else if (
        16 <= new Date(el.createdAt).getHours() &&
        20 > new Date(el.createdAt).getHours()
      ) {
        if (Object.keys(format).includes("04-08 PM")) {
          let a = format["04-08 PM"];
          a.push(el);
          format["04-08 PM"] = a;
        } else {
          format["04-08 PM"] = [el];
        }
      } else {
        if (Object.keys(format).includes("08-12 PM")) {
          let a = format["08-12 PM"];
          a.push(el);
          format["08-12 PM"] = a;
        } else {
          format["08-12 PM"] = [el];
        }
      }
    });

    for (let key in format) {
      let amount = 0;
      for (let elem of format[key]) {
        amount += elem.amount;
      }
      format[key] = amount;
    }

    let ab = Object.values(format).map((el) => {
      return el;
    });
    setDaily(ab);
    let xaxis = Object.keys(format).map((el) => {
      return el;
    });
    setxformat(xaxis);
  };

  let getWeekDay = [];
  let weeklyAmount = () => {
    var now = new Date();
    var startDay = 1;
    var date = now.getDay();
    var weekStart = new Date(
      now.valueOf() - (date <= 0 ? 7 - startDay : date - startDay) * 86400000
    );
    var weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

    earningDetails?.filter((el) => {
      if (
        new Date(el.createdAt).toISOString().slice(0, 10) >=
          weekStart.toISOString().slice(0, 10) &&
        new Date(el.createdAt).toISOString().slice(0, 10) <=
          weekEnd.toISOString().slice(0, 10)
      ) {
        getWeekDay.push(el);
      }
    });

    let a = getWeekDay.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Mon"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setMonday(a);
    let b = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Tue"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setTuesday(b);
    let c = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Wed"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setWednesday(c);
    let d = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Thu"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setThursday(d);

    let e = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Fri"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setFriday(e);
    let f = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Sat"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setSaturday(f);
    let g = getWeekDay?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Sun"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setSunday(g);
  };

  let monthlyAmount = () => {
    let a = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Jan"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setJanuary(a);
    let b = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Feb"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setfeb(b);
    let c = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Mar"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setmarch(c);
    let d = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Apr"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setApril(d);
    let e = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"May"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setmay(e);
    let f = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Jun"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setJune(f);
    let g = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Jul"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setJuly(g);
    let h = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Aug"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setAugust(h);
    let i = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Sep"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setSeptember(i);
    let j = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Oct"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setOctober(j);
    let k = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Nov"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setNovember(k);
    let l = earningDetails?.filter((el) => {
      if (
        `${new Date(el.createdAt).toLocaleDateString("en-US", {
          month: "short",
        })} ${new Date(el.createdAt).getFullYear()}` ==
        `${"Dec"} ${new Date().getFullYear()}`
      ) {
        return el;
      }
    });
    setDecember(l);
  };

  let yearlyAmount = () => {
    let a = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2021").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear21(a);
    let b = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2022").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear22(b);
    let c = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2023").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear23(c);
    let d = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2024").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear24(d);
    let e = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2025").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear25(e);
    let f = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2026").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear26(f);
    let g = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2027").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear27(g);
    let h = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2028").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear28(g);
    let i = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2029").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear29(i);
    let j = earningDetails?.filter((el) => {
      if (
        `${new Date(el?.createdAt).getFullYear()}` ==
        `${new Date("2030").getFullYear()}`
      ) {
        return el;
      }
    });
    setYear30(j);
  };

  const mon = monday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const tue = tuesday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const wed = wednesday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const thu = thursday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const fri = friday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const sat = saturday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const sun = sunday?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const jan = january?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const Feb = feb?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const mar = march?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const apr = april?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const May = may?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const jun = june?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const jul = july?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const aug = august?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const sep = september?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const oct = october?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const nov = november?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const dec = december?.reduce(
    (total, currentValue) => (total = total + currentValue.amount),
    0
  );
  const Year21 = year21?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year22 = year22?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year23 = year23?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year24 = year24?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year25 = year25?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year26 = year26?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year27 = year27?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year28 = year28?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year29 = year29?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const Year30 = year30?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );

  const totalEarning = earningDetails?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  const weekAmount = mon + tue + wed + thu + fri + sat + sun;
  const monthAmount =
    jan + Feb + mar + apr + May + jun + jul + aug + sep + oct + nov + dec;

  let totaldayAmount = daily.reduce(
    (total, currentValue) => (total = total + currentValue),
    0
  );

  useEffect(() => {
    weeklyAmount();
    monthlyAmount();
    yearlyAmount();
    dailyAmount();
  }, [earningDetails]);

  let cYear = new Date().getFullYear();
  let cMonth = new Date().getMonth();

  let a = earningDetails?.filter((el) => {
    const currentYear = new Date(el.createdAt).getFullYear();
    if (currentYear == cYear) {
      return el;
    }
  });

  let b = earningDetails?.filter((el) => {
    const currentMonth = new Date(el.createdAt).getMonth();
    if (currentMonth == cMonth) {
      return el;
    }
  });

  let yearlyTotal = a?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );
  let monthlyTotal = b?.reduce(
    (total, currentValue) => (total = total + currentValue?.amount),
    0
  );

  let dayAverageAmount = totaldayAmount / yearlyTotal;
  let weekAverageAmount = weekAmount / yearlyTotal;
  let monthAverageAmount = monthlyTotal / yearlyTotal;
  let yearAverageAmount = yearlyTotal / totalEarning;

  const getdailyOption = (type) => ({
    chart: {
      type,
      width: 850,
      height: 450,
    },
    title: {
      text: `${"Rs "}${totaldayAmount}${" "}Total Earning  | ${"Rs "}${dayAverageAmount.toFixed(
        2
      )}${" "}Average Earning`,
    },

    accessibility: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Amount (Rs)",
      },
      tickInterval: 2000,
      startPoint: 0,
    },

    xAxis: {
      title: {
        text: "Daily Amount",
      },
      categories: xformat,
    },
    series: [
      {
        name: "Daily Amount",
        data: daily,
      },
    ],
  });

  const getweeklyOption = (type) => ({
    chart: {
      type,
      width: 850,
      height: 450,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: `${"Rs "}${weekAmount}${" "}Total Earning  | ${"Rs "}${weekAverageAmount.toFixed(
        2
      )}${" "}Average Earning`,
    },

    yAxis: {
      title: {
        text: "Amount (Rs)",
      },
      tickInterval: 2000,
      startPoint: 0,
    },

    xAxis: {
      title: {
        text: "Weekly Amount",
      },
      categories: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
    series: [
      {
        name: "Weekly Amount",
        data: [mon, tue, wed, thu, fri, sat, sun],
      },
    ],
  });
  const getmonthlyOption = (type) => ({
    chart: {
      type,
      width: 850,
      height: 450,
    },
    title: {
      text: `${"Rs "}${monthAmount}${" "}Total Earning  | ${"Rs "}${monthAverageAmount.toFixed(
        2
      )}${" "}Average Earning`,
    },
    accessibility: {
      enabled: false,
    },

    yAxis: {
      title: {
        text: "Amount (Rs)",
      },
      tickInterval: 2000,
      startPoint: 0,
    },

    xAxis: {
      title: {
        text: "Monthly Amount",
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    series: [
      {
        name: "Monthly Amount",
        data: [jan, Feb, mar, apr, May, jun, jul, aug, sep, oct, nov, dec],
      },
    ],
  });
  const getyearlyOption = (type) => ({
    chart: {
      type,
      width: 850,
      height: 550,
    },
    title: {
      text: `${"Rs "}${totalEarning}${" "}Total Earning  | Average Earning ${"Rs "}${yearAverageAmount.toFixed(
        2
      )}${" "}`,
    },
    accessibility: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Amount (Rs)",
      },
      tickInterval: 2000,
      startPoint: 0,
    },
    xAxis: {
      title: {
        text: "Yearly Amount",
      },
      categories: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
    },
    series: [
      {
        name: "Yearly Amount",
        data: [
          Year21,
          Year22,
          Year23,
          Year24,
          Year25,
          Year26,
          Year27,
          Year28,
          Year29,
          Year30,
        ],
      },
    ],
  });
  return (

    <div>
      {isactive == "weekly" ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={getweeklyOption("line")}
        />
      ) : isactive == "monthly" ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={getmonthlyOption("line")}
        />
      ) : isactive == "yearly" ? (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={getyearlyOption("line")}
          />
        </>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={getdailyOption("line")}
        />
      )}
    </div>
  );
};

export default EarningChart;
