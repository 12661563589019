import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Headers from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { getData, putData } from "../../FetchService";

import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

export default function ManageCorporateEvents() {
  const [allEvents, setAllEvents] = useState([]);
  const [filterdData, setFilterdData] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [row, setRow] = useState("");
  const [loader, setLoader] = useState(true);
  const [multipleRow, setMultipleRow] = useState();

  const [toggleRow, setToggleRow] = useState(false);
  const navigate = useNavigate();

  const getAllEvents = async () => {
    setLoader(true);
    let res = await getData("events");
    let fill = res.data.filter((el) => el?.isCorporate == true);
    setAllEvents(fill);
    setLoader(false);
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    let v = e.target.value;
    setIsSearch(v);
    if (v) {
      let fill = allEvents.filter((el) => el.name.toLowerCase().includes(v));

      setFilterdData(fill);
    }
  };

  const multypleEventDelete = async () => {
    const data = {
      id: multipleRow,
    };
    const res = await putData("delete-events", data);
    if (res.status == true && multipleRow.length > 0) {
      getAllEvents();
      return toast.success(
        `${multipleRow.length} club deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      getAllEvents();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // getAllApiHandler();
      toast.error(`No club select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  const handleSelectedRowsChange = useCallback((row) => {
    let multi = row.selectedRows.map((el) => el._id);

    setMultipleRow(multi);
  });

  const handleDeleteEvent = async (row) => {
    const data = {
      name: row,
    };
    const res = await putData("delete-event", data);
    if (res.status == true) {
      getAllEvents();
      return toast.success(`${res.message} successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      getAllEvents();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "SR.NO",

      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Event Title",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
    },
    {
      name: "Price Type",
      selector: (row) => (row.amount == 0 ? "FREE" : "PAID"),
      sortable: true,
      center: true,
    },
    {
      name: "Event Amount (₹)",
      selector: (row) => row.amount,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => `${new Date(row.startDate).toLocaleString()}`,
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => (
        `${new Date(row.endDate).toLocaleString()}`
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Qualifying Activities",
      cell: (row) =>
        row.activities.map((el) => {
          return el == 1
            ? "Running "
            : "" || el == 2
              ? "Cycling "
              : "" || el == 3
                ? "Jumping "
                : "" || el == 4
                  ? "Wolking "
                  : "" || el == 5
                    ? "Skipping "
                    : "";
        }),

      sortable: true,
      // center: true,
      style: {
        width: "200px",
        // textAlign: "center",
      },
    },
    {
      name: "Total Participants",
      selector: (row) => row.participants,
      sortable: true,
      center: true,
      style: {
        width: "500px",
      },
    },
    {
      name: "Event Status",
      selector: (row) => 
        
        row.status == 1 ? (
          <span className="text-primary">Upcoming</span>
        ) : row.status == 2 ? (
          <span className="text-warning">Ongoing</span>
        ) : row.status == 3 ? (
          <span className="text-success">Completed</span>
        ) : (
          ""
        )
      ,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center justify-content-around ">
          <div
            className="btn btn-white btn-sm border-0"
            onClick={() => {
              if (row.amount == 0) {
                navigate("view-corporat-event-Details-Free", {
                  state: { data: row },
                });
              } else {
                navigate("view-corporate-event-details-paid", {
                  state: { data: row },
                });
              }
            }}
          // to="/eventdetailscompleted"
          >
            <i
              onClick={() => {
                if (row.amount == 0) {
                  navigate("view-corporat-event-Details-Free", {
                    state: { data: row },
                  });
                } else {
                  navigate("view-corporate-event-details-paid", {
                    state: { data: row },
                  });
                }
              }}
              className="bi bi-eye-fill"
            ></i>
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];
  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list manageUpcomingFree ">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">
                  Manage Corporate Events
                </h1>
              </div>
              <div className="col-sm-auto mt-sm-0 mt-3">
                <button
                  className="btn btn-dark addEventBtn"
                  onClick={() =>
                    navigate("add-edit-corporate-event-deetails", {
                      state: { edit: false },
                    })
                  }
                >
                  Add Event
                </button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-bottom-0 card-header-content-md-between row">
              <div className="mb-2 mb-md-0 col-auto">
                <form>
                  <div className="input-group input-group-merge input-group-flush">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      id="datatableSearch"
                      type="search"
                      className="form-control"
                      onChange={(e) => handleSearch(e)}
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          handleSearch(e);
                        }
                      }}
                      placeholder="Search event title"
                      aria-label="Search events"
                    />
                  </div>
                </form>
              </div>
              <div className="col px-0 active_inactive_btn_bar d-flex gap-2 justify-content-end">
                <button
                  // onClick={() => multypleEventDelete()}
                  type="button"
                  className="btn btn-white border-0"
                  data-bs-toggle="modal"
                  data-bs-target="#multypleEventDelete"
                >
                  <i className="bi bi-trash-fill fs-1"></i>
                </button>
              </div>
            </div>
            {loader ? (
              <div style={{ marginLeft: "400px" }}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginTop: "100px" }}
                  wrapperclassName="blocks-wrapper"
                  colors={[
                    "#b8c480",
                    "#B2A3B5",
                    "#F4442E",
                    "#51E5FF",
                    "#429EA6",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive datatable-custom ">
                <DataTable
                  columns={columns}
                  data={isSearch ? filterdData : allEvents}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggleRow}
                  pagination
                  selectableRows
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Modal for delete --> */}
      <div
        className="modal fade "
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">Do you want to delete the event</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleDeleteEvent(row.name)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="multypleEventDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete {multipleRow?.length} events
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => multypleEventDelete()}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
