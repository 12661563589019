import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getData } from "../../FetchService";

const Team = () => {
  const location = useLocation();
  const [teams, setTeams] = useState([]);
  const [singleTeam, setSingleTeam] = useState([]);
  const [show, setShow] = useState("");

  const textChange = (id) => {
    if (show == id) {
      setShow("");
    } else {
      setShow(id);
    }
  };
  const handleTeams = async () => {
    const res = await getData("get-all-global-teams");

    setTeams(res.data);
  };
  useEffect(() => {
    handleTeams();
  }, []);

  const handleOneTeams = async (el) => {
    const res = await getData(`get-one-global-team?id=${el}`);

    let a = res.data.map((el) => {
      return el.invitedUsers;
    });
    setSingleTeam(a[0]);
  };

  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list managePublicEvent">
          <div className="card shadow-none border-0 ">
            <div className="row mx-0 mb-3 align-items-center">
              <div className="col ps-0 sectionTitle text-black">
                Event Teams
              </div>
              <div className="col-auto customCol px-0">
                <NavLink to="manageteams" state={{ id: teams }}>
                  <button type="button" className="btn btn-primary typeEvent">
                    Manage Teams
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          {teams?.map((item, i) => {
            return (
              <div key={item._id} className="col-12 mt-4">
                <div className="accordion">
                  <div className="accordion-item mb-4 shadow rounded-3">
                    <h2 className="accordion-header">
                      <button
                        onClick={() => {
                          handleOneTeams(item._id);
                          textChange(item._id);
                        }}
                        className="accordion-button rounded-3"
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                      >
                        <div className="card shadow-none border-0 p-0 ">
                          <div className="card-body p-sm-3 p-2">
                            <div className="row align-items-center gx-2">
                              <div className="col-12 mb-4">
                                <h2 className="card-title text-inherit">
                                  {item.name}
                                </h2>
                              </div>
                              <div className="col-12 mb-2">
                                <h6 className="card-subtitle">
                                  Added Participants: {item.invitedParticipants}
                                </h6>
                              </div>
                              <div className="col-12 mb-2">
                                <h6 className="card-subtitle">
                                  Event Admin:{" "}
                                  {`${item?.admin?.firstName} ${item?.admin?.lastName}`}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse collapse ${
                        show == item._id ? "show" : "hide"
                      } `}
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm-6">
                            {singleTeam?.map((el, i) => {
                              return (
                                <div
                                  key={i}
                                  className="row align-items-center mb-3 border-bottom py-3 mx-0"
                                >
                                  <div className="col-auto">
                                    <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                      <img
                                        src={
                                          el?.image
                                            ? el?.image
                                            : "assets/img/event-img-1.png"
                                        }
                                        alt="no img"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <h6 className="mb-0 fs-5">
                                      {`${el?.firstName} ${el?.lastName}`}
                                    </h6>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Team;
