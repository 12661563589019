import React, { useCallback, useEffect } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import DataTable from "react-data-table-component";
import { Navigate, NavLink } from "react-router-dom";
import { getData, putData } from "../../FetchService";
import { postData } from "../../FetchService";
import { ColorRing } from "react-loader-spinner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage, yupToFormErrors } from "formik";
import * as yup from "yup";
import ErrorMessages from "../../CommonPages/ErrorMessages";

export default function ManageHealthCategories() {
  const navigate = useNavigate();
  const img =
    "https://i.pcmag.com/imagery/reviews/03aizylUVApdyL…ku1AvRV-39.fit_scale.size_760x427.v1605559903.png";
  const [addClicked, setAddClicked] = useState(false);
  const [searchInput, setSaerchInput] = useState("");
  const [allData, setAllData] = useState([]);
  const [filterd, setFilterd] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [edit, setEdit] = useState({
    id: "",
    image: "",
  });
  const [loader, setLoader] = useState(true);
  const [row, setRow] = useState([]);
  const [multipleRow, setMultipleRow] = useState();
  const [toggleRow, setToggleRow] = useState(false);

  const handleMultipleDelete = async () => {
    let data = {
      id: multipleRow,
    };

    const res = await putData("delete-health-categories", data);
    setName("");
    if (res.status == true && multipleRow.length > 0) {
      getAllApiHandler();
      return toast.success(`${multipleRow.length} ${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (res.status == false) {
      getAllApiHandler();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  const handleSelectedRowsChange = useCallback(
    (row) => {
      const selected = row.selectedRows.map((x) => x._id);
      setMultipleRow(selected);
    },
    [multipleRow]
  );

  const handleSearch = (e) => {
    e.preventDefault();
    setSaerchInput(e.target.value);
    let filterd = allData.filter((el) =>
      el.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilterd(filterd);
  };

  const getAllApiHandler = async () => {
    setLoader(true);
    let res = await getData("health-categories");
    setAllData(res.data);
    setLoader(false);
  };
  useEffect(() => {
    getAllApiHandler();
  }, []);

  const editAddCategogiesHandler = async (value) => {
    if (isEdit) {
      let data = {
        name: value.name,
        image: edit.image,
      };
      let res = await postData(`edit-health-category/${edit.id}`, data);
      if (res.status == true) {
        getAllApiHandler();
        return toast.success(`${res.message} Successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.message}!`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAddClicked(true);
      }
    } else {
      let data = {
        name: value.name,
        image: img,
      };
      let res = await postData("add-health-category", data);
      setName("");
      if (res.status == true) {
        getAllApiHandler();
        return toast.success(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAddClicked(true);
      }
    }
  };

  const deleteCategoriesHandler = async (row) => {
    setToggleRow(!toggleRow);
    let data = {
      id: [row],
    };

    const res = await putData("delete-health-categories", data);
    if (res.status) {
      getAllApiHandler();
      return toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "SR.NO",

      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Health Categories",
      selector: (row) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: "No. of Blogs",
      cell: (row) => (
        <div onClick={() => navigate("/managehealtharticles")} className="btn">
          {row.articles}
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="">
          <div
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#addEditCealthCategories"
            onClick={() => {
              setEdit({ ...edit, id: row._id, image: row.image });
              setIsEdit(true);
              setName(row.name);
            }}
          >
            <i className="bi-pencil-fill me-1"></i>
          </div>
          <div
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => {
              setRow(row._id);
              setName(row.name);
            }}
          >
            <i className="bi bi-trash-fill"></i>
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const categorySchema = yup.object().shape({
    name: yup.string().required("Name is required!"),
  });

  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">
                  Manage Health Categories
                </h1>
              </div>
            </div>
          </div>
          <div className="card manageHealthCategories">
            <div className="card-header border-bottom-0 card-header-content-md-between row align-items-center">
              <div className="mb-2 mb-md-0 col-sm-auto">
                <form>
                  <div className="input-group input-group-merge input-group-flush">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search users"
                      aria-label="Search users"
                      onKeyPress={(event) => {
                        if (event.key == "Enter") {
                          handleSearch(event);
                        }
                      }}
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </form>
              </div>
              <div className="col pt-sm-0 pt-3 px-0 active_inactive_btn_bar d-sm-flex gap-2 justify-content-center justify-content-sm-end">
                <div className="row mx-0 justify-content-between align-items-center">
                  {multipleRow?.length ? (
                    <div className="col-auto ps-0">
                      <button
                        type="button"
                        className="btn btn-white border-0 px-2"
                        data-bs-toggle="modal"
                        data-bs-target="#multypleEventDelete"
                      >
                        <i className="bi bi-trash-fill fs-1"></i>
                      </button>
                    </div>
                  ) : null}
                  <div
                    className="col-auto px-sm-3 "
                    data-bs-toggle="modal"
                    data-bs-target="#addEditCealthCategories"
                  >
                    <button
                      className="btn btn-dark customBtnSize"
                      type="button"
                      id={""}
                      onClick={() => {
                        setAddClicked(true);
                        setIsEdit(false);
                        setName("");
                      }}
                    >
                      Add Health Category
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {loader ? (
              <div style={{ marginLeft: "400px" }}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginTop: "100px" }}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#b8c480",
                    "#B2A3B5",
                    "#F4442E",
                    "#51E5FF",
                    "#429EA6",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive datatable-custom">
                <DataTable
                  columns={columns}
                  data={searchInput ? filterd : allData}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggleRow}
                  selectableRows
                  pagination
                />
              </div>
            )}
            <ToastContainer autoClose={1000} />
            <div
              className="modal fade show"
              id="addEditCealthCategories"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="false"
            >
              <Formik
                enableReinitialize={true}
                initialValues={{ name: name ? name : "" }}
                validationSchema={categorySchema}
                onSubmit={editAddCategogiesHandler}
              >
                {({ errors, touched }) => {
                  return (
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                      <div className="modal-content">
                        <div className="modal-header p-3 p-sm-5">
                          <h5
                            className="modal-title fs-4 fs-sm-1"
                            id="editUserModalLabel"
                          >
                            {addClicked
                              ? "Add Health Categories"
                              : "Edit Health Categories"}
                          </h5>
                        </div>
                        <div className="modal-body ps-3 ps-sm-5 pb-3 pb-sm-5 pe-3 pe-sm-5 pt-0">
                          <Form>
                            <div className="row mb-4 flex-column">
                              <label className="col-sm-12 col-form-label form-label">
                                Health Category Name
                              </label>
                              <div className="col-sm-12">
                                <div className="input-group input-group-merge">
                                  <Field
                                    type="text"
                                    className="js-toggle-password form-control "
                                    name="name"
                                    autoFocus
                                    placeholder="Enter Health Category Name"
                                    onKeyPress={(e) => {
                                      if (e.charCode == 13) {
                                        editAddCategogiesHandler();
                                      }
                                    }}
                                  />
                                </div>
                                {errors.name && touched.name ? (
                                  <ErrorMessage
                                    name="name"
                                    component={ErrorMessages}
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="d-flex gap-3">
                                <button
                                  type="button"
                                  className="btn btn-white"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    setAddClicked(false);
                                    setName("");
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  type="submit"
                                  className="btn btn-dark"
                                >
                                  {addClicked ? "Add" : "Update"}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>

            <div>
              {/* <!-- Modal for delete --> */}
              <div
                className="modal fade"
                id="askdeletecategory"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLongTitle"
                      >
                        Alert !
                      </h5>
                    </div>
                    <div className="modal-body">
                      Do you want to delete the categorie -
                      <strong>{name}</strong>
                      <strong>{row.categories}</strong>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => deleteCategoriesHandler(row)}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setName("")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade "
                id="multypleEventDelete"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLongTitle"
                      >
                        Alert !
                      </h5>
                    </div>
                    <div className="modal-body">
                      {multipleRow?.length < 1
                        ? "Please select the row first !"
                        : `Do you want to delete ${multipleRow?.length} categories`}
                    </div>
                    <div className="modal-footer">
                      {multipleRow?.length > 0 ? (
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => handleMultipleDelete()}
                        >
                          Delete
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setMultipleRow([]);
                          setToggleRow(!toggleRow);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
