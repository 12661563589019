import React from "react";
import axios from "axios";

const BaseURL = process.env.REACT_APP_BASEURL;

const PostData = async (url, body) => {
  const Token = localStorage.getItem("token");

  const response = await fetch(`${BaseURL}/${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },

    body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    if (url == "login") {
      localStorage.setItem("token", result1.data.auth);
    }
    if (result1.message == "Unauthorized") {
      localStorage.removeItem("token");
      window.location.reload();
    }
    console.log("gsgsg", result1.message);
    return result1;
  } catch (e) {
    console.error(e);
  }
};
const putData = async (url, body) => {
  const Token = localStorage.getItem("token");

  const response = await fetch(`${BaseURL}/${url}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },

    body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    console.log("gsgsg", result1.message);
    if (result1.message == "Unauthorized") {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return result1;
  } catch (e) {
    console.error(e);
  }
};

const getData = async (path) => {
  const Token = localStorage.getItem("token");

  const response = await fetch(`${BaseURL}/${path}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  });

  try {
    const result2 = await response.json();
    if (result2.message == "Unauthorized") {
      localStorage.removeItem("token");
    }
    console.log("gsgsg", result2.message);
    if (result2.message == "Unauthorized") {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return result2;
  } catch (e) {
    console.error(e);
  }
};
const axiosPostData = async (url, formData) => {
  const Token = localStorage.getItem("token");

  return axios
    .post(`${BaseURL}/${url}`, formData, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.message == "Unauthorized") {
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.log("gsgsg", res.message);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
const postData = PostData;
export { postData, getData, axiosPostData, putData };
