import React, { useEffect, useState } from "react";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { getData } from "../../FetchService";
const ActivityChart = () => {
  const [activityChart, setActivityChart] = useState([]);
  const [series, setseries] = useState([]);
  const categories = [];
  for (let i = 0; i < 7; i++) {
    categories.unshift(
      new Date(Date.now() - i * 24 * 60 * 60 * 1000).toLocaleDateString(
        "en-US",
        { weekday: "short" }
      )
    );
  }
  const getActivityChart = async () => {
    const res = await getData("activity-chart");
    setActivityChart(res.data);
  };
  useEffect(() => {
    getActivityChart();
  }, []);
  const [max, setMax] = useState(0);

  const maxValue = () => {
    series.map((item) => {
      item.data.map((el) => {
        if (el > max) {
          setMax(el);
        }
      });
    });
  };

  let ser = [];
  activityChart
    .sort((a, b) => (a > b ? -1 : 1))
    .map((el, i) => {
      if (el._id == 1) {
        ser.push({ ["Running"]: el.users });
      } else if (el._id == 2) {
        ser.push({ ["Cycling"]: el.users });
      } else if (el._id == 3) {
        ser.push({ ["Jumping"]: el.users });
      } else if (el._id == 4) {
        ser.push({ ["Walking"]: el.users });
      } else if (el._id == 5) {
        ser.push({ ["Skipping"]: el.users });
      }
    });
  const grouped = () => {
    let arr = [];
    activityChart.map((item) => {
      const user = item.users;
      if (item._id == 1) {
        let d = {};
        d["name"] = "running";
        let userData = {};
        user.map((el) => {
          userData[el.days - 1] = el.totalUsers;
        });
        for (let i = -7; i < 0; i++) {
          if (!Object.keys(userData).includes(`${i}`)) {
            userData[`${i}`] = 0;
          }
        }
        const ordered = Object.keys(userData)
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .reduce((obj, key) => {
            obj[key] = userData[key];
            return obj;
          }, {});
        d["data"] = Object.values(ordered);

        arr.push(d);
      } else if (item._id == 2) {
        let d = {};
        d["name"] = "cycling";
        let userData = {};
        user.map((el) => {
          userData[el.days - 1] = el.totalUsers;
        });
        for (let i = -7; i < 0; i++) {
          if (!Object.keys(userData).includes(`${i}`)) {
            userData[`${i}`] = 0;
          }
        }
        const ordered = Object.keys(userData)
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .reduce((obj, key) => {
            obj[key] = userData[key];
            return obj;
          }, {});
        d["data"] = Object.values(ordered);
        arr.push(d);
      } else if (item._id == 3) {
        let d = {};
        d["name"] = "jumping";
        let userData = {};
        user.map((el) => {
          userData[el.days - 1] = el.totalUsers;
        });
        for (let i = -7; i < 0; i++) {
          if (!Object.keys(userData).includes(`${i}`)) {
            userData[`${i}`] = 0;
          }
        }
        const ordered = Object.keys(userData)
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .reduce((obj, key) => {
            obj[key] = userData[key];
            return obj;
          }, {});
        d["data"] = Object.values(ordered);
        arr.push(d);
      } else if (item._id == 4) {
        let d = {};
        d["name"] = "walking";
        let userData = {};
        user.map((el) => {
          userData[el.days - 1] = el.totalUsers;
        });
        for (let i = -7; i < 0; i++) {
          if (!Object.keys(userData).includes(`${i}`)) {
            userData[`${i}`] = 0;
          }
        }
        const ordered = Object.keys(userData)
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .reduce((obj, key) => {
            obj[key] = userData[key];
            return obj;
          }, {});
        d["data"] = Object.values(ordered);
        arr.push(d);
      } else if (item._id == 5) {
        let d = {};
        d["name"] = "skipping";
        let userData = {};
        user.map((el) => {
          userData[el.days - 1] = el.totalUsers;
        });
        for (let i = -7; i < 0; i++) {
          if (!Object.keys(userData).includes(`${i}`)) {
            userData[`${i}`] = 0;
          }
        }
        const ordered = Object.keys(userData)
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .reduce((obj, key) => {
            obj[key] = userData[key];
            return obj;
          }, {});
        d["data"] = Object.values(ordered);
        arr.push(d);
      }
    });
    setseries(arr);
  };
  useEffect(() => {
    grouped();
    maxValue();
  }, [activityChart]);

  const getOptions = (type) => ({
    chart: {
      type,
    },
    title: {
      text: _.startCase(``),
    },
    accessibility: {
      enabled: false,
    },

    yAxis: {
      title: {
        text: "No. of users",
      },
      lineColor: "black",
      lineWidth: 1,
      min: 0,
      max: max > 2 ? max + 10 : 20,
      tickInterval: 5,
      startPoint: 0,
    },

    xAxis: {
      title: {
        text: "Weekdays",
      },
      categories: categories,
      min: 0,
      max: 6,
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
    },

    series: series,
  });
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={getOptions("line")} />
    </div>
  );
};

export default ActivityChart;
