import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ColorRing } from "react-loader-spinner";
import Moment from "react-moment";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";

const ManageClubsList = () => {
  const location = useLocation();
  let _id = location.state.id;
  const [allClubs, setAllClubs] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterd, setFilterd] = useState([]);
  const [row, setRow] = useState("");
  const [searchInput, setSaerchInput] = useState("");

  const [multyDeleteRow, setMultyDeleteRow] = useState([]);
  const [multipleRow, setMultipleRow] = useState();
  const [toggleRow, setToggleRow] = useState(false);

  const navigate = useNavigate();

  const allClubsData = async () => {
    setLoader(true);
    let body = {
      id: _id,
    };
    const res = await postData("filtered-clubs", body);
    setAllClubs(res.data);
    setLoader(false);
  };
  useEffect(() => {
    allClubsData();
  }, [_id]);

  const handleMultipleDelete = async () => {
    const data = {
      id: multipleRow,
    };
    let res = await putData(`delete-clubs`, data);
    if (res.status == true && multipleRow.length > 0) {
      allClubsData();
      return toast.success(
        `${multipleRow.length} club deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      allClubsData();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // getAllApiHandler();
      toast.error(`No club select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  const handleDeleteclub = async (row) => {
    const data = {
      id: [row],
    };
    let res = await putData(`delete-clubs`, data);

    if (res.status == true) {
      allClubsData();
      return toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      allClubsData();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let a = e.target.value;
    setSaerchInput(a);
    if (a) {
      let fill = allClubs.filter((el) => el.name.toLowerCase().includes(a));
      setFilterd(fill);
    }
  };
  const handleSelectedRowsChange = useCallback(
    (row) => {
      const selected = row.selectedRows.map((x) => x._id);
      setMultipleRow(selected);
    },
    [multipleRow]
  );

  const columns = [
    {
      name: "SR.NO",

      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "CLUB NAME",
      selector: (row) => (row.name ? row.name : "empty"),
      sortable: true,
      center: true,
    },
    {
      name: "CLUB TYPE",
      selector: (row) => (row.isPrivate ? "Private" : "Public"),
      sortable: true,
      center: true,
    },
    {
      name: "QUALIFYING ACTIVITIES",
      selector: (row) =>
        row.activities?.map((el) =>
          el == 1
            ? "Running "
            : "" || el == 2
            ? " Cycling "
            : "" || el == 3
            ? " Jumping "
            : "" || el == 4
            ? " Walking "
            : "" || el == 5
            ? "Skipping "
            : "" || (el == 1 && el == 2 && el == 3 && el == 4 && el == 5)
            ? "All"
            : ""
        ),
      center: true,
      style: { width: "570px" },
    },
    {
      name: "CLUB MEMBERS",
      selector: (row) => row.members,
      sortable: true,
      center: true,
    },
    {
      name: "ACTIVITIES",
      selector: (row) => row.totalActivities,
      sortable: true,
      center: true,
    },
    {
      name: "POSTS",
      selector: (row) => row.posts,
      sortable: true,
      center: true,
    },
    {
      name: "LAST ACTIVITY PERFORMED",
      selector: (row) => (
        <Moment format="DD-MM-YY , HH:MM A">{row.lastActivityAt}</Moment>
      ),
      sortable: true,
      center: true,
      style: { width: "480px", textAlign: "center" },
    },
    ,
    {
      name: "CREATED ON",
      selector: (row) => (
        <Moment format="DD-MM-YY , HH:MM A">{row.createdAt}</Moment>
      ),
      sortable: true,
      center: true,
      style: { width: "480px", textAlign: "center" },
    },
    ,
    {
      name: "STATUS",
      selector: (row) =>
        row.isActivityPerformed == true ? "Active" : "Inactive",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="/manageclubs/leaderboardviewclubdetails"
            state={{ data: row }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">Views Clubs</h1>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-bottom-0 card-header-content-md-between row">
              <div className="mb-2 mb-md-0 col-sm-auto">
                <form>
                  <div className="input-group input-group-merge input-group-flush">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      id="datatableSearch"
                      type="search"
                      className="form-control"
                      placeholder="Search clubs"
                      aria-label="Search users"
                      value={searchInput}
                      onKeyPress={(event) => {
                        if (event.charCode == 13) {
                          handleSearch(event);
                        }
                      }}
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </form>
              </div>
              <div className="col active_inactive_btn_bar d-flex gap-2 justify-content-sm-end justify-content-start mt-sm-0 mt-2">
                <form action="">
                  <div className="row">
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-white border-0 px-2"
                        data-bs-toggle="modal"
                        data-bs-target="#multypleEventDelete"
                      >
                        <i className="bi bi-trash-fill fs-1"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {loader ? (
              <div style={{ marginLeft: "400px" }}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginTop: "100px" }}
                  wrapperclassName="blocks-wrapper"
                  colors={[
                    "#b8c480",
                    "#B2A3B5",
                    "#F4442E",
                    "#51E5FF",
                    "#429EA6",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive datatable-custom ">
                <DataTable
                  columns={columns}
                  data={searchInput ? filterd : allClubs}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggleRow}
                  selectableRows
                  pagination
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <!-- Modal for delete --> */}
      <div
        className="modal fade "
        id="multypleEventDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete {multipleRow?.length} events
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleMultipleDelete()}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setMultipleRow([]);
                  setToggleRow(!toggleRow);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClubsList;
