import React from "react";
import { useEffect, useState } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData } from "../../FetchService/index";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
const ManageOrder = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterData1, setFilterData1] = useState([]);

  const [input, setInput] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [filteroption, setfilteroption] = useState("");
  const [filteroption1, setfilteroption1] = useState("");
  const [filtermedal, setfiltermedal] = useState("");

  const getOrder = async () => {
    let res = await getData("medals");
    setData(res.data);
  };
  useEffect(() => {
    getOrder();
  }, []);

  const column = [
    {
      name: "SR.NO",
      cell: (row, index) => index + 1,
      center: true,
    },
    {
      name: "USERNAME",
      cell: (row) => `${row.user?.firstName} ${row.user?.lastName}`,
      center: true,
    },
    {
      name: "MOBILE NUMBER",
      cell: (row, index) => row.user?.mobile,
      center: true,
      style: { width: "450px" },
    },
    {
      name: "EVENT NAME",
      cell: (row, index) => row.event?.name,
      style: { width: "750px" },
    },
    {
      name: "ACTIVITIES",
      cell: (row, index) =>
        row.event?.activities.map((el) => {
          return el == 1
            ? "Running ,"
            : "" || el == 2
              ? "Cycling ,"
              : "" || el == 3
                ? "Jumping ,"
                : "" || el == 4
                  ? "Walking "
                  : "" || el == 5
                    ? "Skipping "
                    : "" && el == 1 && el == 2 && el == 3 && el == 4 && el == 4
                      ? "All"
                      : "";
        }),
    },
    {
      name: "EVENT START DATE",
      cell: (row, index) => (
        <Moment format="DD-MMM-YY">{row.event?.startDate}</Moment>
      ),
      center: true,
    },
    {
      name: "EVENT END DATE",
      cell: (row, index) => (
        <Moment format="DD-MMM-YY">{row.event?.endDate}</Moment>
      ),
      center: true,
    },
    {
      name: "NUMBER OF MEDAL EARNED",
      cell: (row, index) => data.length,
      center: true,
    },
    {
      name: "MEDAL/MEDAL WALL STATUS",
      cell: (row, index) => (row.isWall ? "Medal" : "N/A"),
      center: true,
    },
    {
      name: "ORDER STATUS",
      cell: (row, index) =>
        row.status == 0
          ? " EARNED"
          : row.status == 1
            ? "READY_TO_DISPATCH"
            : row.status == 2
              ? "CONFIRMED"
              : row.status == 3
                ? "SHIPPED"
                : row.status == 4
                  ? "DELIVERED"
                  : row.status == 5
                    ? "CANCELED"
                    : "",
      center: true,
    },
    {
      name: "ACTION",
      cell: (row, index) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="viewmedalorderdetails"
            state={row}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </NavLink>
        </div>
      ),
      center: true,
    },
  ];

  const handleChange = (event) => {
    event.preventDefault();
    setInput(event.target.value);
    let filterName = data?.filter((el) => {
      if (el?.event?.name.toLowerCase().includes(event.target.value)) {
        return el;
      }
    });
    setFilterData(filterName);
  };

  const handledataFilter = (item) => {
    setfilteroption(item);
    if (item.opt == "0" && item.med == "medal") {
      let a =  filterData.filter((el) => el.status == 0)
      setFilterData(a)
    } else if (item.opt == "1" && item.med == "medal") {
      let b = filterData.filter((el) => el.status == 1)
      setFilterData(b)
    } else if (item.opt == "2" && item.med == "medal") {
      let c = filterData.filter((el) => el.status == 2)
      setFilterData(c)
    } else if (item.opt == "3" && item.med == "medal") {
      let d = filterData.filter((el) => el.status == 3)
      setFilterData(d)
    } else if (item.opt == "4" && item.med == "medal") {
      let e = filterData.filter((el) => el.status == 4)
      setFilterData(e)
    } else if (item.opt == "5" && item.med == "medal") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "0" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "1" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "2" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "3" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "4" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else if (item.opt == "5" && item.med == "medalwall") {
      let f = filterData.filter((el) => el.status == 5)
      setFilterData(f)
    }else {
      return false
    }

  };
  return (
  
      <div className="main mt-8">
        <div className="content container-fluid manageOrdersPage">
          <div className="pageheader">
            <div className="row g-3 align-items-center mb-5">
              <div className="col-12">
                <h1 className="page-header-title pageTitle">Manage Orders</h1>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5">
                    <form>
                      <div className="input-group input-group-merge input-group-flush border rounded">
                        <div className="input-group-prepend input-group-text ms-2">
                          <i className="bi-search"></i>
                        </div>
                        <input
                          id="datatableSearch"
                          type="search"
                          className="form-control formSearch pe-2"
                          placeholder="Search by Event Name"
                          aria-label="Search by Event Name"
                          onChange={handleChange}
                          value={input}
                          onKeyPress={(event) => {
                            if (event.charCode == 13) {
                              handleChange(event);
                            }
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-lg-auto">
                    <div className="row g-3 align-items-center">
                      <div className="col-12 col-sm-auto">
                        <div className="fw-bold">Filter By</div>
                      </div>
                      <div className="col-12 col-sm-auto">
                        <div className="tom-select-custom">
                          <select
                            id="datatableEntries"
                            className="js-select form-select w-sm-auto selectForm"
                            autoComplete="off"
                            data-hs-tom-select-options=""
                            onChange={(e) => setfiltermedal(e.target.value)}
                            defaultValue="DEFAULT"
                          >
                            <option value="DEFAULT" defaultValue={false}>
                              Select Product
                            </option>
                            <option value='medal' selected={filtermedal == "medal" ? true : false}>Medal</option>
                            <option value='medalwall' selected={filtermedal == "medalwall" ? true : false} >Medal Wall</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-auto">
                        <div className="tom-select-custom">
                          <select
                            id="datatableEntries"
                            className="js-select form-select w-sm-auto selectForm"
                            autoComplete="off"
                            data-hs-tom-select-options=""
                            onChange={(e) => setOptionSelect(e.target.value)}
                            defaultValue='Select Medal'
                          >
                            <option value="Select Medal" defaultValue={false} >
                              Select Medal Status
                            </option>
                            <option value="0" selected={optionSelect == "0" ? true : false}>Earned</option>
                            <option value="1" selected={optionSelect == "1" ? true : false}>Ready to Dispatch</option>
                            <option value="2" selected={optionSelect == "2" ? true : false}>CONFIRMED</option>
                            <option value="3" selected={optionSelect == "3" ? true : false}>SHIPPED</option>
                            <option value="4" selected={optionSelect == "4" ? true : false}>DELIVERED</option>
                            <option value="5" selected={optionSelect == "5" ? true : false}>CANCELED</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="dgrid d-sm-flex gap-2 justify-content-md-start justify-content-sm-end justify-content-center ms-sm-2">
                          <button
                            onClick={() => handledataFilter({ opt: optionSelect, med: filtermedal })}
                            className="btn btn-dark pageBtn"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="dgrid d-sm-flex gap-2 justify-content-md-start justify-content-sm-end justify-content-center ms-sm-2">
                          <button
                            onClick={() =>{ handledataFilter(""); setfiltermedal(""); setOptionSelect(""); setInput("") }}
                            className="btn btn-dark pageBtn"
                            type="button"
                          >
                            {" "}
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive datatable-custom">
                  <DataTable
                    columns={column}
                    data={input || filteroption ? filterData : data}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default ManageOrder;
