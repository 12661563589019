import React from 'react'

const ManageMerchandise= () => {
  return (
    <div  className="main vh-100 overflow-hidden mt-8">
    <div className="content container-fluid addEditHealthArticles manage_user manage_event_list h-100">
      <div className="pageheader h-100">
        <div className="row g-2 align-items-center mb-3">
          <div className="col">
            <h1 className="page-header-title m-0">Manage Merchandise
            </h1>
          </div>
        </div>
        <div className="row align-items-center justify-content-center h-100 manageMerchandiseOuter">
          <div className="col-auto">
            <div className="comingsoonImgOuter">
              <img src="assets/img/v2_qqtndn.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ManageMerchandise
