import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getData, putData } from "../../FetchService";
import ActivityChart from "./ActivityChart";
import EarningChart from "./EarningChart";
import GrowthChart from "./GrowthChart";

function Dashboard() {
  const [totalData, setTotaldata] = useState();
  const [isactive, setIsActive] = useState();
  const [earningDetails, setEarningDetails] = useState();
  const [medalDetails, setMedalDetails] = useState();
  const [upcomingEvents, setUpcomingevents] = useState();
  const [activitiesChart, setActivitesChart] = useState();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const handledailyData = async () => {
    const res = await getData("daily-data");
    setTotaldata(res.data);
  };
  const handleearningDetails = async () => {
    const res = await getData("earning-details");
    setEarningDetails(res.data);
  };

  const handlemedalDetails = async () => {
    const res = await getData("medal-details");
    setMedalDetails(res.data);
  };
  const handleupcomeEvents = async () => {
    const res = await getData("upcoming-events");
    setUpcomingevents(res.data);
  };
  const handleactivityChart = async () => {
    const res = await getData("activity-chart");
    setActivitesChart(res.data);
  };
  useEffect(() => {
    handledailyData();
    handleearningDetails();
    handlemedalDetails();
    handleupcomeEvents();
    handleactivityChart();
  }, []);
  const deleteHandler = async (id) => {
    const data = { id: [id] };
    const res = await putData("delete-events", data);
    handleupcomeEvents();
    setOpen(false);
    if (res.status == true) {
      return toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid dashboardMain">
          <div className="row">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <div className="card card-hover-shadow h-100">
                <div className="card-body">
                  <h6 className="card-subtitle">Today's Earnings</h6>
                  <div className="row align-items-center gx-2 mb-1 mb-5 mt-2">
                    <div className="col-12">
                      <h2 className="card-title text-inherit">
                        ${totalData?.earnings?.today}
                      </h2>
                    </div>
                  </div>
                  <span className="text-body fs-6 ms-1 d-inline-block w-100">
                    Yesterday's Earnings
                  </span>
                  <span className="text-body fs-6 ms-1">
                    ${totalData?.earnings?.yesterday}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <div className="card card-hover-shadow h-100">
                <div className="card-body">
                  <h6 className="card-subtitle">Today's Orders</h6>
                  <div className="row align-items-center gx-2 mb-1 mb-5 mt-2">
                    <div className="col-12">
                      <h2 className="card-title text-inherit">
                        {totalData?.medals?.today}{" "}
                        <span className="text-body fs-6 ms-1 text-muted">
                          Medals
                        </span>
                      </h2>
                    </div>
                  </div>
                  <span className="text-body fs-6 ms-1 d-inline-block w-100">
                    Yesterday's Order
                  </span>
                  <span className="text-body fs-6 ms-1">
                    {totalData?.medals?.yesterday}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <div className="card card-hover-shadow h-100">
                <div className="card-body">
                  <h6 className="card-subtitle">Total Users</h6>
                  <div className="row align-items-center gx-2 mb-1 mb-5 mt-2">
                    <div className="col-12">
                      <h2 className="card-title text-inherit">
                        {totalData?.users?.total}
                      </h2>
                    </div>
                  </div>
                  <span className="text-body fs-6 ms-1 d-inline-block w-100">
                    {totalData?.users?.today} New Users
                  </span>
                  <span className="text-body fs-6 ms-1">
                    {totalData?.users?.total - totalData?.users?.today} Old
                    Users
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <div className="card card-hover-shadow h-100">
                <div className="card-body">
                  <h6 className="card-subtitle">Total Activities</h6>
                  <div className="row align-items-center gx-2 mb-1 mb-5 mt-2">
                    <div className="col-12">
                      <h2 className="card-title text-inherit">
                        {totalData?.activities?.total}
                      </h2>
                    </div>
                  </div>
                  <span className="text-body fs-6 ms-1 d-inline-block w-100">
                    Today's Total Activities
                  </span>
                  <span className="text-body fs-6 ms-1">
                    {totalData?.activities?.today}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-5">
              <div className="card h-100">
                <div className="card-header card-header-content-sm-between">
                  <h4 className="card-header-title mb-2 mb-sm-0">Overview </h4>
                  <ul
                    className="nav nav-segment nav-fill"
                    id="expensesTab"
                    role="tablist"
                  >
                    <li
                      onClick={() => setIsActive("daily")}
                      className="nav-item cursor-pointer"
                      data-bs-toggle="chart-bar"
                      data-datasets="daily"
                      data-trigger="click"
                      data-action="toggle"
                    >
                      <span className="nav-link active" data-bs-toggle="tab">
                        Daily
                      </span>
                    </li>
                    <li
                      onClick={() => setIsActive("weekly")}
                      className="nav-item cursor-pointer"
                      data-bs-toggle="chart-bar"
                      data-datasets="weekly"
                      data-trigger="click"
                      data-action="toggle"
                    >
                      <span className="nav-link" data-bs-toggle="tab">
                        Weekly
                      </span>
                    </li>
                    <li
                      onClick={() => setIsActive("monthly")}
                      className="nav-item cursor-pointer"
                      data-bs-toggle="chart-bar"
                      data-datasets="monthly"
                      data-trigger="click"
                      data-action="toggle"
                    >
                      <span className="nav-link" data-bs-toggle="tab">
                        Monthly
                      </span>
                    </li>
                    <li
                      onClick={() => setIsActive("yearly")}
                      className="nav-item cursor-pointer"
                      data-bs-toggle="chart-bar"
                      data-datasets="yearly"
                      data-trigger="click"
                      data-action="toggle"
                    >
                      <span className="nav-link" data-bs-toggle="tab">
                        Yearly
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="chartjs-custom">
                    <EarningChart
                      earningDetails={earningDetails}
                      setIsActive={setIsActive}
                      isactive={isactive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mb-3 mb-lg-5">
              <div className="card h-100">
                <div className="card-header card-header-content-sm-between d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title mb-sm-0">User Growth</h4>
                  <span className="fs-6">Enrolled Users</span>
                </div>
                <div className="card-body">
                  <div className="chartjs-custom">
                    <GrowthChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3 mb-lg-5">
              <NavLink to="/manageorder" className="card h-100">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Orders</h4>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush list-group-no-gutters">
                    <li className="list-group-item border-0">
                      <h5 className="card-title">Total Medals</h5>
                      <div className="d-flex align-items-center gap-3">
                        <div className="medalIm">
                          <img src="" alt="" />
                        </div>
                        <h5 className="totalMedal">{medalDetails?.total}</h5>
                      </div>
                    </li>
                    <li className="list-group-item border-0">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className="row align-items-center">
                            <div className="col mt-5">
                              <h5 className="">Pending Medals</h5>
                              <div className="progress customProgress align-items-center d-flex justify-content-between gap-2">
                                <div
                                  className="progress-bar h-100"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  style={{
                                    width: medalDetails?.pending
                                      ? (medalDetails?.pending /
                                          medalDetails?.total) *
                                          100 +
                                        "%"
                                      : "",
                                    ariaValuenow: "57",
                                    ariaValuemin: "0",
                                    ariaValuemax: "100",
                                  }}
                                ></div>
                                <span>{medalDetails?.pending}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item border-0">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className="row align-items-center">
                            <div className="col">
                              <h5 className="">Delivered Medals</h5>
                              <div className="progress customProgress align-items-center d-flex justify-content-between gap-2">
                                <div
                                  className="progress-bar h-100"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  style={{
                                    width: medalDetails?.delivered
                                      ? (medalDetails?.delivered /
                                          medalDetails?.total) *
                                          100 +
                                        "%"
                                      : "",
                                    ariaValuenow: "156",
                                    ariaValuemin: "0",
                                    ariaValuemax: "100",
                                  }}
                                ></div>
                                <span>{medalDetails?.delivered}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="">
                <div className="card h-30" style={{ height: "500px" }}>
                  <div className="card-header card-header-content-between">
                    <h4 className="card-header-title">Upcoming Events</h4>
                  </div>
                  <div
                    className="card-body"
                    style={{ height: "500px", overflow: "auto" }}
                  >
                    <ul className="list-group list-group-flush list-group-no-gutters">
                      {upcomingEvents?.length ? (
                        upcomingEvents?.map((item, i) => {
                          const coporate = item.isCorporate;
                          return (
                            <li key={i} className="list-group-item">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    className="avatar avatar-xs avatar-4x3"
                                    src="assets/svg/brands/capsule-icon.svg"
                                    alt="Image Description"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="row align-items-center">
                                    <div className="col">
                                      <h5 className="mb-0">{item.name}</h5>
                                      <span className="d-block fs-6 text-body">
                                        <Moment format="DD/MM/YYYY">
                                          {item.startDate}
                                        </Moment>
                                      </span>
                                    </div>
                                    <div className="col-auto d-flex gap-3 ps-0">
                                      <NavLink
                                        className="btn border-0 shadow-none p-0"
                                        state={
                                          item.isCorporate
                                            ? { data: item }
                                            : { items: item._id, user: item }
                                        }
                                        to={
                                          item.isCorporate && item.amount > 0
                                            ? "/managecorporateevents/view-corporate-event-details-paid"
                                            : item.isCorporate &&
                                              item.amount == 0
                                            ? "/managecorporateevents/view-corporat-event-Details-Free"
                                            : item.isCorporate == false &&
                                              item.amount > 0
                                            ? "/managepublicevent/vieweventdetailspaid"
                                            : "/managepublicevent/vieweventdetailsfree"
                                        }
                                        // target="_blank"
                                      >
                                        {" "}
                                        <i className="bi bi-pencil-square ms-1"></i>{" "}
                                      </NavLink>
                                      <button
                                        type="button"
                                        className="btn border-0 shadow-none p-0"
                                        onClick={() => setOpen(true)}
                                      >
                                        <i className="bi bi-trash3 ms-1"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <-- Modal --> */}
                              <div
                                className={`modal fade ${open ? "show" : ""}`}
                                style={{ display: open ? "block" : "none" }}
                                tabIndex="-1"
                                role={open ? "dialog" : ""}
                                aria-hidden={open ? "true" : "false"}
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered "
                                  role="document"
                                >
                                  <div className="modal-content border border-3">
                                    <div className="modal-header">
                                      <h5 className="modal-title text-danger">
                                        Alert !
                                      </h5>
                                    </div>
                                    <div className="modal-body">
                                      Do you want to delete events
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => deleteHandler(item._id)}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setOpen(false)}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className=" row p-3 card_box_sh bg-white rounded-4 d-flex  align-items-center text-danger justify-content-center">
                          No data found !
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-3 mt-lg-0">
              <div className="card h-30">
                <div className="card-header card-header-content-sm-between">
                  <h4 className="card-header-title mb-2 mb-sm-0">
                    Activity Chart Weekly{" "}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="chartjs-custom">
                    <ActivityChart activitiesChart={activitiesChart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
