import React from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { getData } from "../../FetchService";

export default function ManageCorporateTeams() {
  const navigate = useNavigate();
  const location = useLocation();
  const [teams, setTeams] = useState([]);

  const eventTeams = async () => {
    const res = await getData(`get-teams?id=${location.state?.id}`);
    setTeams(res.data);
  };
  useEffect(() => {
    eventTeams();
  }, []);

  const createNewTeamManually = () => {
    navigate("create-edit-corporate-teams", {
      state: { edit: false, id: location.state?.id, globalTeam: false },
    });
  };
  const createNewTeamGlobalTeam = () => {
    navigate("create-edit-corporate-teams", {
      state: { edit: false, id: location.state?.id, globalTeam: true },
    });
  };
  const handleEditTeam = async (iid) => {
    navigate("create-edit-corporate-teams", {
      state: { edit: true, id: iid, globalTeam: false },
    });
  };
  return (
    <div>
      {/* <ToastContainer autoClose={1000} /> */}

      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list manageUpcomingFree">
          <div className="pageheader">
            <div className="row align-items-center mb-3 gap-3 gap-sm-0">
              <div className="col-sm d-flex align-items-center gap-2">
                <h1 className="page-header-title m-0">Team List</h1>
              </div>
              <div className="col-12 col-sm-auto">
                <div className="d-flex gap-3">
                  <div className="dropdown">
                    <div
                      className="navbar-dropdown-account-wrapper"
                      id="accountNavbarDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      data-bs-dropdown-animation
                    >
                      <button type="button" className="btn btn-dark ">
                        Create New Team
                      </button>
                    </div>
                    <div
                      className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                      style={{ width: "16rem" }}
                    >
                      <button
                        className="dropdown-item"
                        style={{
                          backgroundColor: "none",
                          outline: "none",
                          border: "none",
                        }}
                        onClick={() => createNewTeamManually()}
                      >
                        {" "}
                        Manually{" "}
                      </button>

                      <button
                        className="dropdown-item"
                        style={{
                          backgroundColor: "none",
                          outline: "none",
                          border: "none",
                        }}
                        onClick={() => createNewTeamGlobalTeam()}
                      >
                        Global Team
                      </button>

                      {/* <button className='dropdown-item' style={{backgroundColor :'none',outline:'none', border:'none'}} onClick={logOutHandler}>    <i className="bi bi-box-arrow-right me-2" ></i> Logout </button>  */}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-dark h-25 mt-1"
                    onClick={() =>
                      navigate(
                        "/managecorporateevents/view-corporat-event-Details-Free",
                        { state: { data: { _id: location.state?.id } } }
                      )
                    }
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="pageBody mt-4">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="card shadow-none border-0 ">
                  <div className="row mx-0 mb-3 align-items-center">
                    <div className="col ps-0 sectionTitle text-black">
                      Total Teams: {teams.length}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {teams.map((el, i) => {
                    console.log(el, "eeeellll");
                    return (
                      <div key={i} className="col-sm-6 mb-3">
                        <di className="card card-hover-shadow h-100">
                          <div className="card-body">
                            <div className="row align-items-center gx-2">
                              <div className="col-12 px-0 mb-3 row mx-0">
                                <div className="col ps-0">
                                  <h2 className="card-title text-inherit">
                                    {el?.name}
                                  </h2>
                                </div>
                                <div className="col-auto pe-0">
                                  <span
                                    onClick={() => handleEditTeam(el?._id)}
                                    className="border-bottom border-bottom border-3 curcer-pointer"
                                  >
                                    <i className="bi bi-pencil-fill fs-3"></i>
                                  </span>
                                  <span>
                                    <i className="bi bi-trash-fill ms-2 fs-3 curcer-pointer"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="col-12 px-0 mb-3">
                                <h6 className="card-subtitle">
                                  Added Participants: {el.invitedParticipants}
                                </h6>
                              </div>
                              <div className="col-12 px-0">
                                <h6 className="card-subtitle">
                                  Event Admin:{" "}
                                  {`${el.admin.firstName} ${el.admin.lastName}`}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </di>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
