import React, { useEffect, useState } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import Header from "../../CommonPages/Header";
import Sidebar from "../../CommonPages/SideBar";
import { getData } from "../../FetchService";
function ViewQuery() {
  const location = useLocation();
  const [detail, setDetail] = useState([]);
  const details = async () => {
    const path = `view-one-raise-query?id=${location?.state?.details?._id}`;
    const res = await getData(path);
    setDetail(res.data);
  };
  useEffect(() => {
    details();
  }, []);
  return (

    <>
      {detail.map((detail) => {
        return (
          <div className="main mt-8" key={Math.random()}>
            <div className="content container-fluid viewQueryDetails" />
            <div className="pageheader">
              <div className="row g-3 align-items-center mb-3 ms-5">
                <div className="col">
                  <h1 className="page-header-title m-0">View Query Details</h1>
                </div>
                <div className="col-auto panding_btn me-5">
                  <h3 className="page-header-title text-uppercase m-0 text-warning ">
                    {detail?.status == 0
                      ? "Pending"
                      : detail?.status == 1
                        ? "Progress"
                        : detail?.status == 2
                          ? "Completed"
                          : ""}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row g-3 ms-5 me-5">
              <div className="col-12">
                <h3 className="mb-0 pageHeading">{detail?.title}</h3>
              </div>
              <div className="col-12">
                <p className="m-0">{detail?.text}</p>
              </div>

              <div className="col-12">
                <h3 className="mb-0 pageHeading">Attachments</h3>
              </div>
              <div className="col-12">
                <div className="row g-3 mb-3">
                  {detail?.images?.map((item) => {
                    return (
                      <div className="col-6 col-sm-4 col-md-3 col-xxl-auto">
                        <div className="card rounded-0 step-icon-soft-dark ">
                          <div className="card-body d-flex justify-content-center ">
                            <span className="fs-1">
                              <img src={item} key={Math.random()} style={{ width: '100px', height: '100px' }} />
                            </span>
                          </div>
                        </div>
                      </div>
                    )

                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ViewQuery;
